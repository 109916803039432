/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Col, Row, Offcanvas } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import PaymentTable from './PaymentTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { PaymentMethodType, paymentTypeBreadcrumbItems } from 'data/payment';
import paymentMethodService from 'service/paymentMethod';
import Button from 'components/base/Button';
import UpdatePayment from './UpdatePayment';

const PaymentType = () => {
  const { t, i18n } = useTranslation();

  const [openPayment, setOpenPayment] = useState(false);
  const [selectedPaymentId, setSelectedPaymnetId] = useState('');

  //Get PAYMENT_METHOD
  const { data, refetch } = useQuery(['GET_PAYMENT_METHOD'], () => {
    const res = paymentMethodService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entity_payment_methods);
    return res;
  });

  const paymentMethodData: PaymentMethodType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.paymentMethod?.name?.[i18n?.language],
          type: items?.paymentMethod?.paymentType?.name?.[i18n?.language],
          country: items?.paymentMethod?.country.name?.[i18n?.language],
          currency: items?.paymentMethod?.currency?.name?.[i18n?.language],
          status: items?.status === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const paymentTypeTableColumns: ColumnDef<PaymentMethodType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            className="p-2"
            variant="hover"
            onClick={() => {
              setOpenPayment(true);
              setSelectedPaymnetId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('payment_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'type',
      header: t('calculation_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'country',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          [t('active')]: { bg: '#BBFBD0', text: '#119C2B' },
          [t('blocked')]: { bg: '#FDDFDD', text: '#F2271C' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div className="d-flex justify-content-center">
            <p
              style={{
                background: backgroundColor,
                padding: '8px 10px',
                textAlign: 'center',
                borderRadius: '8px',
                color: textColor,
                fontWeight: '600',
                margin: '0'
              }}
            >
              {status}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: paymentMethodData,
    columns: paymentTypeTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={paymentTypeBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('payment_type')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <PaymentTable />
          </div>

          <Offcanvas
            show={openPayment}
            onHide={() => {
              setOpenPayment(false);
              setSelectedPaymnetId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('changed')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <UpdatePayment
                setOpenPayment={setOpenPayment}
                refetch={refetch}
                selectedPaymentId={selectedPaymentId}
                setSelectedPaymnetId={setSelectedPaymnetId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default PaymentType;
