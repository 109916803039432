/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import purchaserService from 'service/purchaser';
import legalEntityCurrencyService from 'service/legalEntityCurrency';

interface ImportedPurchasserTableProps {
  setOpenImportModal: (show: boolean) => void;
  setImportData?: any;
  importData?: any;
  refetch?: any;
}

export type importType = {
  first_name: string;
  last_name: string;
  phone_number: string;
  credit: string;
  maturity_date: string;
  currency: string;
  note: string;
};

const ImportedPurchasserTable = ({
  setOpenImportModal,
  setImportData,
  importData,
  refetch
}: ImportedPurchasserTableProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [loading, setLoading] = useState(false);
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);

  const { data } = useQuery(['GET_LEGAL_ENTITY_CURRENCY'], () => {
    const res = legalEntityCurrencyService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_currencies);
    return res;
  });

  const generateData: importType[] = useMemo(() => {
    return (
      importData?.map((items: any) => {
        return {
          first_name: items?.first_name ?? '',
          last_name: items?.last_name ?? '',
          phone_number: items?.phone_number,
          credit: items?.credit ?? 0,
          maturity_date: items?.maturity_date,
          currency: items?.currency ?? '',
          note: items?.note ?? ''
        };
      }) ?? []
    );
  }, [importData]);

  const handleRemoveProductItem = (indexToRemove: number) => {
    setImportData(
      importData.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const importDataTableColumns: ColumnDef<importType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleRemoveProductItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={18}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },

    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1;
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'first_name',
      header: 'Имя',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'last_name',
      header: 'Фамилия',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'phone_number',
      header: 'Телефон',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: 'Сумма долг',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'maturity_date',
      header: 'Дата погашения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'note',
      header: 'Замтека ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateData,
    columns: importDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSubmit = () => {
    if (importData) {
      setLoading(true);

      if (importData?.length === 0) {
        dispatch(
          showAlert({
            title: 'Товар еще не добавлен'
          })
        );
        setLoading(false);
        return;
      }

      if (cashboxId === '') {
        dispatch(showAlert({ title: 'Смена еще не открыта' }));
        setLoading(false);
        return;
      }

      // Collect valid currency codes from fetched data
      const validCurrencies =
        data?.map((item: any) => item?.currency?.char_code.toLowerCase()) ?? [];

      // Check if any currency in importData is invalid
      const invalidCurrencyItems = importData.filter(
        (item: any) => !validCurrencies.includes(item?.currency?.toLowerCase())
      );

      if (invalidCurrencyItems.length > 0) {
        const invalidRows = invalidCurrencyItems.map(
          (item: any, index: number) =>
            `Ряд ${index + 1}: Неверная валюта ${item?.currency}`
        );
        /* prettier-ignore */
        dispatch(
          showAlert({
            title: `Следующие строки содержат неверные валюты:\n${invalidRows.join('\n')}`
          })
        );
        setLoading(false);
        return;
      }

      const invalidPhoneNumbers = importData
        .map((item: any, index: number) => ({
          ...item,
          originalIndex: index + 1 // Store the original row index (1-based)
        }))
        .filter(
          (item: any) =>
            !/^[1-9]\d{11}$/.test(String(item?.phone_number).trim()) // Validate the phone number
        );

      if (invalidPhoneNumbers.length > 0) {
        const invalidPhoneRows = invalidPhoneNumbers.map(
          (item: any) =>
            `Ряд ${item.originalIndex}: Неверный номер телефона ${item?.phone_number}`
        );
        /* prettier-ignore */
        dispatch(
          showAlert({
            title: `Следующие строки содержат неверные номера телефонов:\n${invalidPhoneRows.join('\n')}`
          })
        );
        setLoading(false);
        return;
      }

      // Check if any 'name' or 'maturity_date' is empty
      const invalidNameOrDateItems = importData.filter(
        (item: any) => item?.maturity_date === '' || !item?.maturity_date
      );

      if (invalidNameOrDateItems.length > 0) {
        const invalidRows = invalidNameOrDateItems.map(
          (item: any, index: number) =>
            `Ряд ${index + 1}: Пустое имя или дата погашения`
        );
        /* prettier-ignore */
        dispatch(
          showAlert({
            title: `Следующие строки содержат пустые поля:\n${invalidRows.join('\n')}`
          })
        );
        setLoading(false);
        return;
      }

      const createData = importData?.map((items: any) => ({
        user: {
          first_name: items?.first_name ?? '',
          last_name: items?.last_name ?? '',
          gender: 'Male',
          phone_number:
            items?.phone_number.charAt(0) !== '+'
              ? `+${items?.phone_number}`
              : items?.phone_number
        },
        note: items?.note ?? '',
        amount_debit: +items?.credit || 0,
        maturity_date: items?.maturity_date
          ? convertToMilliseconds(items?.maturity_date)
          : 0,
        currency_id: items?.currency ?? ''
      }));
      purchaserService
        .createExcel({ cashbox_id: cashboxId, items: createData })
        .then(res => {
          if (res) {
            dispatch(
              showAlert({
                title: 'Успешно добавлен!',
                type: 'success'
              })
            );
            refetch();
            setImportData([]);
            setOpenImportModal(false);
            setLoading(false);
          }
        });
    }
  };

  // Helper function to convert DD.MM.YYYY to milliseconds
  const convertToMilliseconds = (dateString: string): number => {
    const [day, month, year] = dateString.split('.').map(Number); // Split the date string into day, month, and year
    const date = new Date(year, month - 1, day); // Create a new Date object (months are 0-indexed)
    return date.getTime(); // Get the Unix timestamp in milliseconds
  };

  // Helper function to validate phone number format
  // const isValidPhoneNumber = (phoneNumber: string): boolean => {
  //   const phoneRegex = /^\+998\d{9}$/; // Matches +998 followed by 9 digits
  //   return phoneRegex.test(phoneNumber) && phoneNumber.length === 13; // Check format and length
  // };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>

      <div className="d-flex justify-content-end mt-2 border-top">
        <Button
          style={{ height: '48px', marginTop: '20px' }}
          variant="primary"
          onClick={() => handleSubmit()}
          loading={loading}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Добавить
        </Button>
      </div>
    </div>
  );
};

export default ImportedPurchasserTable;
