/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const customerEntityService = {
  getList: (params: any) => request.get('/dc/charterer-entity', { params }),
  getByIdChartererLimit: (id: any) => request.get(`/charterer-limit/${id}`),
  getById: (id: string) => request.get(`/legal-entity/${id}`),
  getByIdCheckChartererLimit: (
    provider_id: any,
    charterer_id: string,
    params: any
  ) =>
    request.get(`/check-charterer-limit/${provider_id}/${charterer_id}`, {
      params
    }),
  getListCheck: (params: any) => request.get('/dc/charterer/check', { params }),
  getListHistorySettlement: (params: any) =>
    request.get('/dc/settlement', { params }),

  createRoleBackSettlement: (data: any) =>
    request.post('/dc/settlement/rollback', data),

  createLegalEntityRelation: (data: any) =>
    request.post('/legal-entity/relation', data),

  createCustomerSettelment: (data: any) =>
    request.post('/dc/customer/settlement', data),
  createCharterer: (data: any) => request.post('/dc/charterer', data),

  createChartererLimit: (data: any) => request.post('/charterer-limit', data),

  getListCharterer: (params: any) => request.get('/dc/charterer', { params }),
  getCustomerSettlementById: (id: string, params: any) =>
    request.get(`/dc/settlement/${id}`, { params }),

  updateCustomerSettlement: (id: any, data: any) =>
    request.put(`/dc/settlement/${id}`, data),
  updateChartererLimit: (id: any, data: any) =>
    request.put(`/charterer-limit/${id}`, data),

  deleteChartererLimit: (id: any) => request.delete(`/charterer-limit/${id}`)
};

export default customerEntityService;
