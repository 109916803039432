/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import inventoryItemService from 'service/inventoryItem';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';

export type CreateInventoryData = {
  product_name: string;
  barcode: string;
  units: string;
  currency: string;
  cost: string;
  accounts_number: string;
  quantity_fact: string;
  shortage: string;
  surplus: string;
  deficiency_amount: string;
  surplus_amount: string;
  status: string;
  guid: string;
};

const InfoResultTable = () => {
  const { t, i18n } = useTranslation();
  const { pathname, state } = useLocation();
  const info_id = pathname.split('/').pop();
  const { name } = state;
  const [searchValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isFetching, isLoading } = useQuery(
    ['GET_INVENTORY_ITEM', searchValue, currentPage],
    () => {
      if (info_id !== '') {
        const res = inventoryItemService
          .getInventoryItemById(info_id, {
            offset: pageToOffset(currentPage, 10),
            limit: 10,
            search: searchValue
          })
          .then((res: any) => res);

        return res;
      }
    },
    {
      enabled:
        !!info_id || !searchValue || searchValue?.length > 2 || !!currentPage
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const inventoryItemData: CreateInventoryData[] = useMemo(() => {
    const res =
      data?.inventory_items?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          cost: items?.price_in,
          accounts_number: items?.quantity ?? 0,
          quantity_fact: items?.quantity_actual ?? 0,
          shortage: items?.shortage ?? 0,

          surplus: items?.excess ?? 0,
          deficiency_amount: items?.shortage ?? 0,
          surplus_amount: items?.excess_total ?? 0,
          status: items?.status?.name?.[i18n?.language],
          guid: items?.id,
          product_id: items?.product_id
        };
      }) ?? [];

    return res;
  }, [data, info_id]);

  const suppliersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'inventory',
      url: '/inventory/create-inventory'
    },
    {
      label: 'inventory_information',
      url: '/inventory/info'
    },
    {
      label: name ?? '',
      active: true
    }
  ];

  const createInventoryDataTableColumns: ColumnDef<CreateInventoryData>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'accounts_number',
      header: t('quantity_accounting'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: t('number_facts'),

      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'shortage',
      header: t('shortage'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus',
      header: t('excess'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'deficiency_amount',
      header: t('amount_deficiency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus_amount',
      header: t('amount_surplus'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventoryItemData,
    columns: createInventoryDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sumOfAllSurplus = inventoryItemData.reduce((acc: any, item: any) => {
    const allCost =
      item?.surplus_amount === undefined ? 0 : +item.surplus_amount;
    return acc + allCost;
  }, 0);

  const sumOfAllDeficiency = inventoryItemData.reduce((acc: any, item: any) => {
    const allCost =
      item?.deficiency_amount === undefined ? 0 : +item.deficiency_amount;
    return acc + allCost;
  }, 0);

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue === '') {
      refetch();
    }
  }, [searchValue]);

  return (
    <div>
      <PageBreadcrumb items={suppliersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{name ?? ''}</h2>

        <SearchBox
          placeholder={t('search')}
          onChange={e => setSearchValue(e?.target?.value)}
        />
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              isLoading={loading}
            />
            <AdvanceTableCPagination
              count={pageCount}
              page={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </AdvanceTableProvider>

        <div className="footer-inv-create">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount_shortages')}: </p>
            <span>{sumOfAllDeficiency ?? 0}</span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount_surplus')}: </p>
            <span>{sumOfAllSurplus ?? 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoResultTable;
