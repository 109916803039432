import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tables: {} // Object to store table data keyed by id
};

export const {
  actions: tableSavedDataActions,
  reducer: tableSavedDataReducer
} = createSlice({
  name: 'tableSavedData',
  initialState,
  reducers: {
    /**
     * Adds new or updates existing table data for a specific id.
     * Payload should have the shape: { id: string, tableData: any[] }
     */
    addTableData: (state, { payload }) => {
      const { id, tableData } = payload;

      if (state.tables[id]) {
        // Update existing data or append new data
        state.tables[id] = state.tables[id].map(existingItem => {
          // Check for matching barcode_user and update the item if changed
          const updatedItem = tableData.find(
            item => item.barcode_user === existingItem.barcode_user
          );
          if (updatedItem) {
            return { ...existingItem, ...updatedItem }; // Merge the updated fields
          }
          return existingItem; // Keep the existing item if not updated
        });

        // Append new items that do not exist in the state
        tableData.forEach(newItem => {
          const existingItem = state.tables[id].find(
            item => item.barcode_user === newItem.barcode_user
          );
          if (!existingItem) {
            state.tables[id].push(newItem); // Add new items that are not in the current state
          }
        });
      } else {
        // If no data exists for this id, initialize with the new data
        state.tables[id] = tableData;
      }
    },
    /**
     * Removes table data for a specific id.
     * Payload should have the shape: { id: string }
     */
    removeItemFromTableData: (state, { payload }) => {
      const { id, barcodeUser } = payload;

      if (state.tables[id]) {
        // Filter out the item with the matching barcodeUser (or guid)
        state.tables[id] = state.tables[id].filter(
          item => item.barcode_user !== barcodeUser
        );
      }
    },

    removeTableById: (state, { payload }) => {
      const { id } = payload;
      if (state.tables[id]) {
        delete state.tables[id]; // Remove the specific id and its data
      }
    },
    /**
     * Clears all table data.
     */
    clearTableData: state => {
      state.tables = {}; // Reset the tables object
    }
  }
});
