/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const discountService = {
  getList: (params: any) => request.get('/discount', { params }),
  getListDiscountItem: (id: any) => request.get(`/discount-item/${id}`),
  getListCheckDiscountItem: (barcode: any, quantity: any) =>
    request.get(`/check-discount/${barcode}/${quantity}`),
  getDiscountById: (id: any) => request.get(`/discount/${id}`),
  createDiscount: (data: any) => request.post('/discount', data),
  createDiscountItem: (data: any) => request.post('/discount-item', data),
  deleteDiscount: (id: any) => request.delete(`/discount/${id}`),
  deleteDiscountItem: (id: any) => request.delete(`/discount-item/${id}`),
  updateDiscount: (id: any, data: any) => request.put(`/discount/${id}`, data)
};

export default discountService;
