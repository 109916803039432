/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import inventoryItemService from 'service/inventoryItem';
import Button from 'components/base/Button';
import WireOffNotInLists from './WireOffNotInLists';
import SupperModal from 'components/modals/SupperModal';
import { showAlert } from 'store/alert/alert.thunk';
import inventoryService from 'service/inventory';
import { pageToOffset } from 'utils/pageToOffset';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';

export type CreateInventoryData = {
  product_name: string;
  barcode: string;
  units: string;
  currency: string;
  cost: string;
  accounts_number: string;
  quantity_fact: string;
  shortage: string;
  surplus: string;
  deficiency_amount: string;
  surplus_amount: string;
  status: string;
  guid: string;
};

const WriteOffInventoryResultTable = () => {
  const { t, i18n } = useTranslation();
  const { pathname, state } = useLocation();
  const info_id = pathname.split('/').pop();

  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const { name } = state || {};
  const [searchValue, setSearchValue] = useState('');
  const [openNotInList, setOpenNotInList] = useState(false);
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInventoryId, setSelectedInventoryId] = useState<string | null>(
    null
  );

  const [openFinishSupperModal, setOpenFinishSupperModal] = useState(false);
  const [confirmToFinish, setConfirmToFinish] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isFetching, isLoading } = useQuery(
    ['GET_INVENTORY_ITEM', currentPage],
    () => {
      if (info_id !== '') {
        const res = inventoryItemService
          .getInventoryItemById(info_id, {
            offset: pageToOffset(currentPage, 10),
            limit: 10,
            search: searchValue,
            write_off: true
          })
          .then((res: any) => res);

        return res;
      }
    },
    {
      enabled: !!info_id || !!currentPage
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const inventoryItemData: CreateInventoryData[] = useMemo(() => {
    const res =
      data?.inventory_items?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          cost: items?.price_in,
          accounts_number: items?.quantity ?? 0,
          quantity_fact: items?.quantity_actual ?? 0,
          shortage: items?.shortage ?? 0,

          surplus: items?.excess ?? 0,
          deficiency_amount: items?.shortage ?? 0,
          surplus_amount: items?.excess_total ?? 0,
          status: items?.status?.name?.[i18n?.language],
          guid: items?.id,
          product_id: items?.product_id
        };
      }) ?? [];

    return res;
  }, [data, info_id]);

  const suppliersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'write-off',
      url: '/write-off/write-off-inventory'
    },
    {
      label: 'result_inventory',
      url: '/write-off/write-off-inventory'
    },
    {
      label: name ?? '',
      active: true
    }
  ];

  const handleDelete = (id: any) => {
    setSelectedInventoryId(id);
    setOpenSupperModal(true);
  };

  const createInventoryDataTableColumns: ColumnDef<CreateInventoryData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },

    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'accounts_number',
      header: t('quantity_accounting'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: t('number_facts'),

      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'shortage',
      header: t('shortage'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus',
      header: t('excess'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'deficiency_amount',
      header: t('amount_deficiency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus_amount',
      header: t('amount_surplus'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventoryItemData,
    columns: createInventoryDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleFinish = () => {
    // if (inventoryItemData?.length === 0) {
    //   dispatch(
    //     showAlert({
    //       title: t('product_not_added')
    //     })
    //   );
    //   setConfirmToFinish(false);
    // }
    /* prettier-ignore */
    if (info_id ) {
      inventoryService.getInventoryById(info_id).then((res: any) => {
        if (res) {
          const today = new Date();
          /* prettier-ignore */
          inventoryService
            .updateInventory(info_id, {
              ...res,
              status_id: 'af0fe0e3-3357-4b29-a33f-138c89cb75db',
              date_close: today.getTime(),
              write_off: true
            })
            .then((response: any) => {
              if (response) {
                dispatch(
                  showAlert({
                    title: `${t("write-off")} ${t("successfully_added")}`,
                    type: 'success'
                  })
                );
                navigate('/write-off/write-off-inventory');
              }
            });
        }
      });
    }
  };

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue === '') {
      refetch();
    }
  }, [searchValue]);

  useEffect(() => {
    if (confirmToDelete && selectedInventoryId) {
      inventoryItemService
        .deleteInventoryItem(selectedInventoryId)
        .finally(() => {
          refetch();
          setConfirmToDelete(false);
          setSelectedInventoryId(null);
          setOpenSupperModal(false);
        });
    }
  }, [confirmToDelete, selectedInventoryId, refetch]);

  useEffect(() => {
    if (confirmToFinish) {
      handleFinish();
    }
  }, [confirmToFinish]);

  return (
    <div>
      <PageBreadcrumb items={suppliersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{name ?? ''}</h2>
        <div className="form-icon-container w-30">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_product')}
              onFocus={e => e.target.select()}
              onChange={e => setSearchValue(e?.target?.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_product')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <div className="mt-3 d-flex gap-2 justify-content-end">
        <Button
          type="button"
          variant="primary"
          onClick={() => setOpenNotInList(true)}
        >
          {t('not_added_list')}
        </Button>
        <Button
          variant="secondary"
          type="button"
          style={{ height: '48px' }}
          onClick={() => setOpenFinishSupperModal(true)}
        >
          {t('complete')}
        </Button>
      </div>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title={t('added_products')}
        bodyText={t('you_want_delete')}
      />

      <SupperModal
        show={openFinishSupperModal}
        setShow={setOpenFinishSupperModal}
        setConfirm={setConfirmToFinish}
        title={t('complete_invertization')}
        bodyText={t('want_complete_inventory')}
      />

      <Modal
        show={openNotInList}
        onHide={() => {
          setOpenNotInList(false);
          refetch();
        }}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('add_cancellation_non_inventoryed_items')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WireOffNotInLists info_id={info_id} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WriteOffInventoryResultTable;
