/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Offcanvas } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import customerEntityService from 'service/charterer';
import { pageToOffset } from 'utils/pageToOffset';
import { useQuery } from 'react-query';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import Createbuyyers from './Createbuyyers';
import Button from 'components/base/Button';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  debitor_id: string;
  creditor_id: string;
  currency_id: string;
};

const SettelementCutomerTable = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [openCreate, setOpenCreate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const navigateTo = (row: any) => {
    navigate(
      `/dtkt/buyyers/buyyers-result/${row?.original?.debitor_id}?currency_id=${row?.original?.currency_id}&&creditor_id=${row?.original?.creditor_id}`,
      {
        state: {
          name: row?.original?.product_provider
        }
      }
    );
  };

  const updateSearchParams = (key: string, value: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(key, value); // Set new value
    } else {
      newParams.delete(key); // Remove if empty
    }
    setSearchParams(newParams);
  };

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
      updateSearchParams('dateFrom', startDateInMillis?.toString());
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
      updateSearchParams('dateFrom', startDateInMillis?.toString());
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
      updateSearchParams('dateTo', endDateInMillis?.toString());
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
      updateSearchParams('dateTo', endDateInMillis?.toString());
    }
  };

  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, isLoading, isFetching } = useQuery(
    ['GET_PROVIDER_LIST', currentPage, adjustedDateTo, dateFrom],
    () => {
      const res = customerEntityService
        .getListCharterer({
          offset: pageToOffset(currentPage, 10),
          limit: 10,
          date_from: dateFrom,
          date_to: adjustedDateTo,
          law_subject_type: 'LEGAL'
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    {
      enabled: !!dateFrom || !!adjustedDateTo || !!currentPage
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const settelementData: SettelementData[] = useMemo(() => {
    const resultData =
      data?.debit_credit_list?.map((items: any) => {
        const remainder =
          (parseFloat(items?.amount_debit) || 0) -
          (parseFloat(items?.amount_credit) || 0);
        return {
          product_provider:
            items?.debitor_law_subject_type === 'INDIVIDUAL'
              ? items?.user?.first_name
              : items?.debitor?.name,
          debitor_id: items?.debitor_id,
          creditor_id: items?.creditor_id,
          currency_id: items?.currency_id,
          currency: items?.currency?.name?.[i18n?.language],
          debt: items?.amount_debit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_debit)
                .replace(/,/g, ' ')
            : 0,
          credit: items?.amount_credit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_credit)
                .replace(/,/g, ' ')
            : 0,
          remainder: remainder
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(remainder)
                .replace(/,/g, ' ')
            : 0
        };
      }) ?? [];

    return resultData;
  }, [data, t, i18n?.language]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      accessorKey: 'product_provider',
      header: t('name_Customer'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: t('debit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: t('credit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: settelementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    // Set initial dateFrom to the beginning of the current month
    const today = new Date();
    const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setDateFrom(beginningOfMonth.getTime());

    // Set initial dateTo to today's date
    setDateTo(today.getTime());
  }, []);

  useEffect(() => {
    const dateFromParam = searchParams.get('dateFrom');
    const dateToParam = searchParams.get('dateTo');
    const pageParam = searchParams.get('pageParam');

    if (pageParam) {
      setCurrentPage(Number(pageParam));
    }

    if (dateFromParam) {
      setDateFrom(Number(dateFromParam));
    }
    if (dateToParam) {
      setDateTo(Number(dateToParam));
    }
  }, [searchParams]);

  return (
    <div>
      <div className="new-rows">
        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder={t('date_from')}
            />
            <label htmlFor="floatingInputCustom">{t('date_from')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder={t('date_to')}
            />
            <label htmlFor="floatingInputCustom">{t('date_to')}</label>
          </Form.Floating>
        </Form.Group>

        <div className="ms-auto">
          <Button
            variant="primary"
            style={{ height: '48px' }}
            onClick={() => setOpenCreate(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('new')}
          </Button>
        </div>

        <Offcanvas
          show={openCreate}
          onHide={() => {
            setOpenCreate(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('new')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Createbuyyers setOpenCreate={setOpenCreate} />
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            navigateTo={navigateTo}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            onClick={(event, page) => {
              if (page) {
                updateSearchParams('pageParam', String(page));
              }
            }}
          />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default SettelementCutomerTable;
