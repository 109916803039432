import { useRef } from 'react';

const useFocusRefs = () => {
  const refs = useRef<{ [key: string]: React.RefObject<HTMLInputElement> }>({});

  const setRef = (key: string) => {
    if (!refs.current[key]) {
      refs.current[key] = { current: null }; // Manually create a ref object
    }
    return refs.current[key];
  };

  const focusRef = (key: string) => {
    const ref = refs.current[key];
    if (ref?.current) {
      ref.current.focus();
      ref.current.select();
    }
  };

  console.log('refs', refs.current);
  return { setRef, focusRef };
};

export default useFocusRefs;
