/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Form } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

// import UpdateAmount from './UpdateAmount';
import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import orderItemService from 'service/orderItem';

export type NewApplicationsInfo = {
  product_name: string;
  barcode: string;
  unit: string;
  currency: string;
  current_balance: string;
  amount: string;
  full_cost: string;
  measure_unit_id: string;
  package_quantity: any;
};

interface NewApplicationsTableProps {
  orderItemData?: any;
  setOrderItemData?: any;
  newOrderId?: any;
  setRef?: any;
  setSavedBarcodes?: any;
}

const NewApplicationsTable = ({
  orderItemData,
  setOrderItemData,
  newOrderId,
  setRef,
  setSavedBarcodes
}: NewApplicationsTableProps) => {
  const { t, i18n } = useTranslation();
  // const [openAmount, setOpenAmount] = useState(false);

  const { data } = useQuery(
    ['GET_ORDER_ITEMS', newOrderId],
    () => {
      if (newOrderId) {
        const res = orderItemService
          .getList({
            offset: 0,
            limit: 100,
            order_id: newOrderId
          })
          .then((res: any) => {
            return res?.order_items;
          });
        return res;
      }
    },
    { enabled: !!newOrderId }
  );

  const generateData: NewApplicationsInfo[] = useMemo(() => {
    const newData =
      data?.map((items: any) => {
        const packageQuantity =
          items?.package_quantity !== undefined ? items.package_quantity : 1;
        const quantity =
          items?.quantity_asked !== undefined
            ? String(items.quantity_asked)
            : '0';
        const totalQuantity =
          items?.total_quantity !== undefined
            ? String(items.total_quantity)
            : '0';
        let integerPart = 0;
        let numerator = 0;
        let denominator = 1;

        let integerPartTotal = 0;
        let numeratorTotal = 0;
        let denominatorTotal = 1;

        if (quantity?.includes('.')) {
          [numerator, denominator] = quantity.split('.').map(Number);
          integerPart = numerator; // Whole number part
          denominator = +`0.${denominator}`; // Remainder of the division
        } else {
          integerPart = +quantity;
        }

        if (totalQuantity?.includes('.')) {
          [numeratorTotal, denominatorTotal] = totalQuantity
            .split('.')
            .map(Number);
          integerPartTotal = numeratorTotal; // Whole number part
          denominatorTotal = +`0.${denominatorTotal}`; // Remainder of the division
        } else {
          integerPartTotal = +totalQuantity;
        }

        const multipleFraction = Math?.round(
          +denominatorTotal * packageQuantity
        );

        const quantityDisplay = quantity?.includes('.')
          ? `${integerPart}/${Math.round(+denominator * packageQuantity)}`
          : integerPart;

        const totalQuantityDisplay = totalQuantity?.includes('.')
          ? `${integerPartTotal}/${multipleFraction}`
          : integerPartTotal;

        return {
          product_name: items?.nomenclature?.name,
          barcode: items?.nomenclature?.barcode,
          unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit.id,
          amount:
            items?.nomenclature?.measure_unit_id ===
            '443bfff1-61e0-4057-8583-d040dc5a0454'
              ? quantityDisplay
              : items?.quantity_asked ?? 0,
          full_cost: items?.price_wholesale ?? 0,
          nomenclature_id: items?.nomenclature_id,
          package_quantity:
            items?.package_quantity !== undefined ? items.package_quantity : 1,
          product_id: items?.product_id,
          product_parent_id: items?.product_id,
          price: items?.price ?? 0,
          price_in: items?.price_in ?? 0,
          price_whosale: items?.price_whosale ?? 0,
          is_vat: items?.is_vat ? items?.is_vat : false,
          measure_unit_kind_id:
            items?.nomenclature?.measure_unit?.measure_unit_kind_id,
          /* prettier-ignore */
          current_balance:
            items?.nomenclature?.measure_unit_id ===
                  '443bfff1-61e0-4057-8583-d040dc5a0454'
                    ? totalQuantityDisplay
                    : items?.total_quantity
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 4,
                          useGrouping: true
                        })
                          .format(items?.total_quantity)
                          .replace(/,/g, ' ')
                      : 0
        };
      }) ?? [];

    setOrderItemData([...orderItemData, ...newData]);

    return newData;
  }, [data, t, i18n?.language]);

  console.log('generateData', generateData);

  const handleRemoveProductItem = (indexToRemove: number, barcode: string) => {
    setOrderItemData(
      orderItemData.filter((_: any, index: any) => index !== indexToRemove)
    );

    setSavedBarcodes((prevBarcodes: any) => {
      const updatedBarcodes = { ...prevBarcodes }; // Make a copy of the previous barcodes
      delete updatedBarcodes[barcode]; // Delete the barcode from the copied object
      return updatedBarcodes; // Return the updated object
    });
  };

  const newApplicationsDataTableColumns: ColumnDef<NewApplicationsInfo>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() =>
              handleRemoveProductItem(
                +rowData?.row?.id,
                rowData.row.original.barcode
              )
            }
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'current_balance',
      header: 'Текущий остаток',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: t('quantity'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';
        const measure_unit_id: string = rowData.row.original.measure_unit_id;

        const currentBalance = rowData?.row?.original?.current_balance;
        const packageQuantity = rowData?.row?.original?.package_quantity || 1;

        const [value, setValue] = useState(amount);

        const calculateAdjustedQuantity = (quantity: string) => {
          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          const quantityStr = String(quantity);

          if (quantityStr?.includes('/')) {
            [numerator, denominator] = quantityStr.split('/').map(Number);
            integerPart = numerator;
          } else {
            integerPart = +quantityStr;
          }

          const fractionalProportion = (denominator * 100) / packageQuantity;
          return quantityStr.includes('/')
            ? `${integerPart}.${Math.round(fractionalProportion)}`
            : integerPart;
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;

          let formattedValue: any = value.replace(/[^0-9,./]/g, ''); // Strip invalid characters

          const numericValue = parseFloat(formattedValue.replace(/[,/]/g, ''));
          const currentBalanceNumber = +currentBalance; // Convert currentBalance to a number

          if (numericValue > currentBalanceNumber) {
            formattedValue = currentBalanceNumber;
          }

          const adjustedQuantity = calculateAdjustedQuantity(formattedValue);

          const updatedItems = orderItemData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: formattedValue, adjustedQuantity };
            }
            return item;
          });

          setOrderItemData(updatedItems);
          setValue(formattedValue);

          const savedBarcodes = updatedItems.reduce((acc: any, item: any) => {
            acc[item.barcode] = { quantity: +item.adjustedQuantity || 0 };
            return acc;
          }, {});
          setSavedBarcodes(savedBarcodes);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;

          const numberPattern = /^-?\d*\.?\d*$/;
          const remainderPattern = /^-?\d*\/?\d*$/;
          if (
            measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454' &&
            remainderPattern.test(newValue)
          ) {
            setValue(newValue);
          } else {
            if (numberPattern.test(newValue)) {
              setValue(newValue);
            }
          }
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="amount"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                ref={setRef(`amount_${rowData.row.id}`)}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'full_cost',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: orderItemData,
    columns: newApplicationsDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default NewApplicationsTable;
