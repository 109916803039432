/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Form } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import productSearchService from 'service/productSearch';
import PriceListCards from './PriceListCards';
import ComponentToPrint from './ComponentToPrint';
import { pageToOffset } from 'utils/pageToOffset';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { showAlert } from 'store/alert/alert.thunk';

const useDebounce = (func: any, delay: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export type PriceListType = {
  name: string;
  variation: string;
  barcode: string;
  original_barcode: string;
  barcode_user: string;
  unit: string;
  guid: string;
  currency_id: string;
  quantity: any;
};

const pageStyle = `@page {
  margin: 0;
  padding: 0;
}
    body {
        -webkit-print-color-adjust: exact;
      }
`;

interface MyProductModalPriceListProps {
  priceListDataTable?: any;
  setPriceListDataTable?: any;
}

const MyProductModalPriceList = ({
  priceListDataTable,
  setPriceListDataTable
}: MyProductModalPriceListProps) => {
  const { t, i18n } = useTranslation();

  const componentRef = useRef<any>(null);
  const dispatch: Dispatch<any> = useDispatch();
  const barcodeInputRef = useRef<HTMLInputElement | any>();

  const [activeCard, setActiveCard] = useState('card-58-30');
  const [selectedRows, setSelectedRows] = useState<PriceListType[]>([]);
  const [count, setCount] = useState('');
  const [hidePrice, setHidePrice] = useState(false);
  const [hideBarcode, setHideBarcode] = useState(false);
  const [selectPriceWhosale, setSlectPriceWhoSale] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchedTableData, setSearchedTableData] = useState<any>([]);

  const { data, isFetching, isLoading } = useQuery(
    ['GET_PRODUCTS', currentPage, searchInputValue],
    () => {
      if (searchInputValue?.length > 2) {
        const resData = productSearchService
          .getListProductLast({
            limit: 10,
            offset: pageToOffset(currentPage, 10),
            search: searchInputValue
          })
          .then((res: any) => {
            return res;
          });
        return resData;
      }
    },
    {
      enabled: !!currentPage || searchInputValue?.length > 2
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  useEffect(() => {
    if (searchInputValue === '') {
      // If search input is empty, reset the data table
      setSearchedTableData([]);
      setPageCount(1);
    } else if (data) {
      const resData = data?.products?.map((items: any) => ({
        name: items?.name,
        barcode: items?.barcode,
        price_sale: items?.price ?? 0,
        price_whosale: items?.price_whosale ?? 0,
        measure_unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
        measure_unit_id: items?.nomenclature?.measure_unit?.id,
        scale_code:
          items?.nomenclature?.measure_unit?.id ===
          '208baabf-b8dd-4e2e-b3a0-05c2f6744052'
            ? items?.scale_code ?? 0
            : '',
        quantity: 0,
        guid: items?.id
      }));

      if (resData) {
        setSearchedTableData(resData);
      }
    }
  }, [data, searchInputValue, t, i18n?.language]);

  const handleAddProductItem = (product: PriceListType) => {
    const isDuplicate = priceListDataTable.some(
      (item: PriceListType) => item.barcode === product.barcode
    );

    if (!isDuplicate) {
      setPriceListDataTable([...priceListDataTable, product]);
      dispatch(
        showAlert({
          title: t('product_successfully_added'),
          type: 'success'
        })
      );
    } else {
      dispatch(
        showAlert({
          title: t('product_barcode_already_added!'),
          type: 'warning'
        })
      );
    }
  };

  const handleRemoveProductItem = (indexToRemove: number) => {
    setPriceListDataTable(
      priceListDataTable.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const priceListTableSearchColumns: ColumnDef<PriceListType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleAddProductItem(rowData.row.original)}
          >
            <FeatherIcon icon="plus" className="cursor-pointer" size={18} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'measure_unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price_sale',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const priceListTableColumns: ColumnDef<PriceListType>[] = [
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'measure_unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price_whosale',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price_sale',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'quantity',
      header: t('quantity'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          value: string,
          index: any
        ) => {
          const updatedItems = priceListDataTable?.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: +value };
            }
            return item;
          });
          setPriceListDataTable(updatedItems);
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          updateProductInvoiceData(name, value, index);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue)) {
            // Allow empty string for intermediate state
            setValue(newValue);
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Tab') {
            const { name, value } = e.target as HTMLInputElement;
            updateProductInvoiceData(name, value, rowData.row?.id);
          }
        };
        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="quantity"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => {
                  if (value === '') {
                    setValue('1'); // Reset to default value if left empty
                  }
                  onBlur(e, rowData.row?.id);
                }}
                onFocus={e => e.target.select()}
                onKeyDown={handleKeyDown}
                min="1"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleRemoveProductItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={18}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    }
  ];

  const tablesearch = useAdvanceTable({
    data: searchedTableData,
    columns: priceListTableSearchColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const table = useAdvanceTable({
    data: priceListDataTable,
    columns: priceListTableColumns,
    pageSize: 50,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleRowSelection = (rows: any) => {
    const selectedData = rows?.map((row: any) => row.original);
    const updatedSelectedRows: PriceListType[] = [];

    // Add or update selected items based on quantity
    selectedData.forEach((item: any) => {
      let index;
      while (
        (index = updatedSelectedRows.findIndex(
          selectedItem => selectedItem.barcode === item.barcode
        )) !== -1
      ) {
        updatedSelectedRows.splice(index, 1);
      }

      if (item.quantity > 0) {
        for (let i = 0; i < item.quantity; i++) {
          updatedSelectedRows.push({ ...item });
        }
      }
    });

    // Merge the updated selected items with the filtered existing selected items
    setSelectedRows([...updatedSelectedRows]);
  };

  useEffect(() => {
    // Get all rows' data from the table
    const allRows = table.getCoreRowModel().rows;

    // Handle selection with all rows
    handleRowSelection(allRows);
  }, [table, priceListDataTable]);

  const handleChange = () => {
    if (count === '') {
      return;
    }

    const updatedSelectedRows = priceListDataTable?.map(
      (item: PriceListType) => ({
        ...item,
        quantity: parseInt(count, 10)
      })
    );

    setSelectedRows(updatedSelectedRows);

    const updatedPriceListDataTable = priceListDataTable?.map(
      (item: PriceListType) => {
        const selectedItem = updatedSelectedRows.find(
          (row: any) => row.barcode === item.barcode
        );
        return selectedItem
          ? { ...item, quantity: selectedItem.quantity }
          : item;
      }
    );

    setPriceListDataTable(updatedPriceListDataTable);
  };

  const debouncedHandleGetProduct = useDebounce(async (e: any) => {
    if (e?.key === 'Enter') {
      productSearchService
        .getListProductLast({
          search: searchInputValue
        })
        .then((res: any) => {
          const isDuplicate = priceListDataTable.some(
            (item: PriceListType) => item.barcode === res?.products?.[0].barcode
          );

          const resData = res?.products?.map((items: any) => ({
            name: items?.name,
            barcode: items?.barcode,
            price_sale: items?.price ?? 0,
            price_whosale: items?.price_whosale ?? 0,
            measure_unit:
              items?.nomenclature?.measure_unit?.name?.[i18n?.language],
            measure_unit_id: items?.nomenclature?.measure_unit?.id,
            scale_code:
              items?.nomenclature?.measure_unit?.id ===
              '208baabf-b8dd-4e2e-b3a0-05c2f6744052'
                ? items?.scale_code ?? 0
                : '',
            quantity: 0,
            guid: items?.id
          }));

          if (!isDuplicate) {
            setPriceListDataTable([...priceListDataTable, ...resData]);
            barcodeInputRef.current?.select();
            dispatch(
              showAlert({
                title: t('product_successfully_added'),
                type: 'success'
              })
            );
          } else {
            dispatch(
              showAlert({
                title: t('product_barcode_already_added!'),
                type: 'warning'
              })
            );
          }
        });
    }
  }, 500);

  useEffect(() => {
    if (barcodeInputRef.current) {
      setTimeout(() => {
        barcodeInputRef.current.focus();
      }, 500);
    }
  }, [barcodeInputRef]);

  console.log('selectedRows', selectedRows);

  return (
    <div>
      <div className="form-icon-container">
        <Form.Floating>
          <Form.Control
            type="text"
            ref={barcodeInputRef}
            placeholder={t('enter_product')}
            onFocus={e => e.target.select()}
            onChange={e => setSearchInputValue(e.target.value)}
            className="form-icon-input w-25"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                debouncedHandleGetProduct(e);
              }
            }}
          />
          <label
            htmlFor="floatingInputCustom"
            className="form-icon-label text-700"
          >
            {t('enter_product')}
          </label>
        </Form.Floating>
        <FontAwesomeIcon icon={faSearch} className="text-900 fs-9 form-icon" />
      </div>

      {/* <PriceListFilter
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
      /> */}

      <AdvanceTableProvider {...tablesearch}>
        <div className="mt-4 mx-n3 px-lg-4 border-top border-bottom border-300 ">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <div className="mt-5 border-top pt-3">
        <div className="d-flex gap-2 align-items-center justify-content-between">
          <h4>{t('added_products')}</h4>

          <div className="d-flex gap-2">
            <Form.Floating>
              <Form.Control
                type="number"
                placeholder={t('quantity')}
                value={count}
                onChange={e => setCount(e?.target?.value)}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('quantity')}</label>
            </Form.Floating>

            <Button
              // disabled={selectedRows?.length > 0 ? false : true}
              variant="primary"
              onClick={() => handleChange()}
            >
              {t('changed')}
            </Button>
          </div>
        </div>
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n3 px-lg-4 border-top border-bottom border-300">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      </div>

      <div className="d-flex mt-3 justify-content-between">
        <PriceListCards
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          hidePrice={hidePrice}
          hideBarcode={hideBarcode}
          selectPriceWhosale={selectPriceWhosale}
        />

        <div className="w-25 d-flex flex-column">
          <Form.Group className="printing-check  mt-auto">
            <Form.Check
              type="switch"
              checked={selectPriceWhosale}
              onChange={e => setSlectPriceWhoSale(e?.target?.checked)}
              className="cursor-pointer"
              label={t('select_price_wholesale')}
            />
          </Form.Group>
          <Form.Group className="printing-check">
            <Form.Check
              type="switch"
              checked={hidePrice}
              onChange={e => setHidePrice(e?.target?.checked)}
              className="cursor-pointer"
              label={t('hide_price')}
            />
          </Form.Group>

          <Form.Group className="printing-check">
            <Form.Check
              type="switch"
              checked={hideBarcode}
              onChange={e => setHideBarcode(e?.target?.checked)}
              className="cursor-pointer"
              label={t('hide_barcode')}
            />
          </Form.Group>
          <ReactToPrint
            trigger={() => (
              <Button
                disabled={selectedRows?.length > 0 ? false : true}
                style={{ height: '48px', marginTop: '20px' }}
                variant="primary"
              >
                {t('print')}
              </Button>
            )}
            content={() => componentRef?.current}
            pageStyle={pageStyle}
          />
        </div>

        <div style={{ display: 'none' }}>
          <div ref={componentRef}>
            {selectedRows?.map((items: any) => (
              <ComponentToPrint
                key={items}
                activeCard={activeCard}
                name={items?.name}
                barcode={items?.barcode}
                price={items?.price_sale}
                price_whosale={items?.price_whosale}
                hidePrice={hidePrice}
                hideBarcode={hideBarcode}
                selectPriceWhosale={selectPriceWhosale}
                scale_code={items?.scale_code}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProductModalPriceList;
