/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, CSSProperties } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import EChartsReactCore from 'echarts-for-react/lib/core';

echarts.use([TooltipComponent, LineChart, GridComponent]);

const getDefaultOptions = (
  dates: string[],
  seriesData: any[],
  getThemeColor: (name: string) => string
) => ({
  color: seriesData?.map((_, index) => getThemeColor(`color-${index + 1}`)), // Dynamically assign colors
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: (params: any[]) => {
      const date = params[0]?.axisValue as string;
      const formattedValues = params
        ?.map(param => {
          const value = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 4,
            useGrouping: true
          })
            .format(param.value as number)
            .replace(/,/g, ' ');
          return `${param.marker} ${param.seriesName}: ${value}`;
        })
        .join('<br/>');
      return `${date}<br/>${formattedValues}`;
    }
  },
  xAxis: {
    type: 'category',
    data: dates,
    boundaryGap: false,
    axisLabel: {
      color: getThemeColor('gray-900'),
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
      fontSize: 12.8,
      margin: 16
    },
    axisLine: {
      lineStyle: { color: getThemeColor('gray-200') }
    },
    axisTick: { show: false }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: getThemeColor('gray-900'),
      formatter: (value: number) => `${value / 1000}M`
    },
    axisLine: {
      lineStyle: { color: getThemeColor('gray-200') }
    },
    splitLine: {
      lineStyle: { color: getThemeColor('gray-200') }
    }
  },
  series: seriesData,
  grid: {
    right: 40,
    left: 6,
    bottom: '0',
    top: 10,
    containLabel: true
  }
});

const AnalyticsCallCampaignChart = ({
  data,
  style,
  randomColors
}: {
  data?: any;
  style: CSSProperties;
  randomColors?: any;
  selectedBranchId?: string;
  legalEntityId?: string;
}) => {
  const { getThemeColor } = useAppContext();

  console.log('data', data);

  const chartRef = useRef<null | EChartsReactCore>(null);
  /* prettier-ignore */
  const dates =
    data?.flatMap((entity: any) =>
        entity.info.amounts?.map((amount: any) => amount.range)
      )
      ?.sort(
        (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
      ) || [];

  const seriesData = data?.map((entity: any) => ({
    name: entity.legal_entity.name,
    type: 'line',
    smooth: 0.4,
    symbolSize: 6,
    itemStyle: {
      color: randomColors[entity?.legal_entity?.id] || '#9103e4' // Apply random color if exists
    },
    data: entity.info.amounts?.map((amount: any) => amount.amount || 0)
  }));

  const updateDimensions = () => {
    if (window.innerWidth < 576) {
      chartRef.current?.getEchartsInstance().setOption({
        xAxis: {
          axisLabel: { showMaxLabel: false }
        }
      });
    } else {
      chartRef.current?.getEchartsInstance().setOption({
        xAxis: {
          axisLabel: { showMaxLabel: true }
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [chartRef.current]);

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getDefaultOptions(dates, seriesData, getThemeColor)}
      style={style}
    />
  );
};

export default AnalyticsCallCampaignChart;
