import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import ABCAnalysisFilter from './ABCAnalysisFilter';
import ABCAnalysisTable from './ABCAnalysisTable';

export const frozenRemedyBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'analytics',
    url: '/analytics/revaluation/invoice'
  },

  {
    label: 'abc_analysis',
    active: true
  }
];

const ABCAnalysis = () => {
  const { t } = useTranslation();
  const [selectedAnalysis, setSelectedAnalysis] = useState('');
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [optionSenderId, setOptionSenderId] = useState('');
  const [warehouseId, setWarehouseId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [branchId, setBranchId] = useState('');

  console.log('selectedAnalysis', selectedAnalysis);
  return (
    <div>
      <PageBreadcrumb items={frozenRemedyBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('abc_analysis')}</h2>

        <ABCAnalysisFilter
          setSelectedAnalysis={setSelectedAnalysis}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionSenderId={setOptionSenderId}
          setWarehouseId={setWarehouseId}
          setCurrencyId={setCurrencyId}
          branchId={branchId}
          setBranchId={setBranchId}
        />

        <ABCAnalysisTable
          selectedAnalysis={selectedAnalysis}
          dateFrom={dateFrom}
          dateTo={dateTo}
          optionSenderId={optionSenderId}
          warehouseId={warehouseId}
          currencyId={currencyId}
          branchId={branchId}
        />
      </div>
    </div>
  );
};

export default ABCAnalysis;
