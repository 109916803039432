/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { Offcanvas, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AddCustomer from './create/AddCustomer';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';
import { CustomerLimitIcon } from 'assets/img/icons/icons';

export const customerBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'dt_kt',
    url: '/dtkt/provider'
  },
  {
    label: 'customer',
    active: true
  }
];

export type CustomerData = {
  individual: string;
  activity: string;
  country: string;
  region: string;
  area: string;
  address: string;
  phone: string;
  status: string;
};

const CustomerPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [openCustomer, setOpenCustomer] = useState(false);

  const navigateTo = (row: any) => {
    navigate(`/dtkt/customer/customer-limits/${row?.original?.guid}`);
  };

  //Get Customer
  const { data, refetch } = useQuery(['GET_CUSTOMER'], () => {
    const res = customerEntityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entities);
    return res;
  });

  const customerEntityData: CustomerData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          individual: items?.name,
          activity: items?.activity_kind?.name?.[i18n?.language],
          country: items?.country?.name?.[i18n?.language],
          region: items?.region?.name?.[i18n?.language],
          area: items?.district?.name?.[i18n?.language],
          address: items?.address,
          phone: items?.phone_number,
          status: items?.legal_entity_status?.name?.[i18n?.language],
          is_discounter: items?.is_discounter === true ? t('yes') : t('no'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n]);

  const customerDataTableColumns: ColumnDef<CustomerData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div className="d-flex">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: 'fixed' }}>
                  {t('customer_limit')}
                </Tooltip>
              }
            >
              <div>
                <Button
                  variant="hover"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigateTo(rowData.row);
                  }}
                >
                  <CustomerLimitIcon />
                </Button>
              </div>
            </OverlayTrigger>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'individual',
      header: t('individual'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'activity',
      header: t('activity_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'address',
      header: t('address'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'phone',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'is_discounter',
      header: t('discount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          [t('active')]: { bg: '#BBFBD0', text: '#119C2B' },
          [t('blocked')]: { bg: '#FDDFDD', text: '#F2271C' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div className="d-flex">
            <p
              style={{
                background: backgroundColor,
                padding: '8px 10px',
                textAlign: 'center',
                borderRadius: '8px',
                color: textColor,
                fontWeight: '600',
                margin: '0'
              }}
            >
              {status}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: customerEntityData,
    columns: customerDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={customerBreadcrumbItems} />
      <div className="d-flex justify-content-between align-items-center">
        <h2>{t('customer')}</h2>
        <Button
          variant="primary"
          style={{ height: '48px' }}
          onClick={() => setOpenCustomer(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {t('new')}
        </Button>
      </div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <Offcanvas
        show={openCustomer}
        onHide={() => setOpenCustomer(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('customer')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddCustomer setOpenCustomer={setOpenCustomer} refetch={refetch} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CustomerPage;
