/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import discountService from 'service/discount';
import { showAlert } from 'store/alert/alert.thunk';

interface CreateDiscountProps {
  setOpenDiscount: (show: boolean) => void;
  selectedDiscountId?: any;
  refetch?: any;
  setSelectedDiscountId?: any;
}

const CreateDiscount = ({
  setOpenDiscount,
  selectedDiscountId,
  refetch,
  setSelectedDiscountId
}: CreateDiscountProps) => {
  const { t } = useTranslation();

  const statusOption = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];

  const lawSubjectTypes = [
    {
      value: 'LEGAL',
      label: t('LEGAL')
    },
    {
      value: 'INDIVIDUAL',
      label: t('INDIVIDUAL')
    }
  ];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const hasFetchedData = useRef(false);
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    if (selectedDiscountId === '') return setLoading(false);

    discountService
      .getDiscountById(selectedDiscountId)
      .then((res: any) => {
        const computed = {
          name: res?.name,
          percentage: res?.percentage,
          from_quantity: res?.from_quantity,
          to_quantity: res?.to_quantity,
          law_subject_type: res?.law_subject_type,
          status: res?.status === true ? true : false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedDiscountId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createData = {
      name: data?.name || '',
      percentage: +data?.percentage || 0,
      from_quantity: +data?.from_quantity || 0,
      to_quantity: +data?.to_quantity || 0,
      law_subject_type: data?.law_subject_type,
      status: data?.status === 'true' || data?.status === true ? true : false
    };

    discountService.createDiscount(createData).then((res: any) => {
      if (res) {
        setOpenDiscount(false);
        setLoading(false);
        dispatch(
          showAlert({
            title: `${t('discount')} ${t('successfully_added')}`,
            type: 'success'
          })
        );
        refetch();
        setSelectedDiscountId('');
      }
    });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name || '',
      percentage: +data?.percentage || 0,
      from_quantity: +data?.from_quantity || 0,
      to_quantity: +data?.to_quantity || 0,
      law_subject_type: data?.law_subject_type,
      status: data?.status === 'true' || data?.status === true ? true : false,
      id: selectedDiscountId
    };

    discountService
      .updateDiscount(selectedDiscountId, updateData)
      .finally(() => {
        setLoading(false);
        setOpenDiscount(false);
        refetch();
        setSelectedDiscountId('');
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef?.current?.select();
      }, 500);
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedDiscountId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedDiscountId]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('discount_name')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.name}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('discount_name')}
                  </label>

                  {errors?.name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="percentage"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('percentage')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.percentage}
                  />
                  <label htmlFor="floatingInputCustom">{t('percentage')}</label>

                  {errors?.percentage && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="from_quantity"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('from_quantity')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.from_quantity}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('from_quantity')}
                  </label>

                  {errors?.from_quantity && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="to_quantity"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('to_quantity')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.to_quantity}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('to_quantity')}
                  </label>

                  {errors?.to_quantity && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="law_subject_type"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.law_subject_type}
                    >
                      <option className="d-none" value=""></option>
                      {lawSubjectTypes?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('type')}</label>
                  </Form.Floating>
                )}
              />
              {errors?.law_subject_type && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {statusOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                  </Form.Floating>
                )}
              />
              {errors?.status && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default CreateDiscount;
