/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarcode } from '@fortawesome/free-solid-svg-icons';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from 'components/base/Button';
import RemainderCreateFiltert from './RemainderCreateFilter';
import reportInvoiceService from 'service/reportInvoice';

interface RemainderFilterProps {
  setGeneratedTableData?: any;
  savedValues?: any;
  setSavedValues?: any;
  setGetName?: any;
  setGetBarcode?: any;
  getName?: any;
  getBarcode?: any;
  setGetFilterData?: any;
  setCurrencyName?: any;
  setTotalQuantity?: any;
  setTotalincome?: any;
  setTotalSale?: any;
  currentPage?: any;
  setPageCount?: any;
  getFilterData?: any;
}

const RemainderFilter = ({
  setGeneratedTableData,
  savedValues,
  setSavedValues,
  setGetName,
  setGetBarcode,
  getName,
  getBarcode,
  setGetFilterData,
  setCurrencyName,
  setTotalQuantity,
  setTotalincome,
  setTotalSale,
  currentPage,
  setPageCount,
  getFilterData
}: RemainderFilterProps) => {
  const url = process.env.REACT_APP_CDN_URL;
  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleExport = () => {
    setLoading(true);
    const customFilename: string = 'Остаток.xlsx';

    // Prepare request payload
    const requestData = {
      name: getName,
      date: getFilterData?.date_on ? getFilterData?.date_on : undefined,
      barcode: getBarcode,
      supplier_id: getFilterData?.supplier_id
        ? getFilterData?.supplier_id
        : undefined,
      branch_id: getFilterData?.branch_id
        ? getFilterData?.branch_id
        : undefined,
      currency_id: getFilterData?.currency_id
        ? getFilterData?.currency_id
        : currencyId,
      warehouse_id: getFilterData?.warehouse_id
        ? getFilterData?.warehouse_id
        : undefined,
      measure_unit_id: getFilterData?.measure_unit_id
        ? getFilterData?.measure_unit_id
        : undefined,
      is_group: getFilterData?.is_group ? getFilterData?.is_group : true,
      is_negative: getFilterData?.is_negative
        ? getFilterData?.is_negative
        : true
    };

    // Fetch report and trigger download
    reportInvoiceService
      ?.getReportRemainingListExcel(requestData)
      .then((res: any) => {
        setLoading(false);
        if (res?.filename !== '') {
          const resUrl = `${url}${res?.filename}`;
          const link = document.createElement('a');
          link.href = resUrl;
          link.download = customFilename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error==>', error);
      });
  };

  return (
    <div>
      <Form className="d-flex gap-3">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_product')}
              onFocus={e => e.target.select()}
              onChange={e => setGetName(e.target.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_product')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('scan_barcode')}
              onFocus={e => e.target.select()}
              onChange={e => setGetBarcode(e.target.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('scan_barcode')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faBarcode}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <Button
          style={{ height: '48px' }}
          variant="outline-primary"
          onClick={() => setOpenModal(true)}
        >
          <FeatherIcon icon="filter" className="me-2" size={20} />
          {t('filter')}
        </Button>

        <Button
          style={{ height: '48px' }}
          variant="outline-primary"
          onClick={handleExport}
          loading={loading}
          loadingPosition="start"
        >
          <FeatherIcon icon="upload-cloud" className="me-2" size={20} />
          {t('export')}
        </Button>

        <Offcanvas
          show={openModal}
          onHide={() => {
            setOpenModal(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('filter')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <RemainderCreateFiltert
              setGeneratedTableData={setGeneratedTableData}
              savedValues={savedValues}
              setSavedValues={setSavedValues}
              setOpenModal={setOpenModal}
              getName={getName}
              getBarcode={getBarcode}
              setGetFilterData={setGetFilterData}
              setCurrencyName={setCurrencyName}
              setTotalQuantity={setTotalQuantity}
              setTotalincome={setTotalincome}
              setTotalSale={setTotalSale}
              currentPage={currentPage}
              setPageCount={setPageCount}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </Form>
    </div>
  );
};

export default RemainderFilter;
