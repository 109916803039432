import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PurchaseByInvoiceTable from './PurchaseByInvoiceTable';
import Filter from './filter';

import './styles.scss';

export const invoiceBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'reports',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'purchase',
    url: '/reports/purchase/invoice'
  },

  {
    label: 'purchase_invoice',
    active: true
  }
];

const PurchaseByInvoice = () => {
  const { t } = useTranslation();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [optionSenderId, setOptionSenderId] = useState('');
  const [warehouseId, setWarehouseId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [typeId, setTypeId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [filialId, setFilialId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div>
      <PageBreadcrumb items={invoiceBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('purchase_invoice')}</h2>

        <Filter
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionSenderId={setOptionSenderId}
          setWarehouseId={setWarehouseId}
          setCurrencyId={setCurrencyId}
          setTypeId={setTypeId}
          setPaymentId={setPaymentId}
          optionSenderId={optionSenderId}
          warehouseId={warehouseId}
          currencyId={currencyId}
          typeId={typeId}
          paymentId={paymentId}
          setCurrentPage={setCurrentPage}
          setFilialId={setFilialId}
          filialId={filialId}
        />
        <PurchaseByInvoiceTable
          dateFrom={dateFrom}
          dateTo={dateTo}
          optionSenderId={optionSenderId}
          warehouseId={warehouseId}
          currencyId={currencyId}
          typeId={typeId}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          paymentId={paymentId}
          filialId={filialId}
        />
      </div>
    </div>
  );
};

export default PurchaseByInvoice;
