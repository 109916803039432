import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

import { authReducer } from './auth/auth.slice';
import { alertReducer } from './alert/alert.slice';
import { shiftIdReducer } from './shiftId/shiftId.slice';
import { registerReducer } from './register/register.slice';
import { tableSettingsReducer } from './tableSettings/tableSettings.slice';
import { tableSavedDataReducer } from './tableSavedData/tableSavedData.slice';

const authPersistConfig = {
  key: 'auth',
  storage
};

const shiftIdPersistConfig = {
  key: 'shiftId',
  storage
};

const registerPersistConfig = {
  key: 'register',
  storage
};

const tableSettingsPersistConfig = {
  key: 'tableSettings',
  storage
};
const tableSavedDataPersistConfig = {
  key: 'tableSavedData',
  storage
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  shiftId: persistReducer(shiftIdPersistConfig, shiftIdReducer),
  register: persistReducer(registerPersistConfig, registerReducer),
  tableSettings: persistReducer(
    tableSettingsPersistConfig,
    tableSettingsReducer
  ),
  tableSavedData: persistReducer(
    tableSavedDataPersistConfig,
    tableSavedDataReducer
  ),
  alert: alertReducer
});

export default rootReducer;
