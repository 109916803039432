/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faKey } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UAParser from 'ua-parser-js';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { authActions } from 'store/auth/auth.slice';
import CInputMask from 'components/forms/CInputMask';
import useAuth from 'service/auth/useAuth';
import usePasswordToggle from 'utils/usePasswordToggle';
import { shiftIdActions } from 'store/shiftId/shiftId.slice';

const SignInForm = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    phone_number: yup
      .string()
      .required(t('required_field'))
      .matches(
        //eslint-disable-next-line
        /^(\+998)\s\(([1-9][0-9])\)\s([0-9]{3})-([0-9]{2})-([0-9]{2})$/,
        t('enter_valid_phone')
      ),
    password: yup.string().required(t('required_field'))
    // .matches(
    //   //eslint-disable-next-line
    //   /^(?:1|[1-8]{5})|([a-zA-Z]{5,})$/,
    //   'Пароль должен быть длиной 5'
    // )
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  // const key: any = '6Ld0aoQqAAAAAGKwMKXeaKC2ODRnVRJ3i-5vxdMS'; // local
  // const key: any = process.env.REACT_APP_GOOGLE_CAPCHA;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [uaResult, setUaResult] = useState<any>(null);
  const [errorCounter, setErrorCounter] = useState(0);
  // const [captcheIsDone, setCaptcheIsDone] = useState(false);
  const [loading, setLoading] = useState(false);

  // const onChange = () => {
  //   setCaptcheIsDone(true);
  // };

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    setUaResult(result);
  }, []);

  const { login, sendOtp } = useAuth({
    loginProps: {
      onSuccess: (value: any) => {
        const states = {
          token: value.data.access_token,
          user: value.data.user,
          legal_entity: value?.data?.legal_entity
        };
        dispatch(authActions.login(states));
        dispatch(
          shiftIdActions.setShiftId(
            value.data.cashbox_id ? value.data.cashbox_id : ''
          )
        );
        navigate('/');
      }
    },
    sendOtpProps: {
      onSuccess: (value: any) => {
        const data = getValues();
        const states = {
          send_otp_id: value.data.id,
          phone_number: data?.phone_number?.replace(/\D/g, '')
        };
        dispatch(authActions.login(states));
        navigate('/verify-otp');
        setErrorCounter(0);
      }
    }
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const convertedPhone = data?.phone_number?.replace(/\D/g, '');
    const deviceModel = uaResult?.device?.model
      ? `_${uaResult.device.model}`
      : '';
    try {
      await login.mutateAsync({
        phone_number: `+${convertedPhone}`,
        password: data.password,
        user_device: `WEB#${uaResult.browser.name}${deviceModel}`
      });
    } catch (error: any) {
      setLoading(false);

      if (error && passwordInputRef.current) {
        passwordInputRef.current.focus();
        passwordInputRef.current.select();
      }

      if (error?.status === 400) {
        setErrorCounter(errorCounter + 1);
      }
    }
  };

  useEffect(() => {
    if (errorCounter === 3) {
      const data = getValues();
      const convertedPhone = data?.phone_number?.replace(/\D/g, '');

      try {
        sendOtp.mutateAsync({
          phone_number: `+${convertedPhone}`
        });
      } catch (error: any) {
        console.log('Send Otp error==>', error);
      }
    }
  }, [errorCounter]);

  useEffect(() => {
    register('password');
  }, [register]);

  useEffect(() => {
    const subscription = watch(value => {
      const phoneNumber = value.phone_number || '';
      const phoneRegex = /^\+998\s\(\d{2}\)\s\d{3}-\d{2}-\d{2}$/;
      if (phoneRegex.test(phoneNumber) && passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center mb-7">
        <h3 className="text-1000">{t('access_your_account')}</h3>
        {/* <p className="text-700">{t('access_your_account')}</p> */}
      </div>
      <Form.Group className="mb-3 text-start">
        <Form.Label>{t('phone_number')}</Form.Label>
        <div className="form-icon-container">
          <CInputMask
            name="phone_number"
            //eslint-disable-next-line
            placeholder="+998 (99) 999-99-99"
            //eslint-disable-next-line
            mask="+\9\9\8 (99) 999-99-99"
            maskChar="_"
            control={control}
            icon={faPhone}
            isInvalid={!!errors.phone_number}
            errors={errors}
            focusInput={true}
          />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">{t('password')}</Form.Label>
        <div className="form-icon-container position-relative">
          <Form.Control
            {...register('password')}
            type={PasswordInputType}
            className="form-icon-input"
            placeholder={t('password')}
            isInvalid={!!errors.password}
            onFocus={e => e.target.select()}
            ref={passwordInputRef}
            onChange={e => setValue('password', e.target.value)}
          />
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
          <span
            style={{
              position: 'absolute',
              top: '8px',
              right: !errors.password ? '1rem' : '2rem',
              cursor: 'pointer'
            }}
          >
            {ToggleIcon}
          </span>
        </div>
      </Form.Group>

      {/* <div className="mb-3">
        <ReCAPTCHA sitekey={key} onChange={onChange} hl={i18n?.language} />
      </div> */}
      <Button
        // disabled={!captcheIsDone}
        type="submit"
        variant="primary"
        className="w-100 mb-2"
        loading={loading}
        loadingPosition="start"
      >
        {t('login')}
      </Button>
      <div className="text-center">
        <Link
          to={`/sign-up`}
          className="fs-9 fw-bold"
          style={{ fontFamily: 'inherit' }}
        >
          {t('register')}
        </Link>
      </div>
    </Form>
  );
};

export default SignInForm;
