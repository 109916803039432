/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import NewReturningTableSearch from './NewReturningTableSearch';
import NewReturningTable from './NewReturningTable';
import usePageSearchParams from 'utils/usePageSearchParams';
import returnService from 'service/return';
import returnOutService from 'service/returnOut';
import returnItemService from 'service/returnItemService';
import { showAlert } from 'store/alert/alert.thunk';
import useFocusRefs from 'utils/useFocusRefs';
import legalEntitySettingsService from 'service/legalEntitySettings';
import './styles.scss';

const NewReturning = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();
  const newReturningId = pathname.split('/').pop();
  const senderId = searchParams?.get('sender_id');
  const dispatch: Dispatch<any> = useDispatch();
  const { setRef, focusRef } = useFocusRefs();

  const [returnItemData, setReturnItemData] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [isAutoTab, setIsAutoTab] = useState(false);
  const [savedBarcodes, setSavedBarcodes] = useState<any>([]);

  useQuery(['GET_SETTINGS'], async () => {
    await legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 20
      })
      .then((res: any) => {
        setIsAutoTab(res?.legal_entity_settings[0].is_auto_tab ?? 0);
      });
  });

  const newReturningBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: t('returns'),
      url: '/returning/outgoing'
    },
    {
      label: t('outgoing'),
      url: '/returning/outgoing'
    },
    {
      label: t('new'),
      active: true
    }
  ];

  const sumOfQuantity = returnItemData.reduce((acc: any, item: any) => {
    const quantity = String(item?.amount) || '0';

    let integerPart = 0;
    let numerator = 0;
    let denominator = 1; // Default denominator to 1 if no fraction

    // Check if quantity contains '/'
    // eslint-disable-next-line
    if (quantity?.includes('/')) {
      // eslint-disable-next-line
      [numerator, denominator] = quantity?.split('/').map(Number);
      integerPart = numerator; // Whole number part
      // eslint-disable-next-line
      denominator = denominator; // Remainder of the division
    } else {
      integerPart = +quantity;
    }

    // Package quantity
    const packageQuantity = +item?.package_quantity || 1;
    // Calculate the fractional proportion
    const fractionalProportion = (denominator * 100) / packageQuantity;
    // Calculate adjusted quantity
    const adjustedQuantity = quantity?.includes('/')
      ? `${integerPart}.${fractionalProportion}`
      : integerPart;
    return acc + adjustedQuantity;
  }, 0);

  const sumOfPriceWholsale = returnItemData.reduce((acc: any, item: any) => {
    const price = item?.price === undefined ? 0 : +item.price;
    return acc + price;
  }, 0);

  const sumOfOrderPrice = sumOfQuantity * sumOfPriceWholsale;

  const handleSave = () => {
    setSaveLoading(true);

    const invalidItems = returnItemData?.filter(
      (el: any) => !el?.return_reason_id
    );

    if (invalidItems.length > 0) {
      dispatch(
        showAlert({
          title: t('please_indicate_reason_returning')
        })
      );
      setSaveLoading(false);
      return;
    }

    const createData = returnItemData?.map((el: any) => {
      const quantity = el?.amount || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1;

      const quantityStr = String(quantity);

      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      const packageQuantity = +el?.package_quantity || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      return {
        barcode: el?.barcode,
        name: el?.product_name,
        currency_id: el?.currency_id,
        measure_unit_id: el?.measure_unit_id,
        nomenclature_id: el?.nomenclature_id,
        product_id: el?.product_id,
        parent_product_id: el?.parent_product_id,
        return_reason_id: el?.return_reason_id,
        price: el?.price ? +el?.price : 0,
        quantity:
          el?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        package_quantity: el?.package_quantity ? +el?.package_quantity : 0,
        status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557' // static new status id
      };
    });

    const updateData = {
      return_id: newReturningId,
      return_items: createData
    };

    if (newReturningId !== '') {
      returnItemService.createReturnItem(updateData).then((createres: any) => {
        if (createres) {
          returnService.getReturnById(newReturningId).then((res: any) => {
            if (res) {
              returnOutService
                .updateReturnOut(newReturningId, {
                  ...res,
                  status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557'
                })
                .then((outresponse: any) => {
                  if (outresponse) {
                    dispatch(
                      showAlert({
                        title: t('new_application_saved'),
                        type: 'success'
                      })
                    );
                    setSaveLoading(false);
                  }
                });
            }
          });
        }
      });
    }
  };

  const handleSend = () => {
    const invalidItems = returnItemData?.filter(
      (el: any) => !el?.return_reason_id
    );

    if (invalidItems.length > 0) {
      dispatch(
        showAlert({
          title: t('please_indicate_reason_returning')
        })
      );
      setSendLoading(false);
      return;
    }

    const createData = returnItemData?.map((el: any) => {
      const quantity = el?.amount || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1;

      const quantityStr = String(quantity);

      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      const packageQuantity = +el?.package_quantity || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      return {
        barcode: el?.barcode,
        name: el?.product_name,
        currency_id: el?.currency_id,
        measure_unit_id: el?.measure_unit_id,
        nomenclature_id: el?.nomenclature_id,
        product_id: el?.product_id,
        parent_product_id: el?.parent_product_id,
        price: el?.price ? +el?.price : 0,
        quantity:
          el?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        return_reason_id: el?.return_reason_id,
        package_quantity: el?.package_quantity ? +el?.package_quantity : 0,
        status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b' // static new status id
      };
    });

    const updateData = {
      return_id: newReturningId,
      return_items: createData
    };

    if (newReturningId !== '' && createData?.length > 0) {
      setSendLoading(true);

      returnItemService.createReturnItem(updateData).then((createres: any) => {
        if (createres) {
          returnService.getReturnById(newReturningId).then((res: any) => {
            if (res) {
              returnOutService
                .updateReturnOut(newReturningId, {
                  ...res,
                  status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b'
                })
                .then((outresponse: any) => {
                  if (outresponse) {
                    setSendLoading(false);
                    dispatch(
                      showAlert({
                        title: t('new_return_sent'),
                        type: 'success'
                      })
                    );
                    navigate('/returning/outgoing');
                  }
                });
            }
          });
        }
      });
    } else {
      dispatch(
        showAlert({
          title: t('unable_send_return')
        })
      );
    }
  };

  return (
    <div>
      <PageBreadcrumb items={newReturningBreadcrumbItems} />
      <h2>{t('new')}</h2>

      <NewReturningTableSearch
        returnItemData={returnItemData}
        setReturnItemData={setReturnItemData}
        senderId={senderId}
        focusRef={focusRef}
        isAutoTab={isAutoTab}
        setSavedBarcodes={setSavedBarcodes}
        savedBarcodes={savedBarcodes}
      />
      <NewReturningTable
        returnItemData={returnItemData}
        newReturningId={newReturningId}
        setReturnItemData={setReturnItemData}
        setRef={setRef}
        setSavedBarcodes={setSavedBarcodes}
      />

      <div className="footer-return-info">
        <div className="box">
          <p className="mb-0 text-bold">{t('total_amount')}: </p>
          <span>
            {sumOfOrderPrice
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(sumOfOrderPrice)
                  .replace(/,/g, ' ')
              : 0}
          </span>
        </div>

        <div className="main-btn-group">
          <Button
            type="button"
            variant="outline-primary"
            onClick={() => handleSave()}
            loading={saveLoading}
          >
            {t('save')}
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={() => handleSend()}
            loading={sendLoading}
          >
            {t('send')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewReturning;
