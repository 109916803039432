/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPlus, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import legalEntityService from 'service/legalEntity';
import { showAlert } from 'store/alert/alert.thunk';
import orderService from 'service/order';
import NewApplicationDataTable from './NewApplicationDataTable';
import customerEntityService from 'service/charterer';

const CreateNewApplication = () => {
  const { t } = useTranslation();
  const { control, getValues } = useForm();
  const dispatch: Dispatch<any> = useDispatch();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const navigate = useNavigate();

  const legalEntityId = useSelector(
    (state: any) => state?.auth?.legal_entity?.id
  );

  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [openFolder, setOpenFolder] = useState(false);
  const [selectedLegalEntityId, setSelectedLegalEntityId] =
    useState<string>('');

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));

        setLegalEntityOption(options);
      });
  });

  const { data: chartererData } = useQuery(
    ['GET_CHARTERER_LIMIT', selectedLegalEntityId, legalEntityId],
    () => {
      if (selectedLegalEntityId !== '' && legalEntityId !== '') {
        const resData = customerEntityService
          .getByIdCheckChartererLimit(selectedLegalEntityId, legalEntityId, {
            limit_type: 'TIME'
          })
          .then((res: any) => {
            return res;
          });

        return resData;
      }
    },
    { enabled: !!selectedLegalEntityId && !!legalEntityId }
  );

  const handleSave = () => {
    const data = getValues();

    if (!cashboxId) {
      dispatch(showAlert({ title: t('shift_not_open') }));
      return;
    }

    if (chartererData?.id) {
      dispatch(showAlert({ title: t('you_have_time_limit') }));
      return;
    }

    if (data?.sender !== '' && cashboxId) {
      orderService
        .createOrder({
          sender_id: data?.sender,
          cashbox_id: cashboxId
        })
        .then((res: any) => {
          if (res) {
            dispatch(
              showAlert({
                title: `${t('applications')} ${t('successfully_added')}`,
                type: 'success'
              })
            );
            navigate(
              `/applications/outgoing/filter/newapplications/${res?.id}?sender_id=${data?.sender}`
            );
          }
        });
    }
  };

  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Form.Group className="md-4">
        <div className="react-select-container">
          <Controller
            name="sender"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Form.Floating>
                <Form.Select
                  {...field}
                  onChange={(e: any) => {
                    const selectedOption = legalEntityOption.find(
                      (option: any) => option.value === e.target.value
                    );
                    if (selectedOption) {
                      setSelectedLegalEntityId(selectedOption?.value);
                    }
                    field.onChange(e);
                  }}
                >
                  <option className="d-none" value=""></option>
                  {legalEntityOption?.map((option: any) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className="option"
                    >
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">{t('sender')}</label>
              </Form.Floating>
            )}
          />
        </div>
      </Form.Group>
      <Form.Group style={{ display: 'flex', gap: '10px', marginTop: 'auto' }}>
        <Button
          variant="outline-primary"
          type="button"
          style={{ height: '48px', width: '100%' }}
          onClick={() => setOpenFolder(true)}
        >
          <FontAwesomeIcon icon={faFolderOpen} className="me-2" />
          {t('open')}
        </Button>
        <Button
          variant="primary"
          type="button"
          style={{ height: '48px', width: '100%' }}
          onClick={() => handleSave()}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {t('create')}
        </Button>
      </Form.Group>

      <Modal
        show={openFolder}
        onHide={() => setOpenFolder(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('select_application')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewApplicationDataTable setOpenFolder={setOpenFolder} />
        </Modal.Body>
      </Modal>
    </Form>
  );
};

export default CreateNewApplication;
