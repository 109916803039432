/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';
import {
  useForm,
  Controller,
  FieldValues,
  SubmitHandler
} from 'react-hook-form';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import analyticLostProfitService from 'service/analyticLostProfit';
import legalEntityService from 'service/legalEntity';
import LostProfitCategory from './LostProfitCategory';
import styles from './styles.module.scss';

interface LostProftFilterProps {
  setTableData?: any;
}

const LostProftFilter = ({ setTableData }: LostProftFilterProps) => {
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { t } = useTranslation();

  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState<any>([]);
  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [branchOption, setBranchOption] = useState<any>([]);
  const [branchId, setBranchId] = useState('');

  //GET LEgal Entity to options
  useQuery(['GET_BRANCH'], async () => {
    await legalEntityService
      .getListBranch({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setBranchOption(options);
      });
  });

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY', branchId], async () => {
    if (branchId) {
      await legalEntityService
        .getLegalEntityOption({ offset: 0, limit: 100 })
        .then((res: any) => {
          const options = res?.legal_entities?.map((option: any) => ({
            value: option.id,
            label: option.name,
            branch_id: branchId
          }));
          setLegalEntityOption(options);
        });
    }
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    analyticLostProfitService
      .getList({
        offset: 0,
        limit: 10,
        supplier_id: data?.provider,
        branch_id: data?.branch,
        barcode: data?.barcode,
        category_id: selectedCategoryData?.id,
        from_date_range: +data?.last_sale_period
      })
      .then((res: any) => {
        if (res) {
          setTableData(res);
        }
      });
  };
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles.profitRows}>
        <Form.Group className="md-4">
          <div className="react-select-container">
            <Controller
              name="branch"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = branchOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setBranchId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {branchOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('branch')}</label>
                  {watch('branch') !== '' && (
                    <Button
                      variant="link"
                      className="p-0"
                      style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        top: '32%',
                        right: '25%'
                      }}
                      onClick={() => {
                        setValue('branch', '');
                        setBranchId('');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        {branchId !== '' && (
          <Form.Group>
            <div className="position-relative">
              <Controller
                name="provider"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.provider}>
                      <option className="d-none" value=""></option>
                      {legalEntityOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('supplier')}</label>
                    {watch('provider') !== '' && (
                      <Button
                        variant="link"
                        className="p-0"
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          top: '32%',
                          right: '25%'
                        }}
                        onClick={() => {
                          setValue('provider', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                    {errors?.provider && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>
        )}

        <Controller
          name="last_sale_period"
          control={control}
          rules={{
            required: true
          }}
          defaultValue=""
          render={({ field }) => (
            <Form.Floating>
              <Form.Control
                {...field}
                type="text"
                placeholder={t('last_sales_period')}
                onFocus={e => e.target.select()}
                isInvalid={!!errors.last_sale_period}
              />
              <label htmlFor="floatingInputCustom">
                {t('last_sales_period')}
              </label>
              {errors?.last_sale_period && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          )}
        />

        <Form.Floating>
          <Form.Control
            {...register('barcode')}
            type="text"
            placeholder={t('scan_barcode')}
            onFocus={e => e.target.select()}
          />
          <label htmlFor="floatingInputCustom">{t('scan_barcode')}</label>
        </Form.Floating>

        <Form.Group
          className={styles.categoryBox}
          onClick={() => setOpenCategoryModal(true)}
        >
          <span className={styles.title}>
            {selectedCategoryData?.length !== 0
              ? `${t('category')} - ${selectedCategoryData?.label}`
              : t('category')}
          </span>
          <FeatherIcon icon="chevron-right" size={16} />
          {selectedCategoryData?.length !== 0 && (
            <Button
              variant="link"
              className="p-0"
              style={{
                position: 'absolute',
                cursor: 'pointer',
                top: '32%',
                right: '18%'
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                setSelectedCategoryData([]);
              }}
            >
              <FeatherIcon icon="delete" size={16} />
            </Button>
          )}
        </Form.Group>

        <Button style={{ height: '48px' }} variant="primary" type="submit">
          {t('apply')}
        </Button>

        <Offcanvas
          show={openCategoryModal}
          onHide={() => {
            setOpenCategoryModal(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('category')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <LostProfitCategory
              setOpenCategoryModal={setOpenCategoryModal}
              setSelectedCategoryData={setSelectedCategoryData}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </Form>
    </div>
  );
};

export default LostProftFilter;
