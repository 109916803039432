/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import usePageSearchParams from 'utils/usePageSearchParams';

import paymentMethodService from 'service/paymentMethod';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import customerEntityService from 'service/charterer';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import legalEntityCurrencyRateService from 'service/legalEntityCurrencyRate';

interface AddPurchaseToChildProps {
  setOpenChild?: any;
  refetch?: any;
  totalRemainder?: any;
  setTotalRemainder?: any;
}

const removeSpaces = (value: string) => {
  return value?.replace(/\s/g, '');
};

const formatNumber = (value: string) => {
  return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const AddPurchaseToChild = ({
  setOpenChild,
  refetch,
  totalRemainder,
  setTotalRemainder
}: AddPurchaseToChildProps) => {
  const { t, i18n } = useTranslation();

  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);

  const schema = yup.object().shape({
    remainder: yup.string(),
    payment_type: yup.string(),
    debit: yup.string().required(t('required_field')),
    note: yup.string(),
    legal_entity_currency_id: yup.string().required(t('required_field')),
    rate: yup?.string()
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();

  const debitor_id = pathname.split('/').pop();
  const currency_id = searchParams.get('currency_id');
  const creditor_id = searchParams.get('creditor_id');

  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [selctedPaymentsId, setSelectedPaymentsId] = useState<any>();
  const [legalEntityCurrencyOption, setLegalEntityCrrencyOption] =
    useState<any>([]);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<string>('');
  const [lastRateValue, setLastRateValue] = useState<number>(1);

  const debitValue = removeSpaces(watch('debit')) ?? 1;

  //GET Wallet to options
  useQuery(['GET_METHOD'], async () => {
    await paymentMethodService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_payment_methods?.map(
          (option: any) => ({
            value: option?.paymentMethod?.id,
            label: option?.paymentMethod?.name?.[i18n?.language],
            payment_method_id: option?.payment_method_id,
            payment_type_id: option?.paymentMethod?.payment_type_id
          })
        );
        setPaymentMethod(options);
      });
  });

  //GET currency to options
  useQuery(['GET_LEGAL_ENTITY_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));

        setLegalEntityCrrencyOption(options);
      });
  });

  useQuery(['GET_LEGAL_ENTITY_CURRENCY_RATE', selectedCurrencyId], async () => {
    if (selectedCurrencyId !== '') {
      await legalEntityCurrencyRateService
        .getListConversion({
          offset: 0,
          limit: 100,
          income_currency_id: selectedCurrencyId,
          outcome_currency_id: currency_id
        })
        .then((res: any) => {
          if (res) {
            setLastRateValue(
              res?.legal_entity_currency_conversion_rates?.[0]?.outcome_amount
            );
          }
        });
    }
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    const debit = +removeSpaces(data?.debit);

    if (debit <= 0) {
      setLoading(false);
      dispatch(
        showAlert({
          title: t('not_enough_product')
        })
      );
    }

    if (+removeSpaces(totalRemainder) < debit) {
      setLoading(false);
      dispatch(
        showAlert({
          title: t('amount_exceed')
        })
      );
      return;
    }

    const createdData = {
      cashbox_id: cashboxId,
      amount:
        selectedCurrencyId !== currency_id ? lastRateValue * debit : debit,
      currency_id: currency_id,
      payment_method_id: selctedPaymentsId?.payment_method_id,
      payment_type_id: selctedPaymentsId?.payment_type_id,
      debitor_id: debitor_id,
      creditor_id: creditor_id,
      note: data?.note ?? '',
      currency_rate: lastRateValue ?? 1,
      original_amount: debit,
      original_currency_id:
        selectedCurrencyId !== '' ? selectedCurrencyId : currency_id,
      is_auto: false
    };

    customerEntityService
      .createCustomerSettelment(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: t('successfully_added'),
              type: 'success'
            })
          );
          const updatedRemainder = +removeSpaces(totalRemainder) - debit;
          setTotalRemainder(
            new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 4,
              useGrouping: true
            })
              .format(updatedRemainder)
              .replace(/,/g, ' ')
          );
          refetch();
          setLoading(false);
          setOpenChild(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (currency_id) {
      setValue('legal_entity_currency_id', currency_id);
      setSelectedCurrencyId(currency_id);
    }
  }, [currency_id]);

  useEffect(() => {
    if (debitValue) {
      const updateRate = +debitValue * lastRateValue;
      setValue(
        'rate',
        new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 4,
          useGrouping: true
        })
          .format(updateRate)
          .replace(/,/g, ' ')
      );
    } else {
      setValue('rate', '1');
    }
  }, [debitValue]);

  const inputSelectedLabel = legalEntityCurrencyOption?.find(
    (option: any) => option?.value !== selectedCurrencyId
  );

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                defaultValue={totalRemainder}
                disabled
                type="text"
                placeholder={t('remainder')}
              />
              <label htmlFor="floatingInputCustom">{t('remainder')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Form.Group className="md-4 mb-2">
          <div className="react-select-container">
            <Controller
              name="legal_entity_currency_id"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = legalEntityCurrencyOption?.find(
                        (option: any) => option?.value === e?.target?.value
                      );

                      if (selectedOption) {
                        setSelectedCurrencyId(selectedOption?.value);
                        setValue('rate', '1');
                        setValue('payment_type', '');
                        setValue('debit', '');
                        setValue('note', '');
                      }

                      field.onChange(e);
                    }}
                    isInvalid={!!errors.legal_entity_currency_id}
                  >
                    <option className="d-none" value=""></option>
                    {legalEntityCurrencyOption?.map((option: any) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="option"
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <label htmlFor="floatingInputCustom">{t('currency')}</label>
                  {errors?.legal_entity_currency_id && (
                    <span
                      style={{
                        marginTop: '2px',
                        color: 'red',
                        fontSize: '12px'
                      }}
                    >
                      {t('required_field')}
                    </span>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Row className="mb-2">
          <Col>
            <Controller
              name="debit"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('enter_amount')}
                    value={field.value || ''}
                    onFocus={e => {
                      if (field.value && field.value.startsWith('-')) {
                        // Remove the minus sign if present
                        const sanitizedValue = field.value.replace('-', '');
                        field.onChange(sanitizedValue);
                      }
                      e.target.select();
                    }}
                    onChange={e => {
                      const rawValue = removeSpaces(e.target.value) || '';

                      const remainderPattern = /^\d*\.?\d*$/;

                      if (remainderPattern.test(rawValue)) {
                        const formattedValue = formatNumber(rawValue);
                        field.onChange(formattedValue);
                      }
                    }}
                    isInvalid={!!errors.debit}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('enter_amount')}
                  </label>
                  {errors.debit && (
                    <Form.Control.Feedback type="invalid">
                      {errors.debit.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="payment_type"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = paymentMethod.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setSelectedPaymentsId({
                            payment_method_id:
                              selectedOption?.payment_method_id,
                            payment_type_id: selectedOption?.payment_type_id
                          });
                        }
                        field.onChange(e);
                      }}
                      isInvalid={!!errors.payment_type}
                    >
                      <option className="d-none" value=""></option>
                      {paymentMethod?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('payment_type')}
                    </label>
                    {errors?.payment_type?.message && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {errors?.payment_type?.message}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('note')}
                type="text"
                placeholder={t('note')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('note')}</label>
            </Form.Floating>
          </Col>
        </Row>

        {selectedCurrencyId !== currency_id && (
          <InputGroup className="mb-3">
            <FormControl
              {...register('rate')}
              disabled
              defaultValue={parseFloat(debitValue) * lastRateValue}
            />
            <InputGroup.Text>
              {new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(lastRateValue)
                .replace(/,/g, ' ')}
            </InputGroup.Text>
            <InputGroup.Text>
              {inputSelectedLabel?.label ?? "so'm"}
            </InputGroup.Text>
          </InputGroup>
        )}

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default AddPurchaseToChild;
