/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';

const userService = {
  getList: (params: any) => requestAuth.get('/user', { params }),
  getById: (id: string) => requestAuth.get(`/user/${id}`),
  getUserPermisionById: (id: string, data: any) =>
    requestAuth.get(`/user-permission/menu/web/${id}`, data),
  getPosUserPermisionById: (id: string, data: any) =>
    requestAuth.get(`/user-permission/menu/pos/${id}`, data),
  create: (data: any) => requestAuth.post('/user', data),
  update: (id: any, data: any) =>
    requestAuth.put(`/user-permission/menu/web/${id}`, data),
  updatePos: (id: any, data: any) =>
    requestAuth.put(`/user-permission/menu/pos/${id}`, data)
};

export default userService;
