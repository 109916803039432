/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import returnItemService from 'service/returnItemService';
import returnReasonService from 'service/returnReason';

export type NewReturningInfo = {
  product_name: string;
  barcode: string;
  unit: string;
  currency: string;
  amount: string;
  return_reason_id: string;
  current_balance: string;
  measure_unit_id: string;
  package_quantity: any;
};

interface NewReturningTableProps {
  returnItemData?: any;
  newReturningId?: any;
  setReturnItemData?: any;
  setRef?: any;
  setSavedBarcodes?: any;
}

const NewReturningTable = ({
  returnItemData,
  newReturningId,
  setReturnItemData,
  setRef,
  setSavedBarcodes
}: NewReturningTableProps) => {
  const { t, i18n } = useTranslation();

  const [returnReasonOption, setReturnReasonOption] = useState<any>([]);
  //GET return Reason  to options
  useQuery(['GET_RETURN_REASON'], async () => {
    await returnReasonService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.product_return_reasons?.map((option: any) => ({
          value: option.id,
          label: option.name?.[i18n?.language]
        }));

        setReturnReasonOption(options);
      });
  });

  const { data } = useQuery(
    ['GET_RETURN_ITEMS', newReturningId],
    async () => {
      if (newReturningId) {
        const res = await returnItemService
          .getList({
            offset: 0,
            limit: 100,
            return_id: newReturningId
          })
          .then((res: any) => {
            return res?.return_items;
          });
        return res;
      }
    },
    { enabled: !!newReturningId }
  );

  const generateData: NewReturningInfo[] = useMemo(() => {
    const newData =
      data?.map((items: any) => {
        const packageQuantity =
          items?.package_quantity !== undefined ? items.package_quantity : 1;
        const quantity =
          items?.quantity !== undefined ? String(items.quantity) : '0';
        const totalQuantity =
          items?.total_quantity !== undefined
            ? String(items.total_quantity)
            : '0';
        let integerPart = 0;
        let numerator = 0;
        let denominator = 1;

        let integerPartTotal = 0;
        let numeratorTotal = 0;
        let denominatorTotal = 1;

        if (quantity?.includes('.')) {
          [numerator, denominator] = quantity.split('.').map(Number);
          integerPart = numerator; // Whole number part
          denominator = +`0.${denominator}`; // Remainder of the division
        } else {
          integerPart = +quantity;
        }

        if (totalQuantity?.includes('.')) {
          [numeratorTotal, denominatorTotal] = totalQuantity
            .split('.')
            .map(Number);
          integerPartTotal = numeratorTotal; // Whole number part
          denominatorTotal = +`0.${denominatorTotal}`; // Remainder of the division
        } else {
          integerPartTotal = +totalQuantity;
        }

        const multipleFraction = Math?.round(
          +denominatorTotal * packageQuantity
        );

        const quantityDisplay = quantity?.includes('.')
          ? `${integerPart}/${Math.round(+denominator * packageQuantity)}`
          : integerPart;

        const totalQuantityDisplay = totalQuantity?.includes('.')
          ? `${integerPartTotal}/${multipleFraction}`
          : integerPartTotal;

        return {
          product_name: items?.product_short_name,
          barcode: items?.barcode,
          unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit.id,
          amount:
            items?.nomenclature?.measure_unit_id ===
            '443bfff1-61e0-4057-8583-d040dc5a0454'
              ? quantityDisplay
              : items?.quantity ?? 0,
          full_cost: items?.price_wholesale,
          currency: items?.currency?.name?.[i18n?.language],
          return_reason_id: items?.return_reason_id,
          nomenclature_id: items?.nomenclature_id,
          product_id: items?.product_id,
          parent_product_id: items?.parent_product_id,
          price: items?.price,
          is_vat: items?.is_vat ? items?.is_vat : false,
          currency_id: items?.currency_id,
          package_quantity:
            items?.package_quantity !== undefined ? items.package_quantity : 1,
          measure_unit_kind_id:
            items?.nomenclature?.measure_unit?.measure_unit_kind_id,
          /* prettier-ignore */
          current_balance:
            items?.nomenclature?.measure_unit_id ===
                  '443bfff1-61e0-4057-8583-d040dc5a0454'
                    ? totalQuantityDisplay
                    : items?.total_quantity
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 4,
                          useGrouping: true
                        })
                          .format(items?.total_quantity)
                          .replace(/,/g, ' ')
                      : 0
        };
      }) ?? [];

    setReturnItemData([...returnItemData, ...newData]);

    return newData;
  }, [data, t, i18n?.language]);

  console.log(generateData);

  const handleRemoveProductItem = (indexToRemove: number, barcode: string) => {
    setReturnItemData(
      returnItemData.filter((_: any, index: any) => index !== indexToRemove)
    );

    setSavedBarcodes((prevBarcodes: any) => {
      const updatedBarcodes = { ...prevBarcodes }; // Make a copy of the previous barcodes
      delete updatedBarcodes[barcode]; // Delete the barcode from the copied object
      return updatedBarcodes; // Return the updated object
    });
  };

  const newReturningDataTableColumns: ColumnDef<NewReturningInfo>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() =>
              handleRemoveProductItem(
                +rowData?.row?.id,
                rowData?.row?.original.barcode
              )
            }
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } }
      }
    },

    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'current_balance',
      header: t('current_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: t('quantity'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';
        const measure_unit_id: string = rowData.row.original.measure_unit_id;

        const currentBalance = rowData?.row?.original?.current_balance;
        const packageQuantity = rowData?.row?.original?.package_quantity || 1;

        const [value, setValue] = useState(amount);

        const calculateAdjustedQuantity = (quantity: string) => {
          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          const quantityStr = String(quantity);

          if (quantityStr?.includes('/')) {
            [numerator, denominator] = quantityStr.split('/').map(Number);
            integerPart = numerator;
          } else {
            integerPart = +quantityStr;
          }

          const fractionalProportion = (denominator * 100) / packageQuantity;
          return quantityStr.includes('/')
            ? `${integerPart}.${Math.round(fractionalProportion)}`
            : integerPart;
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          // let newValue = +value;

          let formattedValue: any = value.replace(/[^0-9,./]/g, ''); // Strip invalid characters

          const numericValue = parseFloat(formattedValue.replace(/[,/]/g, ''));
          const currentBalanceNumber = +currentBalance; // Convert currentBalance to a number

          if (numericValue > currentBalanceNumber) {
            formattedValue = currentBalanceNumber;
          }

          const adjustedQuantity = calculateAdjustedQuantity(formattedValue);

          const updatedItems = returnItemData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: formattedValue, adjustedQuantity };
            }
            return item;
          });

          setReturnItemData(updatedItems);
          setValue(formattedValue); // Ensure the displayed value is updated correctly

          const savedBarcodes = updatedItems.reduce((acc: any, item: any) => {
            acc[item.barcode] = { quantity: +item.adjustedQuantity || 0 };
            return acc;
          }, {});
          setSavedBarcodes(savedBarcodes);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;

          const numberPattern = /^-?\d*\.?\d*$/;
          const remainderPattern = /^-?\d*\/?\d*$/;
          if (
            measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454' &&
            remainderPattern.test(newValue)
          ) {
            setValue(newValue);
          } else {
            if (numberPattern.test(newValue)) {
              setValue(newValue);
            }
          }
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="amount"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                ref={setRef(`amount_${rowData.row.id}`)}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'return_reason_id',
      header: t('reason_return'),
      cell: rowData => {
        return (
          <div>
            <Form.Group className="md-4">
              <div className="react-select-container">
                <Form.Group>
                  <Form.Select
                    value={rowData?.row?.original?.return_reason_id}
                    onChange={(e: any) => {
                      const selectedOption = returnReasonOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        const updatedItems = returnItemData.map(
                          (item: any, i: any) => {
                            if (i === +rowData?.row?.id) {
                              return {
                                ...item,
                                return_reason_id: selectedOption.value
                              };
                            }
                            return item;
                          }
                        );
                        setReturnItemData(updatedItems);
                      }
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {returnReasonOption?.map((option: any) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="option"
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: returnItemData,
    columns: newReturningDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default NewReturningTable;
