import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const comingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'applications',
    url: '/applications/outgoing'
  },
  {
    label: 'incoming ',
    active: true
  }
];

export type Coming = {
  orders: string;
  recipient: string;
  currency: string;
  status: string;
  create_date: string;
  guid: string;
  can_update: boolean;
  maturity_date: string;
  date_auto_cancel: string;
  recipient_id: string;
  sender_id: string;
};

export type ComingResult = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  cost: string;
  remainder: string;
  quantity_asked: string;
  quantity_fact: string;
  quantity_accept: string;
  discrepancies: string;
  amount_discrepancies: string;
  final_price: string;
  total_price: string;
  status: string;
  nomenclature_id: string;
  recipient_id: string;
  guid: string;
  discount_percentage: string;
  prevCost: string;
  update_price: boolean;
  discount_id: string;
};
