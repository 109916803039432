/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { Coming } from 'data/movement';
import movingInService from 'service/movingIn';
import legalEntitySettingsService from 'service/legalEntitySettings';

interface ComingFilterMovementsTableProps {
  dateFrom?: any;
  dateTo?: any;
  optionSenderId?: any;
  optionStatusId?: any;
}

const ComingFilterMovementsTable = ({
  dateFrom,
  dateTo,
  optionSenderId,
  optionStatusId
}: ComingFilterMovementsTableProps) => {
  const { t, i18n } = useTranslation();
  const legalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );
  const navigate = useNavigate();

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const navigateTo = (row: any) => {
    navigate(
      `/movements/coming/movements-coming-result/${row?.original?.guid}`
    );
  };
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_MOVING_IN_LIST'],
    async () => {
      const res = await movingInService
        .getList({
          offset: 0,
          limit: 100,
          status_id: optionStatusId ? optionStatusId : undefined,
          sender_id: optionSenderId ? optionSenderId : undefined,
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.movings);
      return res;
    },
    {
      enabled:
        !!dateFrom || !!adjustedDateTo || !!optionSenderId || !!optionStatusId
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const comingData: Coming[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resData =
      data?.map((items: any) => {
        return {
          name: items?.name,
          orders: items?.moving_number,
          status: items?.status?.name?.[i18n?.language],
          sender: items?.legal_entity?.name,
          currency: items?.currency?.name?.[i18n?.language],
          recipient: legalEntityName,
          /* prettier-ignore */
          create_date: formatDateToTimeZone(items?.date_create, timeZone),
          can_update: items?.can_update,
          maturity_date: items?.maturity_date ?? null,
          guid: items?.id
        };
      }) ?? [];

    return resData;
  }, [data, t, i18n?.language]);

  const comingTableColumns: ColumnDef<Coming>[] = [
    {
      accessorKey: 'orders',
      header: t('application_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'recipient',
      header: t('recipient'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sender',
      header: t('sender'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          Завершен: { bg: '#BBFBD0', text: '#119C2B' },
          Отправлен: { bg: '#fef3c7', text: '#92400e' },
          Bajarildi: { bg: '#BBFBD0', text: '#119C2B' },
          "Jo'natilgan": { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div className="d-flex">
            <p
              style={{
                background: backgroundColor,
                padding: '8px 10px',
                textAlign: 'center',
                borderRadius: '8px',
                color: textColor,
                fontWeight: '600',
                margin: '0'
              }}
            >
              {status}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'create_date',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: comingData,
    columns: comingTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionSenderId, optionStatusId]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default ComingFilterMovementsTable;
