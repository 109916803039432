/* eslint-disable @typescript-eslint/no-explicit-any */
import Avatar from 'components/base/Avatar';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import avatar from 'assets/img/icons/avatarImg.png';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authActions } from 'store/auth/auth.slice';
import Button from 'components/base/Button';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [openSupperModal, setOpenSupperModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userName = useSelector(
    (state: { auth: { user: { first_name: string } } }) =>
      state?.auth?.user?.first_name
  );

  const userIdNumber = useSelector(
    (state: { auth: { user: { id_number: string } } }) =>
      state?.auth?.user?.id_number
  );

  const phone = useSelector(
    (state: { auth: { user: { phone_number: string } } }) =>
      state?.auth?.user?.phone_number
  );

  const legalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );

  // const [navItems] = useState([
  //   // {
  //   //   label: 'Profile',
  //   //   icon: 'user'
  //   // },
  //   // {
  //   //   label: 'Dashboard',
  //   //   icon: 'pie-chart'
  //   // },
  //   // {
  //   //   label: 'Posts & Activity',
  //   //   icon: 'lock'
  //   // },
  //   {
  //     label: 'Settings',
  //     icon: 'settings'
  //   }
  //   // {
  //   //   label: 'Help Center',
  //   //   icon: 'help-circle'
  //   // },
  //   // {
  //   //   label: 'Language',
  //   //   icon: 'globe'
  //   // }
  // ]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={avatar} size="xl" />
            <h6 className="text-black">{userName}</h6>
            <span className="text-1000">{phone}</span>
            <span>
              {t('short_name_legal_entity')}:{' '}
              <span style={{ fontWeight: 800 }}>{legalEntityName}</span>
            </span>
            <span>
              {t('short_id_legal_entity')}:{' '}
              <span style={{ fontWeight: 800 }}>{userIdNumber}</span>
            </span>
          </div>
          {/* <div className="mb-3 mx-3">
            <Form.Control
              type="text"
              placeholder="Update your status"
              size="sm"
            />
          </div> */}
          {/* <div style={{ height: '60px', overflow: 'hidden' }}>
            <Scrollbar scrolling="none">
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href="#!" className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div> */}
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          {/* <Nav className="nav flex-column my-3">
            <Nav.Item>
              <Nav.Link href="#!" className="px-3">
                <FeatherIcon
                  icon="user-plus"
                  size={16}
                  className="me-2 text-900"
                />
                <span>Add another account</span>
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
          <div className="px-3 mt-3">
            <Button
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
              onClick={() => setOpenSupperModal(true)}
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              {t('exit')}
            </Button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-600">
            <Link className="text-600 me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-600 mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-600 ms-1" to="#!">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>

      <Modal
        show={openSupperModal}
        onHide={() => setOpenSupperModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{t('exit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('sure_want_log_out')}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenSupperModal(false)}>
            {t('no')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(authActions.logout());
              navigate('/sign-in');
            }}
          >
            {t('yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
