/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { newMovementsBreadcrumbItems } from 'data/newmovements';
import Button from 'components/base/Button';
import NewMovementsTableSearch from './NewMovementsTableSearch';
import NewMovementsTable from './NewMovementsTable';
import { showAlert } from 'store/alert/alert.thunk';
import movingOutService from 'service/movingOut';
import movingService from 'service/movement';
import movingItemService from 'service/movingItem';
import useFocusRefs from 'utils/useFocusRefs';
import legalEntitySettingsService from 'service/legalEntitySettings';

import '../../styles.scss';

const NewMovements = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const newMovingId = pathname.split('/').pop();
  const dispatch: Dispatch<any> = useDispatch();
  const { setRef, focusRef } = useFocusRefs();

  const [movingItemData, setMovingItemData] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [isAutoTab, setIsAutoTab] = useState(false);
  const [savedBarcodes, setSavedBarcodes] = useState<any>([]);

  useQuery(['GET_SETTINGS'], async () => {
    await legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 20
      })
      .then((res: any) => {
        setIsAutoTab(res?.legal_entity_settings[0].is_auto_tab ?? 0);
      });
  });

  const sumOfQuantity = movingItemData.reduce((acc: any, item: any) => {
    const quantity = String(item?.amount) || '0';

    let integerPart = 0;
    let numerator = 0;
    let denominator = 1; // Default denominator to 1 if no fraction

    // Check if quantity contains '/'
    // eslint-disable-next-line
    if (quantity?.includes('/')) {
      // eslint-disable-next-line
      [numerator, denominator] = quantity?.split('/').map(Number);
      integerPart = numerator; // Whole number part
      // eslint-disable-next-line
      denominator = denominator; // Remainder of the division
    } else {
      integerPart = +quantity;
    }

    // Package quantity
    const packageQuantity = +item?.package_quantity || 1;
    // Calculate the fractional proportion
    const fractionalProportion = (denominator * 100) / packageQuantity;
    // Calculate adjusted quantity
    const adjustedQuantity = quantity?.includes('/')
      ? `${integerPart}.${fractionalProportion}`
      : integerPart;
    return acc + adjustedQuantity;
  }, 0);

  const sumOfPriceWholsale = movingItemData.reduce((acc: any, item: any) => {
    const price = item?.total_price === undefined ? 0 : +item.total_price;
    return acc + price;
  }, 0);

  const sumOfOrderPrice = sumOfQuantity * sumOfPriceWholsale;

  const handleSave = () => {
    console.log('movingItemData', movingItemData);
    setSaveLoading(true);
    const createData = movingItemData?.map((el: any) => {
      const quantity = el?.amount || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1;

      const quantityStr = String(quantity);

      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      const packageQuantity = +el?.package_quantity || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      return {
        barcode: el?.barcode,
        name: el?.product_name,
        currency_id: el?.currency_id,
        measure_unit_id: el?.measure_unit_id,
        nomenclature_id: el?.nomenclature_id,
        product_id: el?.product_id,
        product_parent_id: el?.product_parent_id,
        price: el?.price ? +el?.price : 0,
        price_in: el?.price_in ? +el?.price_in : 0,
        price_wholesale: el?.price_wholesale ? +el?.price_wholesale : 0,
        price_difference: 0,
        quantity_send:
          el?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        quantity_fact: el?.quantity_fact ? +el?.quantity_fact : 0,
        status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557' // static new status id
      };
    });

    const updateData = {
      moving_id: newMovingId,
      moving_items: createData
    };

    if (newMovingId !== '') {
      movingItemService.createMovingItem(updateData).then((createres: any) => {
        if (createres) {
          movingService.getMovingById(newMovingId).then((res: any) => {
            if (res) {
              movingOutService
                .updateMovingOut(newMovingId, {
                  ...res,
                  status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557'
                })
                .then((outresponse: any) => {
                  if (outresponse) {
                    dispatch(
                      showAlert({
                        title: t('new_move_saved'),
                        type: 'success'
                      })
                    );
                    setSaveLoading(false);
                  }
                });
            }
          });
        }
      });
    }
  };

  const handleSend = () => {
    const createData = movingItemData?.map((el: any) => {
      const quantity = el?.amount || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1;

      const quantityStr = String(quantity);

      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      const packageQuantity = +el?.package_quantity || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      return {
        barcode: el?.barcode,
        name: el?.product_name,
        currency_id: el?.currency_id,
        measure_unit_id: el?.measure_unit_id,
        nomenclature_id: el?.nomenclature_id,
        product_id: el?.product_id,
        product_parent_id: el?.product_parent_id,
        price: el?.price ? +el?.price : 0,
        price_in: el?.price_in ? +el?.price_in : 0,
        price_wholesale: el?.price_wholesale ? +el?.price_wholesale : 0,
        price_difference: 0,
        quantity_send:
          el?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        quantity_fact: el?.quantity_fact ? +el?.quantity_fact : 0,
        status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b' // static new status id
      };
    });

    const updateData = {
      moving_id: newMovingId,
      moving_items: createData
    };

    if (newMovingId !== '' && createData?.length > 0) {
      setSendLoading(true);

      movingService.getMovingById(newMovingId).then((res: any) => {
        if (res) {
          movingItemService
            .createMovingItem(updateData)
            .then((createres: any) => {
              if (createres) {
                movingOutService
                  .updateMovingOut(newMovingId, {
                    ...res,
                    status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b'
                  })
                  .then((outresponse: any) => {
                    if (outresponse) {
                      setSendLoading(false);
                      dispatch(
                        showAlert({
                          title: t('new_move_sent'),
                          type: 'success'
                        })
                      );
                      navigate('/movements/outgoing');
                    }
                  });
              }
            });
        }
      });
    } else {
      dispatch(
        showAlert({
          title: t('unable_send_moves')
        })
      );
    }
  };

  return (
    <div>
      <PageBreadcrumb items={newMovementsBreadcrumbItems} />

      <h2 className="mb-5">{t('new')}</h2>

      <NewMovementsTableSearch
        movingItemData={movingItemData}
        setMovingItemData={setMovingItemData}
        focusRef={focusRef}
        isAutoTab={isAutoTab}
        setSavedBarcodes={setSavedBarcodes}
        savedBarcodes={savedBarcodes}
      />
      <NewMovementsTable
        movingItemData={movingItemData}
        newMovingId={newMovingId}
        setMovingItemData={setMovingItemData}
        setRef={setRef}
        setSavedBarcodes={setSavedBarcodes}
      />

      <div className="footer-mov-out">
        <div className="box">
          <p className="mb-0 text-bold">{t('total_amount')}: </p>
          <span>
            {sumOfOrderPrice
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(sumOfOrderPrice)
                  .replace(/,/g, ' ')
              : 0}
          </span>
        </div>

        <div className="main-btn-group">
          <Button
            type="button"
            variant="outline-primary"
            onClick={() => handleSave()}
            loading={saveLoading}
          >
            {t('save')}
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={() => handleSend()}
            loading={sendLoading}
          >
            {t('send')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewMovements;
