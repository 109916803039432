/* eslint-disable @typescript-eslint/no-explicit-any */
import Barcode from 'react-barcode';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import logo from 'assets/img/icons/rizomarketplace-logo.png';
import './reportComponent.scss';

interface ComponentToPrintProps {
  activeCard?: string;
  key?: string;
  name?: string;
  price?: number;
  barcode?: any;
  hidePrice?: boolean;
  hideBarcode?: boolean;
  scale_code?: string;
}

const ComponentToPrint = forwardRef(
  ({
    activeCard,
    key,
    name,
    price,
    barcode,
    hidePrice,
    hideBarcode,
    scale_code
  }: ComponentToPrintProps) => {
    const legalEntityName = useSelector(
      (state: any) => state?.auth?.legal_entity?.name
    );

    const logoUrl = useSelector(
      (state: any) => state?.auth?.legal_entity?.logo_url
    );

    const logoType = `${process.env.REACT_APP_CDN_URL}${logoUrl}`;

    const formatPrice = (price: any) => {
      const priceNumber = Number(price);
      if (isNaN(priceNumber)) {
        return { main: '0', decimals: '00' };
      }
      const priceParts = Number.isInteger(priceNumber)
        ? [priceNumber?.toString(), '00']
        : priceNumber?.toFixed(2).split('.');
      return {
        main: priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
        decimals: priceParts[1] || '00'
      };
    };

    const { main, decimals } = formatPrice(price || 0);

    return (
      <div
        className="report-print-container"
        style={{ height: '100vh', margin: '0', padding: '0' }}
      >
        {activeCard === 'card-60-40' && (
          <div key={key} className="card-60-40">
            <div className="card-body">
              <div className="logo">
                <img src={logoType ? logoType : logo} alt="logo" />
              </div>

              <div className="body-items">
                <p className="filial">
                  {legalEntityName ? legalEntityName : 'FILIAL STANDART'}
                </p>
                <p className={hideBarcode ? 'changed-title' : 'title'}>
                  {name ?? ''}
                </p>

                <div className="cost-box">
                  <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                    {hidePrice ? <div style={{ height: '18px' }}></div> : main}
                  </span>

                  <div
                    className={
                      hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                    }
                  >
                    {hidePrice ? (
                      ''
                    ) : (
                      <>
                        <span className="top">{decimals ?? 0}</span>
                        <span className="bottom">UZS</span>
                      </>
                    )}
                  </div>
                </div>

                {hideBarcode ? (
                  <div style={{ height: '40px' }}></div>
                ) : (
                  <Barcode
                    value={barcode}
                    width={1}
                    height={hidePrice ? 40 : 20}
                    format="CODE128"
                    displayValue={true}
                    font="monospace"
                    fontSize={10}
                    background="transparent"
                  />
                )}
              </div>
              <span className="scale-code">
                {scale_code !== '' ? `(${scale_code})` : ''}
              </span>
            </div>
          </div>
        )}

        {activeCard === 'card-58-30' && (
          <div key={key} className="card-58-30">
            <div className="card-body">
              <div className="body-items">
                <div className="filial">
                  {legalEntityName ? legalEntityName : 'FILIAL STANDART'}
                </div>
                <p className={hideBarcode ? 'changed-title' : 'title'}>
                  {name ?? ''}
                </p>

                <div className="cost-box">
                  <div className={hideBarcode ? 'cost-changed' : 'cost'}>
                    {hidePrice ? <div style={{ height: '12px' }}></div> : main}
                  </div>

                  <div
                    className={
                      hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                    }
                  >
                    {hidePrice ? (
                      ''
                    ) : (
                      <>
                        <div className="top">{decimals ?? 0}</div>
                        <div className="bottom">UZS</div>
                      </>
                    )}
                  </div>
                </div>

                {hideBarcode ? (
                  <div style={{ height: '24px' }}></div>
                ) : (
                  <Barcode
                    value={barcode}
                    width={1.3}
                    height={hidePrice ? 40 : 18}
                    format="CODE128"
                    displayValue={true}
                    font="monospace"
                    fontSize={10}
                    background="transparent"
                  />
                )}
              </div>
              <span className="scale-code">
                {scale_code !== '' ? `(${scale_code})` : ''}
              </span>
            </div>
          </div>
        )}

        {activeCard === 'card-40-30' && (
          <div key={key} className="card-40-30">
            <div className="card-body">
              <div className="body-items">
                <p className="filial">
                  {legalEntityName ? legalEntityName : 'FILIAL STANDART'}
                </p>
                <p className={hideBarcode ? 'changed-title' : 'title'}>
                  {name ?? ''}
                </p>

                <div className="cost-box">
                  <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                    {hidePrice ? <div style={{ height: '12px' }}></div> : main}
                  </span>

                  <div
                    className={
                      hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                    }
                  >
                    {hidePrice ? (
                      ''
                    ) : (
                      <>
                        <span className="top">{decimals ?? 0}</span>
                        <span className="bottom">UZS</span>
                      </>
                    )}
                  </div>
                </div>

                {hideBarcode ? (
                  <div style={{ height: '26px' }}></div>
                ) : (
                  <Barcode
                    value={barcode}
                    width={1}
                    height={hidePrice ? 30 : 18}
                    format="CODE128"
                    displayValue={true}
                    font="monospace"
                    fontSize={10}
                    background="transparent"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {activeCard === 'card-30-20' && (
          <div key={key} className="card-30-20">
            <div className="card-body">
              <div className="body-items">
                <p className={hideBarcode ? 'changed-title' : 'title'}>
                  {name ?? ''}
                </p>

                <div className="cost-box">
                  <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                    {hidePrice ? <div style={{ height: '12px' }}></div> : main}
                  </span>

                  <div
                    className={
                      hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                    }
                  >
                    {hidePrice ? (
                      ''
                    ) : (
                      <>
                        <span className="top">{decimals ?? 0}</span>
                        <span className="bottom">UZS</span>
                      </>
                    )}
                  </div>
                </div>

                {hideBarcode ? (
                  <div style={{ height: '10px' }}></div>
                ) : (
                  <Barcode
                    value={barcode}
                    width={0.8}
                    height={hidePrice ? 20 : 10}
                    format="CODE128"
                    displayValue={true}
                    font="monospace"
                    fontSize={8}
                    background="transparent"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {activeCard === 'card-line-58-30' && (
          <div key={key} className="card-line-58-30">
            <div className="card-body">
              <div className="body-items">
                <p className={hideBarcode ? 'changed-title' : 'title'}>
                  {name ?? ''}
                </p>

                <div className="cost-box">
                  <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                    {hidePrice ? (
                      <div style={{ height: '12px' }}></div>
                    ) : (
                      `${main}.${decimals}`
                    )}
                  </span>
                  <span className={hideBarcode ? 'bottom-changed' : 'bottom'}>
                    {hidePrice ? '' : 'UZS'}
                  </span>
                </div>

                {hideBarcode ? (
                  <div style={{ height: '24px' }}></div>
                ) : (
                  <Barcode
                    value={barcode}
                    width={1.3}
                    height={hidePrice ? 35 : 18}
                    format="CODE128"
                    displayValue={true}
                    font="monospace"
                    fontSize={10}
                    background="transparent"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {activeCard === 'card-line-40-30' && (
          <div key={key} className="card-line-40-30">
            <div className="card-body">
              <div className="body-items">
                <p className={hideBarcode ? 'changed-title' : 'title'}>
                  {name ?? ''}
                </p>

                <div className="cost-box">
                  <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                    {hidePrice ? (
                      <div style={{ height: '12px' }}></div>
                    ) : (
                      `${main}.${decimals}`
                    )}
                  </span>
                  <span className={hideBarcode ? 'bottom-changed' : 'bottom'}>
                    {hidePrice ? '' : 'UZS'}
                  </span>
                </div>

                {hideBarcode ? (
                  <div style={{ height: '28px' }}></div>
                ) : (
                  <Barcode
                    value={barcode}
                    width={1}
                    height={hidePrice ? 35 : 18}
                    format="CODE128"
                    displayValue={true}
                    font="monospace"
                    fontSize={10}
                    background="transparent"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {activeCard === 'card-line-def-58-30' && (
          <div key={key} className="card-line-def-58-30">
            <div className="card-body">
              <div className="body-items">
                <p className={'title'}>{name ?? ''}</p>

                <div className="cost-box">
                  <span className={'cost'}>
                    {hidePrice ? (
                      <div style={{ height: '12px' }}></div>
                    ) : (
                      `${main}`
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeCard === 'card-70-40' && (
          <div key={key} className="card-70-40">
            <div className="card-body">
              <div className="logo">
                <img src={logoType ? logoType : logo} alt="logo" />
              </div>

              <div className="body-items">
                <p className="filial">
                  {legalEntityName ? legalEntityName : 'FILIAL STANDART'}
                </p>
                <p className={hideBarcode ? 'changed-title' : 'title'}>
                  {name ?? ''}
                </p>

                <div className="cost-box">
                  <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                    {hidePrice ? <div style={{ height: '18px' }}></div> : main}
                  </span>

                  <div
                    className={
                      hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                    }
                  >
                    {hidePrice ? (
                      ''
                    ) : (
                      <>
                        <span className="top">{decimals ?? 0}</span>
                        <span className="bottom">UZS</span>
                      </>
                    )}
                  </div>
                </div>

                {hideBarcode ? (
                  <div style={{ height: '40px' }}></div>
                ) : (
                  <Barcode
                    value={barcode}
                    width={1.2}
                    height={hidePrice ? 40 : 25}
                    format="CODE128"
                    displayValue={true}
                    font="monospace"
                    fontSize={10}
                    background="transparent"
                  />
                )}
              </div>

              <span className="scale-code">
                {scale_code !== '' ? `(${scale_code})` : ''}
              </span>
            </div>
          </div>
        )}

        {activeCard === 'card-line-def-70-40' && (
          <div key={key} className="card-line-def-70-40">
            <div className="card-body">
              <div className="body-items">
                <p className={'title'}>{name ?? ''}</p>

                <div className="cost-box">
                  <span className={'cost'}>
                    {hidePrice ? (
                      <div style={{ height: '12px' }}></div>
                    ) : (
                      `${main}`
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ComponentToPrint;
