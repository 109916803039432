/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues
} from 'react-hook-form';
import { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import customerEntityService from 'service/charterer';
import legalEntityCurrencyService from 'service/legalEntityCurrency';

interface CreateCustomerLimitProps {
  setOpenCreateLimit: (show: boolean) => void;
  refetch?: any;
  selectedLimit?: any;
  setSelectedLimit?: any;
}

const CreateCustomerLimit = ({
  setOpenCreateLimit,
  refetch,
  selectedLimit,
  setSelectedLimit
}: CreateCustomerLimitProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({});
  console.log('selectedLimit', selectedLimit);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const charterer_id = pathname.split('/').pop();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [selectedLimitType, setSelectedLimitType] = useState('');
  const [selectedCurrencyId, setSelectedCurrencyId] = useState('');

  const limitTypeOption = [
    { label: t('amounts'), value: 'AMOUNT' },
    { label: t('time'), value: 'TIME' }
  ];

  const statusOption = [
    { label: t('active'), value: true },
    { label: t('blocked'), value: false }
  ];

  const timeTypeOption = [
    { label: t('hour'), value: 'HOUR' },
    { label: t('day'), value: 'DAY' },
    { label: t('month'), value: 'MONTH' },
    { label: t('year'), value: 'YEAR' }
  ];

  //Get by id
  const fetchData = () => {
    if (!selectedLimit || Object.keys(selectedLimit).length === 0) return;

    const computed = {
      status: selectedLimit.update_status,
      limit_type: selectedLimit.limitType === t('time') ? 'TIME' : 'AMOUNT',
      currency_id: selectedLimit.currency_id,
      amount_limit:
        selectedLimit.limitType === t('time')
          ? undefined
          : selectedLimit.amount,
      time_limit: selectedLimit.timeLimit,
      /* prettier-ignore */
      time_type:
        selectedLimit?.time === t('hour')
          ? 'HOUR'
          : selectedLimit?.time === t('day')
            ? 'DAY'
            : selectedLimit?.time === t('month')
              ? 'MONTH'
              : 'YEAR'
    };
    console.log('computed', computed);

    setSelectedLimitType(computed.limit_type || '');
    setSelectedCurrencyId(computed.currency_id || '');

    Object.entries(computed).forEach(([key, value]) =>
      setValue(key as keyof FieldValues, value)
    );
  };

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);
    if (Object.keys(selectedLimit)?.length !== 0) return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    const createdData = {
      charterer_id: charterer_id,
      law_subject_type: 'LEGAL',
      limit_type: data?.limit_type,
      status: data?.status === 'true' ? true : false,
      currency_id:
        data?.limit_type === 'AMOUNT' ? data?.currency_id : undefined,
      amount_limit:
        data?.limit_type === 'AMOUNT' ? +data?.amount_limit : undefined,
      time_limit: data?.limit_type === 'TIME' ? +data?.time_limit : undefined,
      time_type: data?.limit_type === 'TIME' ? data?.time_type : undefined
    };

    customerEntityService
      .createChartererLimit(createdData)
      .then(res => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('customer_limit')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          setLoading(false);
          setOpenCreateLimit(false);
          setSelectedLimit({});
          refetch();
        }
      })
      .catch(error => {
        if (error) {
          setLoading(false);
        }
      });
  };

  const update = (data: any) => {
    const createdData = {
      charterer_id: charterer_id,
      law_subject_type: 'LEGAL',
      limit_type: selectedLimitType,
      status: data?.status === 'true' || data?.status === true ? true : false,
      currency_id:
        selectedLimitType === 'AMOUNT' ? selectedCurrencyId : undefined,
      amount_limit:
        selectedLimitType === 'AMOUNT' ? +data?.amount_limit : undefined,
      time_limit: selectedLimitType === 'TIME' ? +data?.time_limit : undefined,
      id: selectedLimit?.guid,
      time_type: selectedLimitType === 'TIME' ? data?.time_type : undefined
    };

    customerEntityService
      .updateChartererLimit(selectedLimit?.guid, createdData)
      .then(res => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('changed')}`,
              type: 'success'
            })
          );
          setLoading(false);
          setOpenCreateLimit(false);
          setSelectedLimit({});
          refetch();
        }
      })
      .catch(error => {
        if (error) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (Object.keys(selectedLimit)?.length !== 0) {
      fetchData();
    }
  }, [Object.keys(selectedLimit)?.length]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                disabled={selectedLimit?.update_status}
                name="limit_type"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.limit_type}
                      ref={inputRef}
                      onChange={e => {
                        setSelectedLimitType(e.target.value);
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {limitTypeOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('limit_type')}
                    </label>
                    {errors?.limit_type && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        {selectedLimitType === 'AMOUNT' && (
          <>
            <Row className="mb-2">
              <Col>
                <div className="react-select-container">
                  <Controller
                    disabled={selectedLimit?.update_status}
                    name="currency_id"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true
                    }}
                    render={({ field }) => (
                      <Form.Floating>
                        <Form.Select
                          {...field}
                          isInvalid={!!errors.currency_id}
                        >
                          <option className="d-none" value=""></option>
                          {currencyOption?.map((option: any) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>

                        <label htmlFor="floatingInputCustom">
                          {t('currency')}
                        </label>
                        {errors?.currency_id && (
                          <span
                            style={{
                              marginTop: '2px',
                              color: 'red',
                              fontSize: '12px'
                            }}
                          >
                            {t('required_field')}
                          </span>
                        )}
                      </Form.Floating>
                    )}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <Controller
                  name="amount_limit"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <Form.Floating className="frist-item">
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder={t('amount_limit')}
                        onFocus={e => e.target.select()}
                        isInvalid={!!errors.amount_limit}
                      />
                      <label htmlFor="floatingInputCustom">
                        {t('amount_limit')}
                      </label>
                      {errors.amount_limit && (
                        <Form.Control.Feedback type="invalid">
                          {t('required_field')}
                        </Form.Control.Feedback>
                      )}
                    </Form.Floating>
                  )}
                />
              </Col>
            </Row>
          </>
        )}

        {selectedLimitType === 'TIME' && (
          <>
            <Row className="mb-2">
              <Col>
                <div className="react-select-container">
                  <Controller
                    name="time_type"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true
                    }}
                    render={({ field }) => (
                      <Form.Floating>
                        <Form.Select {...field} isInvalid={!!errors.time_type}>
                          <option className="d-none" value=""></option>
                          {timeTypeOption?.map((option: any) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>

                        <label htmlFor="floatingInputCustom">
                          {t('time_type')}
                        </label>
                        {errors?.time_type && (
                          <span
                            style={{
                              marginTop: '2px',
                              color: 'red',
                              fontSize: '12px'
                            }}
                          >
                            {t('required_field')}
                          </span>
                        )}
                      </Form.Floating>
                    )}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <Controller
                  name="time_limit"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <Form.Floating className="frist-item">
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder={t('time_limit')}
                        onFocus={e => e.target.select()}
                        isInvalid={!!errors.time_limit}
                      />
                      <label htmlFor="floatingInputCustom">
                        {t('time_limit')}
                      </label>
                      {errors.time_limit && (
                        <Form.Control.Feedback type="invalid">
                          {t('required_field')}
                        </Form.Control.Feedback>
                      )}
                    </Form.Floating>
                  )}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {statusOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                    {errors?.status && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          type="submit"
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          loading={loading}
          loadingPosition="start"
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default CreateCustomerLimit;
