/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import productSearchService from 'service/productSearch';
import discountService from 'service/discount';
import SearchDiscount from './SearchDiscount';

interface CreateDiscountItemProps {
  setOpenAddBonus: (show: boolean) => void;
  refetch?: any;
  selectedResultId?: any;
}

const CreateDiscountItem = ({
  setOpenAddBonus,
  refetch
}: CreateDiscountItemProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const discountId = pathname.split('/').pop();

  const { register, control, reset, getValues } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputBarcodeRef = useRef<HTMLInputElement | any>();

  const [openSearch, setOpenSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchedValue, setSearchedValue] = useState<any>('');

  const handleGetProduct = (e: any) => {
    if (e.key === 'Enter') {
      if (searchedValue !== '') {
        productSearchService
          .getListProduct({
            offset: 0,
            limit: 20,
            barcode: searchedValue ? searchedValue : undefined
          })
          .then((res: any) => {
            if (res) {
              const computed = {
                product_name: res?.products?.[0]?.name
              };

              if (inputBarcodeRef.current) {
                inputBarcodeRef?.current?.focus();
                inputBarcodeRef?.current?.select();
              }
              reset(computed);
            }
          });
      }
    }
  };

  const onSubmit = () => {
    const data = getValues();

    const createdData = {
      barcode: data?.barcode === undefined ? searchedValue : data?.barcode,
      discount_id: discountId
    };

    if (createdData?.barcode !== '') {
      setLoading(true);

      discountService
        .createDiscountItem(createdData)
        .then(res => {
          if (res) {
            setLoading(false);
            setOpenAddBonus(false);
            refetch();
            dispatch(
              showAlert({
                title: `${t('product')} ${t('successfully_added')}`,
                type: 'success'
              })
            );
          }
        })
        .catch(error => {
          if (error) {
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    if (inputBarcodeRef.current && searchedValue === '') {
      inputBarcodeRef.current.focus();
    }
  }, [inputBarcodeRef, searchedValue]);

  return (
    <>
      <Form
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col className="d-flex gap-2">
            <Controller
              name="barcode"
              control={control}
              render={({ field }) => (
                <Form.Floating className="md-4 flex-grow-1 bd-highlight">
                  <Form.Control
                    {...field}
                    ref={inputBarcodeRef}
                    type="text"
                    value={searchedValue}
                    placeholder={t('scan_barcode')}
                    onChange={e => setSearchedValue(e?.target?.value)}
                    onKeyDown={e => handleGetProduct(e)}
                    onFocus={e => {
                      e.target.select();
                    }}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('scan_barcode')}
                  </label>
                </Form.Floating>
              )}
            />

            <Button
              className="bd-highlight"
              variant="primary"
              type="button"
              onClick={() => {
                setOpenSearch(true);
              }}
            >
              {t('search')}
            </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled
                {...register('product_name')}
                type="text"
                placeholder={t('product_names')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('product_names')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="button"
          onClick={() => onSubmit()}
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
      <Modal
        show={openSearch}
        onHide={() => setOpenSearch(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('search')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchDiscount
            setOpenSearch={setOpenSearch}
            reset={reset}
            setSearchedValue={setSearchedValue}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateDiscountItem;
