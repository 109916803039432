/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import legalEntitySettingsService from 'service/legalEntitySettings';
import discountService from 'service/discount';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';
import Button from 'components/base/Button';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import SupperModal from 'components/modals/SupperModal';
import CreateDiscount from './CreateDiscount';

export const discountBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'discount',
    active: true
  }
];

export type DiscountType = {
  name: string;
  percentage: string;
  from_quantity: string;
  to_quantity: string;
  status: string;
  guid: string;
  date_create: boolean;
};

const Discount = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [openDiscount, setOpenDiscount] = useState(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState('');
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //Get Discount
  const { data, refetch, isFetching, isLoading } = useQuery(
    ['GET_DISCOUNT', currentPage],
    () => {
      const res = discountService
        .getList({ limit: 10, offset: pageToOffset(currentPage, 10) })
        .then((res: any) => res?.discounts);
      return res;
    },
    { enabled: !!currentPage }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const discountData: DiscountType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          percentage: items?.percentage,
          from_quantity: items?.from_quantity,
          to_quantity: items?.to_quantity,
          law_subject_type:
            items?.law_subject_type === 'LEGAL' ? t('LEGAL') : t('INDIVIDUAL'),
          /* prettier-ignore */
          date_create: formatDateToTimeZone(items?.date_create, timeZone),
          status: items?.status === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const navigateTo = (row: any) => {
    navigate(`/discount/discount-items/${row?.original?.guid}`, {
      state: { name: row?.original?.name }
    });
  };

  const discountDataTableColumns: ColumnDef<DiscountType>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div className="d-flex gap-2">
            <Button
              variant="hover"
              className="p-2"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedDiscountId(rowData?.row?.original?.guid);
                setOpenDiscount(true);
              }}
            >
              <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
            </Button>

            <Button
              variant="hover"
              className="p-2"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete(rowData?.row?.original?.guid);
              }}
            >
              <FeatherIcon
                icon="trash-2"
                className="cursor-pointer text-danger"
                size={20}
              />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('discount_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'percentage',
      header: t('percentage'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'from_quantity',
      header: t('from_quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'to_quantity',
      header: t('to_quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'law_subject_type',
      header: t('type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          [t('active')]: { bg: '#BBFBD0', text: '#119C2B' },
          [t('blocked')]: { bg: '#FDDFDD', text: '#F2271C' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div className="d-flex">
            <p
              style={{
                background: backgroundColor,
                padding: '8px 10px',
                textAlign: 'center',
                borderRadius: '8px',
                color: textColor,
                fontWeight: '600',
                margin: '0'
              }}
            >
              {status}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: discountData,
    columns: discountDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      discountService.deleteDiscount(selectedInvoiceId).finally(() => {
        refetch();
        setSelectedDiscountId('');
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={discountBreadcrumbItems} />

      <div className="d-flex justify-content-between">
        <h2>{t('discount')}</h2>
        <Button
          style={{ height: '48px' }}
          variant="primary"
          onClick={() => setOpenDiscount(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {t('new')}
        </Button>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            navigateTo={navigateTo}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title={t('discount')}
        bodyText={t('you_want_delete')}
      />

      <Offcanvas
        show={openDiscount}
        onHide={() => {
          setOpenDiscount(false);
          setSelectedDiscountId('');
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateDiscount
            setOpenDiscount={setOpenDiscount}
            selectedDiscountId={selectedDiscountId}
            refetch={refetch}
            setSelectedDiscountId={setSelectedDiscountId}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Discount;
