/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';
import { useSearchParams } from 'react-router-dom';

import legalEntityCurrencyService from 'service/legalEntityCurrency';
import Button from 'components/base/Button';
import usersService from 'service/users';

interface CashInfoProps {
  setCurrencyId?: any;
  setUserId?: any;
  dateFrom?: any;
  dateTo?: any;
  setDateFrom?: any;
  setDateTo?: any;
}

const CashFilter = ({
  setCurrencyId,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  setUserId
}: CashInfoProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t, i18n } = useTranslation();

  const { control, setValue, watch } = useForm();

  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [usersOption, setUsersOption] = useState<any>([]);

  const updateSearchParams = (key: string, value: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(key, value); // Set new value
    } else {
      newParams.delete(key); // Remove if empty
    }
    setSearchParams(newParams);
  };

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  useEffect(() => {
    if (!dateFrom) {
      setDateFrom(todayStart);
    }
    if (!dateTo) {
      setDateTo(todayEnd);
    }
  }, [dateFrom, dateTo, setDateFrom, setDateTo]);

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  //GET UNITS to options
  useQuery(['GET_CASHIER'], async () => {
    await usersService
      .getListCashier({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.data?.users?.map((option: any) => ({
          value: option?.id,
          label: option?.first_name + ' ' + option?.last_name
        }));
        setUsersOption(options);
      });
  });

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
      updateSearchParams('dateFrom', startDateInMillis?.toString());
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
      updateSearchParams('dateFrom', startDateInMillis?.toString());
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
      updateSearchParams('dateTo', endDateInMillis?.toString());
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
      updateSearchParams('dateTo', endDateInMillis?.toString());
    }
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (currencyOption?.length === 1) {
      setValue('currency', currencyOption[0].value);
      setCurrencyId(currencyOption[0].value);
    }
  }, [currencyOption, setValue, setCurrencyId]);

  useEffect(() => {
    const usersParam = searchParams.get('users') || '';
    const currencyParam = searchParams.get('currency') || '';
    const dateFromParam = searchParams.get('dateFrom');
    const dateToParam = searchParams.get('dateTo');

    if (dateFromParam) {
      setDateFrom(Number(dateFromParam));
    }
    if (dateToParam) {
      setDateTo(Number(dateToParam));
    }

    setUserId(usersParam);
    setCurrencyId(currencyParam);

    // Sync with react-hook-form values
    setValue('users', usersParam);
    setValue('currency', currencyParam);
  }, [searchParams, setValue]);

  return (
    <Form className="d-flex gap-2">
      <Form.Group>
        <div className="react-select-container position-relative">
          <Controller
            name="users"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Form.Floating>
                <Form.Select
                  {...field}
                  onChange={(e: any) => {
                    const selectedOption = usersOption.find(
                      (option: any) => option.value === e.target.value
                    );
                    if (selectedOption) {
                      setUserId(selectedOption?.value);
                      updateSearchParams('users', selectedOption?.value); // Update searchParams
                    }
                    field.onChange(e);
                  }}
                >
                  <option className="d-none" value=""></option>
                  {usersOption?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">{t('cashiers')}</label>
                {watch('users') !== '' && (
                  <Button
                    variant="link"
                    style={{
                      position: 'absolute',
                      right: '5%',
                      top: '15%',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setValue('users', '');
                      setUserId('');
                      updateSearchParams('users', ''); // Update searchParams
                    }}
                  >
                    <FeatherIcon icon="delete" size={16} />
                  </Button>
                )}
              </Form.Floating>
            )}
          />
        </div>
      </Form.Group>

      <Form.Group>
        <div className="react-select-container position-relative">
          <Controller
            name="currency"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Form.Floating>
                <Form.Select
                  {...field}
                  onChange={(e: any) => {
                    const selectedOption = currencyOption.find(
                      (option: any) => option.value === e.target.value
                    );
                    if (selectedOption) {
                      setCurrencyId(selectedOption?.value);
                      updateSearchParams('currency', selectedOption?.value); // Update searchParams
                    }
                    field.onChange(e);
                  }}
                >
                  <option className="d-none" value=""></option>
                  {currencyOption?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">{t('currency')}</label>
                {watch('currency') !== '' && (
                  <Button
                    variant="link"
                    style={{
                      position: 'absolute',
                      right: '5%',
                      top: '15%',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setValue('currency', '');
                      setCurrencyId('');
                      updateSearchParams('currency', ''); // Update searchParams
                    }}
                  >
                    <FeatherIcon icon="delete" size={16} />
                  </Button>
                )}
              </Form.Floating>
            )}
          />
        </div>
      </Form.Group>

      <Form.Group className="md-4">
        <Form.Floating>
          <Flatpickr
            className={classNames('form-control')}
            options={{
              nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
              prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
              locale: getFlatpickrLocale(),
              monthSelectorType: 'static',
              onDayCreate: (...[, , , dayElem]) => {
                if (
                  dayElem.dateObj.getDay() === 5 ||
                  dayElem.dateObj.getDay() === 6
                ) {
                  dayElem.className += ' weekend-days';
                }
              },
              dateFormat: 'M j, Y',
              disableMobile: true
            }}
            value={dateFrom}
            onChange={handleStartDateChange}
            placeholder={t('date_from')}
          />
          <label htmlFor="floatingInputCustom">{t('date_from')}</label>
        </Form.Floating>
      </Form.Group>

      <Form.Group className="md-4">
        <Form.Floating>
          <Flatpickr
            className={classNames('form-control')}
            options={{
              nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
              prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
              locale: getFlatpickrLocale(),
              monthSelectorType: 'static',
              onDayCreate: (...[, , , dayElem]) => {
                if (
                  dayElem.dateObj.getDay() === 5 ||
                  dayElem.dateObj.getDay() === 6
                ) {
                  dayElem.className += ' weekend-days';
                }
              },
              dateFormat: 'M j, Y',
              disableMobile: true
            }}
            value={dateTo}
            onChange={handleEndDateChange}
            placeholder={t('date_to')}
          />
          <label htmlFor="floatingInputCustom">{t('date_to')}</label>
        </Form.Floating>
      </Form.Group>
    </Form>
  );
};

export default CashFilter;
