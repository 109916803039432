/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { Table, TableProps, Nav, Form, Spinner, Modal } from 'react-bootstrap';
import { flexRender } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useRef } from 'react';
import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import tableSettingsService from 'service/tableSettings';
import { showAlert } from 'store/alert/alert.thunk';
import './styles.scss';

interface AdvanceTableProps {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  hasFooter?: boolean;
  navigateTo?: (row: any) => void;
  isLoading?: any;
}

const isUUID = (str: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

const isNumeric = (str: string) => {
  return /^\d+$/.test(str);
};

const AdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  hasFooter,
  navigateTo,
  isLoading
}: AdvanceTableProps) => {
  const { t } = useTranslation();
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders, getFooterGroups } = table;

  const dispatch: Dispatch<any> = useDispatch();
  const { pathname } = useLocation();

  const pathSegments = pathname.split('/').filter(Boolean);
  const lastSegment = pathSegments[pathSegments.length - 1];
  const secondLastSegment = pathSegments[pathSegments.length - 2];

  const pathSegment =
    isUUID(lastSegment) || isNumeric(lastSegment)
      ? secondLastSegment
      : lastSegment;

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [loading, setLoading] = useState(false);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [fields, setFields] = useState<{
    [key: string]: { hidden: boolean; order: number };
  }>({});
  const [selectAll, setSelectAll] = useState(true);

  const fetchDataCalled = useRef(false);

  const synchronizeFieldsWithHeaders = (headers: any[], fields: any) => {
    const updatedFields = { ...fields };

    headers.forEach((header, index) => {
      if (!updatedFields[header.id]) {
        // Add missing headers to fields
        updatedFields[header.id] = { hidden: false, order: index };
      }
    });

    return updatedFields;
  };

  const fetchData = async () => {
    if (!pathSegment || fetchDataCalled.current) return;

    fetchDataCalled.current = true;

    try {
      await tableSettingsService
        .getTableSettingsById(pathSegment)
        .then((res: any) => {
          if (res && typeof res.fields === 'object' && res.fields !== null) {
            const sortedFields = Object.entries(res.fields)
              .sort(([, a], [, b]) => {
                const orderA = (a as { order: number }).order;
                const orderB = (b as { order: number }).order;
                return orderA - orderB;
              })
              .reduce(
                (acc: any, [key, value], index) => {
                  if (typeof value === 'object' && value !== null) {
                    acc[key] = { ...value, order: index };
                  }
                  return acc;
                },
                {} as { [key: string]: { hidden: boolean; order: number } }
              );

            const synchronizedFields = synchronizeFieldsWithHeaders(
              getFlatHeaders(),
              sortedFields
            );
            setFields(synchronizedFields);

            const initialCheckedState = Object.keys(synchronizedFields).reduce(
              (acc, key) => {
                acc[key] = !synchronizedFields[key].hidden;
                return acc;
              },
              {} as { [key: string]: boolean }
            );

            setCheckedItems(initialCheckedState);
            setSelectAll(Object.values(initialCheckedState).every(Boolean));
          } else {
            console.error("Error: 'fields' data is not available.");
            // Use default column visibility (all visible) if no settings exist
            const defaultFields = getFlatHeaders().reduce(
              (acc, header, index) => {
                acc[header.id] = { hidden: false, order: index };
                return acc;
              },
              {} as { [key: string]: { hidden: boolean; order: number } }
            );

            setFields(defaultFields);

            const initialCheckedState = Object.keys(defaultFields).reduce(
              (acc, key) => {
                acc[key] = true; // Default to visible
                return acc;
              },
              {} as { [key: string]: boolean }
            );

            setCheckedItems(initialCheckedState);
            setSelectAll(true);
          }
        });
    } catch (error) {
      console.error('Error fetching table settings:', error);
      // Use default column visibility (all visible) in case of an error
      const defaultFields = getFlatHeaders().reduce(
        (acc, header, index) => {
          acc[header.id] = { hidden: false, order: index };
          return acc;
        },
        {} as { [key: string]: { hidden: boolean; order: number } }
      );

      setFields(defaultFields);

      const initialCheckedState = Object.keys(defaultFields).reduce(
        (acc, key) => {
          acc[key] = true; // Default to visible
          return acc;
        },
        {} as { [key: string]: boolean }
      );

      setCheckedItems(initialCheckedState);
      setSelectAll(true);
    }
  };

  const handleCheckboxChange = (field: string, isChecked: boolean) => {
    const updatedFields = {
      ...fields,
      [field]: { ...fields[field], hidden: !isChecked }
    };
    setFields(updatedFields);

    setCheckedItems((prevState: any) => ({
      ...prevState,
      [field]: isChecked
    }));

    if (!isChecked) {
      setSelectAll(false);
    } else {
      setSelectAll(
        Object.values({ ...checkedItems, [field]: isChecked }).every(Boolean)
      );
    }
  };

  const handleSelectAllChange = (isChecked: boolean) => {
    const updatedFields = Object.keys(fields).reduce(
      (acc, key) => {
        acc[key] = { ...fields[key], hidden: !isChecked };
        return acc;
      },
      {} as { [key: string]: { hidden: boolean; order: number } }
    );

    setFields(updatedFields);

    const updatedCheckedItems = Object.keys(fields).reduce(
      (acc, key) => {
        acc[key] = isChecked;
        return acc;
      },
      {} as { [key: string]: boolean }
    );

    setCheckedItems(updatedCheckedItems);
    setSelectAll(isChecked);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedFieldsArray = Object.entries(fields);

    if (
      result.source.index < 0 ||
      result.source.index >= reorderedFieldsArray.length ||
      result.destination.index < 0 ||
      result.destination.index >= reorderedFieldsArray.length
    ) {
      console.error('Source or destination index out of bounds');
      return;
    }

    const [movedItem] = reorderedFieldsArray.splice(result.source.index, 1);

    reorderedFieldsArray.splice(result.destination.index, 0, movedItem);

    const updatedFields = reorderedFieldsArray.reduce(
      (acc, [key, value], index) => {
        acc[key] = { ...value, order: index };
        return acc;
      },
      {} as { [key: string]: { hidden: boolean; order: number } }
    );

    // Update the state with the reordered fields and maintain the hidden state
    setFields(updatedFields);

    // Also, update the checkedItems state based on the newly reordered fields
    const updatedCheckedItems = Object.keys(updatedFields).reduce(
      (acc, key) => {
        acc[key] = !updatedFields[key].hidden;
        return acc;
      },
      {} as { [key: string]: boolean }
    );

    setCheckedItems(updatedCheckedItems);
    setSelectAll(Object.values(updatedCheckedItems).every(Boolean));
  };

  const onSubmit = async () => {
    setSettingsLoading(true);

    await tableSettingsService
      .updateTableSettings(pathSegment, {
        fields
      })
      .then(res => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('table_settings')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          setSettingsLoading(false);
        }
      });

    fetchDataCalled.current = false;
    fetchData();
  };

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (pathSegment) {
      console.log('Fetching data for pathSegment:', pathSegment); // Add this log
      fetchData();
    }
  }, [pathSegment]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleRowClick = (row: any) => {
    if (navigateTo) {
      navigateTo(row);
    }
  };

  const sortedHeaders =
    fields && Object.keys(fields).length > 0
      ? getFlatHeaders().sort((a, b) => {
          const orderA = fields[a.id]?.order ?? 0;
          const orderB = fields[b.id]?.order ?? 0;
          return orderA - orderB;
        })
      : getFlatHeaders().sort((a, b) => a.index - b.index);

  return (
    <div className="scrollbar ms-n1 ps-1">
      <Table {...tableProps}>
        <thead className={headerClassName}>
          <tr>
            {sortedHeaders.map(header => {
              const isHidden =
                fields && fields[header.id]?.hidden !== undefined
                  ? !checkedItems[header.id]
                  : false;
              return (
                <>
                  {!isHidden && (
                    <th
                      key={header.id}
                      {...header.column.columnDef.meta?.headerProps}
                      className={classNames(
                        header.column.columnDef.meta?.headerProps?.className,
                        {
                          sort: header.column.getCanSort(),
                          desc: header.column.getIsSorted() === 'desc',
                          asc: header.column.getIsSorted() === 'asc'
                        }
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  )}
                </>
              );
            })}
            <th style={{ width: '2%', textAlign: 'end' }}>
              <Nav.Item>
                <Button
                  variant="link"
                  type="button"
                  className="p-0"
                  onClick={handleClick}
                >
                  <FeatherIcon icon="settings" size={20} />
                </Button>

                <Modal
                  show={open}
                  onHide={handleClose}
                  size="lg"
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('table_settings')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="settings-container">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          type="checkbox"
                          label={t('select_all')}
                          onChange={e =>
                            handleSelectAllChange(e.target.checked)
                          }
                          checked={selectAll}
                          className="ms-2 mt-auto"
                        />
                        <Button
                          variant="primary"
                          onClick={onSubmit}
                          type="button"
                          style={{ height: '48px' }}
                          loading={settingsLoading}
                          loadingPosition="start"
                        >
                          {t('changed')}
                        </Button>
                      </div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable-settings">
                          {provided => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="droppable-list"
                            >
                              {sortedHeaders.map((header, index) => {
                                const columnDefHeader =
                                  header.column.columnDef.header;

                                if (typeof columnDefHeader === 'function') {
                                  return null;
                                }

                                const labelContent = flexRender(
                                  columnDefHeader,
                                  header.getContext()
                                );

                                console.log('labelContent', labelContent);

                                if (labelContent === undefined) {
                                  return null;
                                }

                                return (
                                  <Draggable
                                    key={header.id}
                                    draggableId={header.id}
                                    index={index}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="box"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label={labelContent}
                                          onChange={e =>
                                            handleCheckboxChange(
                                              header.id,
                                              e.target.checked
                                            )
                                          }
                                          checked={
                                            checkedItems[header.id] ?? true
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Modal.Body>
                </Modal>
              </Nav.Item>
            </th>
          </tr>
        </thead>

        <tbody className={bodyClassName}>
          {loading ? (
            <tr>
              <td
                style={{
                  height: '561.03px',
                  textAlign: 'center',
                  cursor: 'default', // Disable pointer cursor
                  pointerEvents: 'none' // Disable hover effects
                }}
                colSpan={getFlatHeaders().length}
                className="text-center"
              >
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: '#8200BF', width: '3rem', height: '3rem' }}
                />
              </td>
            </tr>
          ) : (
            getRowModel()?.rows.map((row: any) => {
              /* prettier-ignore */
              return (
                <tr
                  key={row?.id}
                  // className={row?.original?.color ? 'rowModel' : rowClassName}
                  className={classNames(rowClassName)}
                  style={{
                    backgroundColor: `${row?.original?.movedToTop ? '#F2EAF5' : ''}` // it is for making storno
                  }}
                  onClick={() => handleRowClick(row)}
                >
                  {sortedHeaders.map(header => {
                    const cell = row
                      ?.getVisibleCells()
                      .find((cell: any) => cell.column.id === header.id);
                    const isHidden =
                      fields && fields[header.id]?.hidden !== undefined
                        ? !checkedItems[header.id]
                        : false;

                    return (
                      <React.Fragment key={header.id}>
                        {!isHidden && cell && (
                          <td
                            key={cell.id}
                            {...cell.column.columnDef.meta?.cellProps}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
        {hasFooter && (
          <tfoot>
            {getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id} className="border-0">
                {footerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      {...header.column.columnDef.meta?.footerProps}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
    </div>
  );
};

export default AdvanceTable;
