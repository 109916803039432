/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';
import { useSearchParams } from 'react-router-dom';

import warehouseService from 'service/warehouse';
import Button from 'components/base/Button';
import usersService from 'service/users';

interface InfoProps {
  dateInventory?: any;
  setDateInventory?: any;
  setOptionWarehouseId?: any;
  setOptionStatusId?: any;
  setInventoryValue?: any;
  inventoryValue?: any;
  setBarcodeValue?: any;
  barcodeValue?: any;
  infoValues?: any;
}

const InfoFilter = ({
  dateInventory,
  setDateInventory,
  setOptionWarehouseId,
  setOptionStatusId,
  setInventoryValue,
  inventoryValue,
  setBarcodeValue,
  barcodeValue,
  infoValues
}: InfoProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t, i18n } = useTranslation();
  const { control, watch, setValue, getValues } = useForm();

  console.log('data', getValues());

  const updateSearchParams = (key: string, value: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(key, value); // Set new value
    } else {
      newParams.delete(key); // Remove if empty
    }
    setSearchParams(newParams);
  };

  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [usersOption, setUsersOption] = useState<any>([]);

  //GET Warehouse to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });

  //GET Users to options
  useQuery(['GET_USERS'], async () => {
    await usersService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.data?.users?.map((option: any) => ({
        value: option.id,
        label: `${option.first_name}  ${option?.last_name}`
      }));
      setUsersOption(options);
    });
  });

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDateInMillis = date[0]?.getTime();
      setDateInventory(startDateInMillis);
      updateSearchParams('dateFrom', startDateInMillis?.toString());
    } else {
      const startDateInMillis = date?.getTime();
      setDateInventory(startDateInMillis);
      updateSearchParams('dateFrom', startDateInMillis?.toString());
    }
  };

  const generateExcelFile = () => {
    const worksheetData = infoValues?.map((item: any, index: any) => ({
      '№': index + 1,
      '№ накладной': item?.inventory_number,
      'Материально-ответственного лицо': item?.inventory_name,
      'Дата накладной': item?.date_inventory,
      Склад: item?.inventory_warehouse,
      'Умный режим': item?.smart_mode,
      'Дата создания': item?.date_create_inventory
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Scales Data');

    XLSX.writeFile(workbook, 'инвентаризации.xlsx');
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const barcodeParam = searchParams.get('barcode');
    const inventoryNumberParam = searchParams.get('inventoryNumber');
    const stockParam = searchParams.get('stock') || '';
    const responsibleParam = searchParams.get('responsible') || '';
    const dateFromParam = searchParams.get('dateFrom');

    if (dateFromParam) {
      setDateInventory(Number(dateFromParam));
    }

    setBarcodeValue(barcodeParam);
    setOptionWarehouseId(stockParam);
    setInventoryValue(inventoryNumberParam);
    setOptionStatusId(responsibleParam);

    // Sync with react-hook-form values
    setValue('stock', stockParam);
    setValue('responsible', responsibleParam);
  }, [searchParams, setValue]);

  return (
    <div>
      <Form noValidate>
        <div className="d-flex gap-3">
          <Form.Floating>
            <Form.Control
              value={barcodeValue}
              onChange={e => {
                setBarcodeValue(e.target.value);
                updateSearchParams('barcode', e.target.value);
              }}
              type="text"
              placeholder={t('enter_barcode')}
              onFocus={e => e.target.select()}
            />
            <label htmlFor="floatingInputCustom">{t('enter_barcode')}</label>
          </Form.Floating>
          <Form.Group>
            <div className="react-select-container ">
              <Controller
                name="stock"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = warehouseOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setOptionWarehouseId(selectedOption?.value);
                          updateSearchParams('stock', selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {warehouseOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('warehouse')}
                    </label>
                    {watch('stock') !== '' && (
                      <Button
                        variant="link"
                        style={{
                          position: 'absolute',
                          right: '5%',
                          top: '15%',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOptionWarehouseId('');
                          setValue('stock', '');
                          updateSearchParams('stock', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>

          <Form.Floating>
            <Form.Control
              value={inventoryValue}
              onChange={e => {
                setInventoryValue(e.target.value);
                updateSearchParams('inventoryNumber', e.target.value);
              }}
              type="number"
              placeholder={t('inventory_number')}
              onFocus={e => e.target.select()}
            />
            <label htmlFor="floatingInputCustom">{t('inventory_number')}</label>
          </Form.Floating>

          <Form.Group className="md-4">
            <Form.Floating>
              <Flatpickr
                className={classNames('form-control')}
                options={{
                  nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                  prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                  locale: getFlatpickrLocale(),
                  monthSelectorType: 'static',
                  onDayCreate: (...[, , , dayElem]) => {
                    if (
                      dayElem.dateObj.getDay() === 5 ||
                      dayElem.dateObj.getDay() === 6
                    ) {
                      dayElem.className += ' weekend-days';
                    }
                  },
                  dateFormat: 'M j, Y',
                  disableMobile: true
                }}
                value={dateInventory}
                onChange={handleStartDateChange}
                placeholder={t('date_invoice')}
              />
              <label htmlFor="floatingInputCustom">{t('date_invoice')}</label>
            </Form.Floating>
          </Form.Group>

          <Form.Group>
            <div className="react-select-container position-relative">
              <Controller
                name="responsible"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating className="users">
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = usersOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setOptionStatusId(selectedOption?.value);
                          updateSearchParams(
                            'responsible',
                            selectedOption?.value
                          );
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {usersOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">
                      {t('financially_responsible_person')}
                    </label>
                    {watch('responsible') !== '' && (
                      <Button
                        variant="link"
                        style={{
                          position: 'absolute',
                          right: '5%',
                          top: '15%',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOptionStatusId('');
                          setValue('responsible', '');
                          updateSearchParams('responsible', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>

          <Button
            style={{ height: '48px' }}
            variant="outline-primary"
            onClick={() => generateExcelFile()}
          >
            <FeatherIcon icon="upload-cloud" className="me-2" size={20} />
            {t('export')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default InfoFilter;
