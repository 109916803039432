/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import customerEntityService from 'service/charterer';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CreateCustomerLimit from './CreateCustomerLimit';
import SupperModal from 'components/modals/SupperModal';

export type CustomerLimitsType = {
  limitType: string;
  currency: string;
  amount: string;
  time: string;
  timeLimit: string;
  status: string;
  guid: string;
  currency_id: string;
  updated_limit_type: string;
};

export const customerLimitsBreadcrumbIItems: PageBreadcrumbItem[] = [
  {
    label: 'customer',
    url: '/dtkt/customer'
  },
  {
    label: 'customer_limit',
    active: true
  }
];
const CustomerLimits = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const charterer_id = pathname.split('/').pop();

  const [openCreatLimit, setOpenCreateLimit] = useState(false);
  const [selectedLimit, setSelectedLimit] = useState<object>({});
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  const { data, refetch } = useQuery(
    ['GET_CUSTOMER_LIMIT'],
    async () => {
      if (charterer_id) {
        const res = await customerEntityService
          .getByIdChartererLimit(charterer_id)
          .then((res: any) => {
            return res;
          });
        return res;
      }
    },
    { enabled: !!charterer_id }
  );

  const cutomerLimitData: CustomerLimitsType[] = useMemo(() => {
    return (
      data?.charterer_limits?.map((items: any) => {
        return {
          limitType: items?.limit_type === 'TIME' ? t('time') : t('amounts'),
          updated_limit_type: items?.limit_type,
          currency: items?.currency?.name?.[i18n?.language],
          currency_id: items?.currency?.id,
          amount: items?.amount_limit ?? 0,
          /* prettier-ignore */
          time:
            items?.time_type === 'HOUR'
              ? t('hour')
              : items?.time_type === 'DAY'
                ? t('day')
                : items?.time_type === 'MONTH'
                  ? t('month')
                  : t('year'),
          timeLimit: items?.time_limit,
          status: items?.status === true ? t('active') : t('blocked'),
          update_status: items?.status === true ? true : false,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const customerLimitTableColumns: ColumnDef<CustomerLimitsType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="hover"
            className="p-2"
            onClick={() => {
              setOpenCreateLimit(true);
              setSelectedLimit(rowData.row.original);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
          <Button
            variant="hover"
            className="p-2"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'limitType',
      header: t('limit_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'amount',
      header: t('amounts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'time',
      header: t('time'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'timeLimit',
      header: t('time_limit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          [t('active')]: { bg: '#BBFBD0', text: '#119C2B' },
          [t('blocked')]: { bg: '#FDDFDD', text: '#F2271C' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div className="d-flex justify-content-center">
            <p
              style={{
                background: backgroundColor,
                padding: '8px 10px',
                textAlign: 'center',
                borderRadius: '8px',
                color: textColor,
                fontWeight: '600',
                margin: '0'
              }}
            >
              {status}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: cutomerLimitData,
    columns: customerLimitTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      customerEntityService
        .deleteChartererLimit(selectedInvoiceId)
        .finally(() => {
          refetch();
          setSelectedLimit({});
          setOpenSupperModal(false);
          setConfirmToDelete(false);
          setSelectedInvoiceId(null);
        });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={customerLimitsBreadcrumbIItems} />

      <div className="d-flex justify-content-between align-items-center">
        <h2>{t('customer_limit')}</h2>
        <Button
          type="button"
          variant="primary"
          style={{ height: '48px' }}
          onClick={() => setOpenCreateLimit(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {t('new')}
        </Button>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title={t('customer_limit')}
        bodyText={t('you_want_delete')}
      />

      <Offcanvas
        show={openCreatLimit}
        onHide={() => {
          setOpenCreateLimit(false);
          setSelectedLimit({});
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateCustomerLimit
            setOpenCreateLimit={setOpenCreateLimit}
            refetch={refetch}
            selectedLimit={selectedLimit}
            setSelectedLimit={setSelectedLimit}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CustomerLimits;
