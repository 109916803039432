/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import cashService from 'service/cash';

interface CreateCashRegisterProps {
  setOpenCash: (show: boolean) => void;
  selectedCashId?: any;
  refetch?: any;
  setSelectedCashId?: any;
  bonusData?: any;
}

const CreateCashRegister = ({
  setOpenCash,
  selectedCashId,
  refetch,
  setSelectedCashId,
  bonusData
}: CreateCashRegisterProps) => {
  const { t } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const fetchData = () => {
    if (selectedCashId === '') return setLoading(false);

    cashService
      .getCashById(selectedCashId)
      .then((res: any) => {
        const computed = {
          name: res?.name,
          status: res?.status ?? false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (isSubmitting) return; // Prevent additional submissions
    setIsSubmitting(true);

    const nameExists = bonusData.some(
      (bonus: any) => bonus.name === data?.name
    );

    if (nameExists) {
      dispatch(
        showAlert({
          title: t('name_already_exists')
        })
      );
      setIsSubmitting(false); // Allow retry
      return;
    }

    if (selectedCashId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      name: data?.name,
      status: data?.status === 'true' || data?.status === true ? true : false
    };

    cashService.createCash(createdData).then((res: any) => {
      if (res) {
        dispatch(
          showAlert({
            title: `${t('cash')} ${t('successfully_added')}`,
            type: 'success'
          })
        );
        refetch();
        setOpenCash(false);
        setIsSubmitting(false);
        setLoading(false);
        setSelectedCashId('');
      }
    });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name,
      status: data?.status === 'true' || data?.status === true ? true : false,
      id: selectedCashId
    };
    cashService.updateCash(selectedCashId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setIsSubmitting(false);
      setOpenCash(false);
      setSelectedCashId('');
    });
  };

  useEffect(() => {
    if (selectedCashId !== '') {
      fetchData();
    }
  }, [selectedCashId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef?.current?.select();
      }, 500);
    }
  }, [inputRef.current]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <Controller
              name={`name`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('name_cash_desk')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.name}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('name_cash_desk')}
                  </label>
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                    {errors?.status && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
          disabled={isSubmitting}
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateCashRegister;
