/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import NestedAdvanceTable from 'components/base/NestedAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import cashflowService from 'service/cashflow';
import CashFilter from './CashFilter';

const cashflowBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'finance',
    url: '/finance/cashflow'
  },
  {
    label: 'dds',
    active: true
  }
];

export type ColumnType = {
  name: string;
  total_amount?: number;
};

const Cashflow = () => {
  const { t, i18n } = useTranslation();
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [currencyId, setCurrencyId] = useState('');
  const [selectedBranchId, setSelectedBranchId] = useState('');

  useEffect(() => {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1); // Start of the current year
    setDateFrom(startOfYear.getTime());
    setDateTo(today.getTime());
  }, []);

  const keyTranslationMap: Record<string, string> = {
    income: t('income'),
    expense: t('consumption'),
    sale: t('sale'),
    return: t('return'),
    other: t('other'),
    invoice: t('purchase'),
    operation_articles: t('operating_expenses'),
    other_articles: t('other_articles'),
    taxes_articles: t('tax_articles')
  };

  const translateKey = (key: string): string => keyTranslationMap[key] || key;

  const extractHeaders = (data: any): string[] => {
    const headers = new Set<string>();
    const extract = (obj: any) => {
      if (obj?.amount) {
        Object.keys(obj.amount).forEach(key => headers.add(key));
      }
      Object.values(obj).forEach(value => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          extract(value);
        }
      });
    };
    extract(data);
    return Array.from(headers);
  };

  const ensureZeroForMissingDates = (obj: any, headers: string[]) => {
    headers.forEach(header => {
      if (obj[header] === undefined) {
        obj[header] = 0;
      }
    });
  };

  const transformData = (data: any, headers: string[]): any[] => {
    const removeDuplicateNames = (rows: any[], parentName: string): any[] => {
      const seenNames = new Set<string>();
      return rows.filter(row => {
        if (row.name === parentName || seenNames.has(row.name)) {
          return false;
        }
        seenNames.add(row.name);
        return true;
      });
    };

    const processCategory = (
      categoryData: any,
      categoryName: string,
      isRoot = false
    ): any[] => {
      const rows: any[] = [];

      for (const key in categoryData) {
        let displayName = translateKey(key);

        if (categoryData[key]?.name?.[i18n?.language]) {
          displayName = categoryData[key].name?.[i18n?.language];
        } else if (typeof categoryData[key] === 'string') {
          displayName = categoryData[key];
        }

        if (key === 'amount' || key === 'total_amount') {
          if (isRoot || !categoryData.children) {
            const row = {
              name: categoryName,
              ...categoryData[key],
              total_amount: categoryData.total_amount ?? 0,
              children: []
            };
            ensureZeroForMissingDates(row, headers);
            rows.push(row);
          }
        } else if (
          categoryData[key]?.amount ||
          categoryData[key]?.total_amount !== undefined
        ) {
          const row = {
            name: displayName,
            ...categoryData[key]?.amount,
            total_amount: categoryData[key]?.total_amount ?? 0,
            children: []
          };
          ensureZeroForMissingDates(row, headers);

          if (categoryData[key].payment_methods) {
            const paymentMethodChildren = categoryData[key].payment_methods.map(
              (method: any) => ({
                name: method.name?.[i18n?.language],
                ...headers.reduce((acc: any, date) => {
                  acc[date] = method.amount?.[date] ?? 0;
                  return acc;
                }, {}),
                total_amount: method.total_amount ?? 0,
                children: []
              })
            );
            row.children.push(...paymentMethodChildren);
          }

          rows.push(row);
        } else if (Array.isArray(categoryData[key])) {
          const additionalChildren = categoryData[key]
            .map((item: any) => {
              if (item.name && item.name?.[i18n?.language]) {
                return {
                  name: item.name?.[i18n?.language],
                  ...headers.reduce((acc: any, date) => {
                    acc[date] = item.amount?.[date] ?? 0;
                    return acc;
                  }, {}),
                  total_amount: item.total_amount ?? 0,
                  children: []
                };
              }
              return null;
            })
            .filter((item: any) => item !== null);

          rows.push(...additionalChildren);

          const children = categoryData[key].map((item: any) =>
            processCategory(item, item?.name?.[i18n?.language] || displayName)
          );

          rows.push(...children.flat());
        } else if (typeof categoryData[key] === 'object' && key !== 'name') {
          if (key === 'payment_methods') {
            const paymentMethods = categoryData[key].map((item: any) => ({
              name: item.name?.[i18n?.language],
              ...headers.reduce((acc: any, date) => {
                acc[date] = item.amount?.[date] ?? 0;
                return acc;
              }, {}),
              total_amount: item.total_amount ?? 0,
              children: []
            }));
            rows.push(...paymentMethods);
          } else {
            const children = processCategory(categoryData[key], displayName);
            if (children.length > 0) {
              const row = {
                name: displayName,
                children: children,
                ...categoryData[key]?.amount,
                total_amount: categoryData[key]?.total_amount ?? 0
              };
              ensureZeroForMissingDates(row, headers);
              rows.push(row);
            } else {
              // Handling empty objects by adding a row with zero values
              const row = {
                name: displayName,
                ...headers.reduce((acc: any, date) => {
                  acc[date] = 0;
                  return acc;
                }, {}),
                total_amount: 0,
                children: []
              };
              rows.push(row);
            }
          }
        } else if (Object.keys(categoryData[key]).length === 0) {
          const row = {
            name: displayName,
            ...headers.reduce((acc: any, date) => {
              acc[date] = 0;
              return acc;
            }, {}),
            total_amount: 0,
            children: []
          };
          rows.push(row);
        }
      }

      return rows;
    };

    const income = processCategory(data.income, translateKey('income'), true);
    const expense = processCategory(
      data.expense,
      translateKey('expense'),
      true
    );

    const rootIncome = {
      name: translateKey('income'),
      children: removeDuplicateNames(income, translateKey('income')),
      ...data.income.amount,
      total_amount: data.income.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootIncome, headers);

    const rootExpense = {
      name: translateKey('expense'),
      children: removeDuplicateNames(expense, translateKey('expense')),
      ...data.expense.amount,
      total_amount: data.expense.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootExpense, headers);

    return [rootIncome, rootExpense];
  };

  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, isLoading, isError, refetch } = useQuery(
    ['GET_CASHFLOW'],
    () =>
      cashflowService.getList({
        offset: 0,
        limit: 10,
        currency_id: currencyId,
        date_from: dateFrom,
        date_to: adjustedDateTo,
        branch_id: selectedBranchId
      }),
    {
      enabled:
        !!dateFrom || !!adjustedDateTo || !!currencyId || !!selectedBranchId,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, currencyId, t, selectedBranchId]);

  const headers = useMemo(() => (data ? extractHeaders(data) : []), [data]);
  const tableData = useMemo(
    () => (data ? transformData(data, headers) : []),
    [data, headers, t]
  );

  const columns: ColumnDef<ColumnType>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('cash_flow_names'),
        meta: { cellProps: { className: 'text-900' } }
      },
      {
        accessorKey: 'total_amount',
        header: t('total_amount'),
        meta: { cellProps: { className: 'text-900' } }
      },
      ...headers.map(header => ({
        accessorKey: header,
        header,
        meta: { cellProps: { className: 'text-900' } }
      }))
    ],
    [headers]
  );

  const table = useAdvanceTable({
    data: tableData,
    columns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  if (isLoading) {
    return <div>Loading...</div>; // Add a loading state
  }

  if (isError || !data) {
    return <div>No data available</div>; // Handle the case where there is no data or an error occurred
  }

  return (
    <div>
      <PageBreadcrumb items={cashflowBreadcrumbItems} />
      <h2 className="mb-5">{t('dds')}</h2>

      <CashFilter
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        setCurrencyId={setCurrencyId}
        setSelectedBranchId={setSelectedBranchId}
        selectedBranchId={selectedBranchId}
      />
      <AdvanceTableProvider {...table}>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <NestedAdvanceTable
            bodyClassName="bg-white"
            rowClassName="text-nowrap"
            tableProps={{ className: 'phoenix-table fs-9' }}
            hasFooter={false}
            data={tableData}
            columns={columns}
          />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default Cashflow;
