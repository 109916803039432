/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col, Offcanvas } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import invoiceService from 'service/invoice';
import Button from 'components/base/Button';
import { PurchaseSelections } from 'data/purchaseSelction';
import CreateNewPurchase from './filters/new/purchase-selection';
import SupperModal from 'components/modals/SupperModal';
import usePreventBackOnRoute from 'utils/usePreventBackOnRoute';
import legalEntitySettingsService from 'service/legalEntitySettings';
import { tableSavedDataActions } from 'store/tableSavedData/tableSavedData.slice';

export const createInventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'purchase',
    active: true
  }
];

const CreateInvoice = () => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  // prevent back
  usePreventBackOnRoute('/purchase/create');

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //Get Product Search
  const { data, refetch } = useQuery(['GET_INVOICE_DATA'], () => {
    const res = invoiceService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.invoices);
    return res;
  });

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const purchaseData: PurchaseSelections[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          name: items?.legal_entity?.name,
          /* prettier-ignore */
          date_invoice:
            items?.invoice_date === 0 || !items?.invoice_date
              ? ''
              :  formatDateToTimeZone(items?.invoice_date, timeZone),
          /* prettier-ignore */
          date_create:
            items?.maturity_date === 0 || !items?.maturity_date
              ? ''
              :  formatDateToTimeZone(items?.maturity_date, timeZone),
          invoice: items?.invoice_number,
          stock: items?.warehouse?.name,
          currency_id: items?.currency_id,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  useEffect(() => {
    if (confirmToDelete && selectedInvoiceId) {
      invoiceService.deleteInvoice(selectedInvoiceId).finally(() => {
        dispatch(
          tableSavedDataActions.removeTableById({
            id: selectedInvoiceId
          })
        );
        refetch();
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  const purchaseSelectionTableColumns: ColumnDef<PurchaseSelections>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <Row>
            <Col className="d-flex gap-2">
              <Button
                variant="hover"
                className="p-2"
                onClick={() => {
                  navigate(
                    `/purchase/create/create-add/${rowData?.row?.original?.guid}?currency_id=${rowData?.row?.original?.currency_id}`
                  );
                }}
              >
                <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
              </Button>
              <Button
                variant="hover"
                className="p-2"
                onClick={() => handleDelete(rowData?.row?.original?.guid)}
              >
                <FeatherIcon
                  icon="trash-2"
                  className="cursor-pointer text-danger"
                  size={20}
                />
              </Button>
            </Col>
          </Row>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('supplier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_invoice',
      header: t('date_invoice'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'invoice',
      header: t('invoice'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'stock',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: t('maturity_date'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: purchaseData,
    columns: purchaseSelectionTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <div>
      <PageBreadcrumb items={createInventoryBreadcrumbItems} />
      <div>
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h2>{t('purchase')}</h2>
          <Button
            onClick={() => setModalShow(true)}
            style={{ height: '48px' }}
            variant="primary"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('new')}
          </Button>
        </div>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      </div>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title={t('purchase')}
        bodyText={t('you_want_delete')}
      />

      <Offcanvas
        show={modalShow}
        onHide={() => setModalShow(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateNewPurchase />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CreateInvoice;
