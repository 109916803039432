/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import CashFilter from './CashFilter';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import Button from 'components/base/Button';
import CashInfoCheck from './CashInfoCheck';
import { pageToOffset } from 'utils/pageToOffset';
import cashboxService from 'service/cashbox';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type cashInfoType = {
  employee: string;
  cash_name: string;
  date_open: string;
  date_close: string;
  guid: string;
};

export const cashInfoBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'reports',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'cash',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'checks',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'total_checks',
    active: true
  }
];

const CashInfo = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [userId, setUserId] = useState('');
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [selectedDateOpen, setSelectedDateOpen] = useState('');
  const [selectedDateClose, setSelectedDateClose] = useState('');
  const [activeItemId, setActiveItemId] = useState<any>(null);
  const navigate = useNavigate();

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  const updateSearchParams = (key: string, value: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(key, value); // Set new value
    } else {
      newParams.delete(key); // Remove if empty
    }
    setSearchParams(newParams);
  };

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  // Use the provided dates or default to today's date
  const adjustedDateFrom = dateFrom || todayStart;
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? todayEnd + 86400000
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  //Get Product Search
  const { data, isFetching, isLoading } = useQuery(
    [
      'GET_CASHBOX',
      currentPage,
      adjustedDateFrom,
      adjustedDateTo,
      currencyId,
      userId
    ],
    async () => {
      const res = await cashboxService
        .getList({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          date_from: adjustedDateFrom,
          date_to: adjustedDateTo,
          currency_id: currencyId,
          employee_id: userId
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    {
      enabled:
        !!currentPage ||
        !!adjustedDateFrom ||
        !!adjustedDateTo ||
        !!currencyId ||
        !!userId
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const cashInfoData: cashInfoType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.cashboxes?.map((items: any) => {
        return {
          employee: `${items?.employee?.first_name} ${items?.employee?.last_name}`,
          cash_name: items?.legal_entity_cash?.name,
          date_open: formatDateToTimeZone(items?.date_open, timeZone),
          date_close: items?.date_close
            ? formatDateToTimeZone(items?.date_close, timeZone)
            : '',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings]);

  const productsDataTableColumns: ColumnDef<cashInfoType>[] = [
    {
      id: 'action',
      cell: rowData => {
        const itemId = rowData?.row?.original?.guid;
        return (
          <div className="d-flex gap-2">
            <Button
              variant={activeItemId === itemId ? 'active' : 'hover'}
              className="p-1"
              onClick={() => {
                setSelectedId(itemId);
                setSelectedDateOpen(rowData?.row?.original?.date_open);
                setSelectedDateClose(rowData?.row?.original?.date_close);
                setActiveItemId(itemId);
              }}
            >
              <FeatherIcon icon="info" size={24} className="text-warning" />
            </Button>
            <Button
              variant="hover"
              className="p-1"
              onClick={() => {
                navigate(
                  `/reports/cash-info/cash-info-check/${rowData?.row?.original?.guid}`
                );
              }}
            >
              <FeatherIcon icon="file-text" size={22} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },

    {
      accessorKey: 'employee',
      header: t('employees'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'cash_name',
      header: t('cash_register_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_open',
      header: t('opening_date'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_close',
      header: t('closing_date'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: cashInfoData,
    columns: productsDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    const pageParam = searchParams.get('pageParam');

    if (pageParam) {
      setCurrentPage(Number(pageParam));
    }
  }, [searchParams]);

  return (
    <div>
      <PageBreadcrumb items={cashInfoBreadcrumbItems} />

      <h2 className="mb-5">{t('total_checks')}</h2>
      <CashFilter
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        setCurrencyId={setCurrencyId}
        setUserId={setUserId}
      />

      <div className="d-flex gap-3">
        <AdvanceTableProvider {...table}>
          <div
            style={{ width: '70%' }}
            className="mt-4 px-4 mx-lg-n2 px-lg-4 bg-white border-top border-300 position-relative top-1"
          >
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              isLoading={loading}
            />
            <AdvanceTableCPagination
              count={pageCount}
              page={currentPage}
              setCurrentPage={setCurrentPage}
              onClick={(event, page) => {
                if (page) {
                  updateSearchParams('pageParam', String(page));
                }
              }}
            />
          </div>
        </AdvanceTableProvider>

        <CashInfoCheck
          selectedId={selectedId}
          currencyId={currencyId}
          selectedDateOpen={selectedDateOpen}
          selectedDateClose={selectedDateClose}
        />
      </div>
    </div>
  );
};

export default CashInfo;
