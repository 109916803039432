/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import usePageSearchParams from 'utils/usePageSearchParams';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export type SettelementData = {
  cashier: string;
  currency: string;
  input_balance: string;
  amounts: string;
  output_balance: string;
  status: string;
  bg_color: string;
  text_color: string;
  transaction_type: string;
  guid: string;
};

const SettelementCustomerResultTable = () => {
  const { t, i18n } = useTranslation();

  const { pathname, state } = useLocation();
  const [searchParams] = usePageSearchParams();

  const { name } = state;
  const debitor_id = pathname.split('/').pop();
  const currency_id = searchParams.get('currency_id');
  const creditor_id = searchParams.get('creditor_id');

  const { data } = useQuery(
    ['GET_SETTELEMENT_RESULT'],
    () => {
      if (debitor_id && currency_id && creditor_id) {
        const res = customerEntityService
          .getListHistorySettlement({
            offset: 0,
            limit: 100,
            debitor_id: debitor_id,
            currency_id: currency_id,
            creditor_id: creditor_id
          })
          .then((res: any) => res?.debit_credit_settlements);
        return res;
      }
    },
    { enabled: !!debitor_id && !!currency_id && !!creditor_id }
  );

  const resultData: SettelementData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          cashier:
            items?.employee?.first_name + ' ' + items?.employee?.last_name,
          input_balance: items?.balance_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.balance_in)
                .replace(/,/g, ' ')
            : 0,
          output_balance: items?.balance_out
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.balance_out)
                .replace(/,/g, ' ')
            : 0,
          amount: items?.amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount)
                .replace(/,/g, ' ')
            : 0,
          status: items?.status?.name?.[i18n?.language],
          currency: items?.currency?.name?.[i18n?.language],
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          transaction_type: items?.transaction_type,
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'dt_kt',
      url: '/dtkt/provider'
    },
    {
      label: 'settlement_customer',
      url: '/dtkt/buyyers'
    },
    {
      label: name ?? '',
      active: true
    }
  ];

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      accessorKey: 'cashier',
      header: t('cashier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'input_balance',
      header: t('input_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'amount',
      header: t('amounts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'output_balance',
      header: t('output_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'transaction_type',
      header: t('transaction_type'),
      cell: rowData => {
        /* prettier-ignore */
        return (
          <div>
            {rowData?.row?.original?.transaction_type === 'DEBIT'
              ? t('DEBIT')
              : rowData?.row?.original?.transaction_type === 'CREDIT'
                ? t('CREDIT')
                : rowData?.row?.original?.transaction_type === 'RETURN'
                  ? t('RETURN')
                  : t('RECALCULATION')}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status, bg_color, text_color } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          новый: { bg: '#1d4289', text: '#ffffff' },
          'Завершен с расхождением': { bg: '#fef3c7', text: '#92400e' },
          Yangi: { bg: '#fef3c7', text: '#92400e' },
          'Qisman tugallangan': { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
          bg_color || defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor =
          text_color || defaultColors[status]?.text || '#000000';

        return (
          <div className="d-flex justify-content-center">
            <p
              style={{
                background: backgroundColor,
                padding: '8px 10px',
                textAlign: 'center',
                borderRadius: '8px',
                color: textColor,
                fontWeight: '600',
                margin: '0'
              }}
            >
              {status}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: resultData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{name ?? ''}</h2>
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default SettelementCustomerResultTable;
