/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { newApplicationsBreadcrumbItems } from 'data/newapplications';
import NewApplicationsTableSearch from './NewApplicationsTableSearch';
import NewApplicationsTable from './NewApplicationTable';
import orderItemService from 'service/orderItem';
import orderService from 'service/order';
import orderOutService from 'service/orderOut';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import usePageSearchParams from 'utils/usePageSearchParams';
import useFocusRefs from 'utils/useFocusRefs';
import legalEntitySettingsService from 'service/legalEntitySettings';

import './styles.scss';
import customerEntityService from 'service/charterer';

const NewApplicatons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();
  const newOrderId = pathname.split('/').pop();
  const senderId = searchParams?.get('sender_id');
  const dispatch: Dispatch<any> = useDispatch();
  const { setRef, focusRef } = useFocusRefs();

  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  const legalEntityId = useSelector(
    (state: any) => state?.auth?.legal_entity?.id
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [orderItemData, setOrderItemData] = useState([]);
  const [isAutoTab, setIsAutoTab] = useState(false);
  const [savedBarcodes, setSavedBarcodes] = useState<any>([]);

  const { data } = useQuery(
    ['GET_ORDER', newOrderId],
    () => {
      if (newOrderId !== '') {
        const res = orderService.getOrderById(newOrderId).then((res: any) => {
          return res;
        });
        return res;
      }
    },
    { enabled: !!newOrderId }
  );

  const { data: chartererData } = useQuery(
    ['GET_CHARTERER_CHECK_LIMIT', currencyId, legalEntityId, senderId, data],
    () => {
      if (
        currencyId !== '' &&
        legalEntityId !== '' &&
        senderId !== '' &&
        data
      ) {
        const resData = customerEntityService
          .getByIdCheckChartererLimit(senderId, legalEntityId, {
            limit_type: 'AMOUNT',
            currency_id: data?.currency_id
          })
          .then((res: any) => {
            return res;
          });
        return resData;
      }
    },
    { enabled: !!currencyId && !!legalEntityId && !!senderId && !!data }
  );
  useQuery(['GET_SETTINGS'], async () => {
    await legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 20
      })
      .then((res: any) => {
        setIsAutoTab(res?.legal_entity_settings[0].is_auto_tab ?? 0);
      });
  });

  const sumOfQuantity = orderItemData.reduce((acc: any, item: any) => {
    const quantity = String(item?.amount) || '0';

    let integerPart = 0;
    let numerator = 0;
    let denominator = 1; // Default denominator to 1 if no fraction

    // Check if quantity contains '/'
    // eslint-disable-next-line
    if (quantity?.includes('/')) {
      // eslint-disable-next-line
      [numerator, denominator] = quantity?.split('/').map(Number);
      integerPart = numerator; // Whole number part
      // eslint-disable-next-line
      denominator = denominator; // Remainder of the division
    } else {
      integerPart = +quantity;
    }

    // Package quantity
    const packageQuantity = +item?.package_quantity || 1;
    // Calculate the fractional proportion
    const fractionalProportion = (denominator * 100) / packageQuantity;
    // Calculate adjusted quantity
    const adjustedQuantity = quantity?.includes('/')
      ? `${integerPart}.${fractionalProportion}`
      : integerPart;
    return acc + adjustedQuantity;
  }, 0);

  const sumOfPriceWholsale = orderItemData.reduce((acc: any, item: any) => {
    const price = item?.full_cost === undefined ? 0 : +item.full_cost;
    return acc + price;
  }, 0);

  const sumOfOrderPrice = sumOfQuantity * sumOfPriceWholsale;

  const handleSave = () => {
    const createData = orderItemData?.map((el: any) => {
      const quantity = el?.amount || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1;

      const quantityStr = String(quantity);

      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      const packageQuantity = +el?.package_quantity || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      return {
        barcode: el?.barcode,
        name: el?.product_name,
        price_wholesale: el?.full_cost,
        quantity_asked:
          el?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        currency_id: el?.currency_id,
        measure_unit_id: el?.measure_unit_id,
        nomenclature_id: el?.nomenclature_id,
        product_id: el?.product_id,
        product_parent_id: el?.product_parent_id,
        price: el?.price,
        price_in: el?.price_in,
        price_whosale: el?.price_whosale,
        status_id: '1a22cd85-070e-4779-afcb-8f0be603b60a' // static status id
      };
    });

    const updateData = {
      order_id: newOrderId,
      order_items: createData
    };

    const { amount_limit } = chartererData || {};
    const totalCost = createData.reduce(
      (sum, item) =>
        sum + (item.quantity_asked || 0) * (item.price_whosale || 0),
      0
    );

    if (amount_limit < totalCost) {
      dispatch(
        showAlert({
          title: t('you_have_amount_limit')
        })
      );
      setSaveLoading(false);
      return;
    }

    if (newOrderId !== '' && amount_limit >= totalCost) {
      setSaveLoading(true);
      orderService.getOrderById(newOrderId).then((res: any) => {
        if (res) {
          orderOutService
            .updateOrderOut(newOrderId, {
              ...res,
              status_id: '1a22cd85-070e-4779-afcb-8f0be603b60a'
            })
            .then((outresponse: any) => {
              if (outresponse) {
                orderItemService
                  .createOrderItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      dispatch(
                        showAlert({
                          title: t('new_application_saved'),
                          type: 'success'
                        })
                      );
                      setSaveLoading(false);
                    }
                  });
              }
            });
        }
      });
    }
  };

  const handleSend = () => {
    const createData = orderItemData?.map((el: any) => {
      const quantity = el?.amount || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1;

      const quantityStr = String(quantity);

      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      const packageQuantity = +el?.package_quantity || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      return {
        barcode: el?.barcode,
        name: el?.product_name,
        price_wholesale: el?.full_cost,
        quantity_asked:
          el?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        currency_id: el?.currency_id,
        measure_unit_id: el?.measure_unit_id,
        status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b', // static status id
        nomenclature_id: el?.nomenclature_id,
        product_id: el?.product_id,
        product_parent_id: el?.product_parent_id,
        price: el?.price,
        price_in: el?.price_in,
        price_whosale: el?.price_whosale
      };
    });

    const updateData = {
      order_id: newOrderId,
      order_items: createData
    };

    const { amount_limit } = chartererData || {};
    const totalCost = createData.reduce(
      (sum, item) =>
        sum + (item.quantity_asked || 0) * (item.price_whosale || 0),
      0
    );

    if (amount_limit < totalCost) {
      dispatch(
        showAlert({
          title: t('you_have_amount_limit')
        })
      );
      setSendLoading(false);
      return;
    }

    if (
      newOrderId !== '' &&
      createData?.length > 0 &&
      amount_limit >= totalCost
    ) {
      setSendLoading(true);
      orderService.getOrderById(newOrderId).then((res: any) => {
        if (res) {
          orderOutService
            .updateOrderOut(newOrderId, {
              ...res,
              status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b'
            })
            .then((outresponse: any) => {
              if (outresponse) {
                orderItemService
                  .createOrderItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      setSendLoading(false);
                      dispatch(
                        showAlert({
                          title: t('new_return_sent'),
                          type: 'success'
                        })
                      );
                      navigate('/applications/outgoing');
                    }
                  });
              }
            });
        }
      });
    } else {
      dispatch(
        showAlert({
          title: t('Unable_submit_request')
        })
      );
    }
  };

  return (
    <div>
      <PageBreadcrumb items={newApplicationsBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('new_application')}</h2>

        <NewApplicationsTableSearch
          senderId={senderId}
          orderItemData={orderItemData}
          setOrderItemData={setOrderItemData}
          focusRef={focusRef}
          isAutoTab={isAutoTab}
          setSavedBarcodes={setSavedBarcodes}
          savedBarcodes={savedBarcodes}
          chartererData={chartererData}
        />
        <NewApplicationsTable
          newOrderId={newOrderId}
          orderItemData={orderItemData}
          setOrderItemData={setOrderItemData}
          setRef={setRef}
          setSavedBarcodes={setSavedBarcodes}
        />

        <div className="footer-app-info">
          <div className="box">
            <p className="mb-0 text-bold">{t('amount_limit')}:</p>
            <span>
              {chartererData?.amount_limit
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(chartererData?.amount_limit)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">{t('total_sale_amount')}:</p>
            <span>
              {sumOfOrderPrice
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sumOfOrderPrice)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="main-btn-group">
            <Button
              type="button"
              variant="outline-primary"
              onClick={() => handleSave()}
              loading={saveLoading}
            >
              {t('save')}
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => handleSend()}
              loading={sendLoading}
            >
              {t('send')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewApplicatons;
