import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import SettelementTable from './SettelementTable';

export const suppliersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'dt_kt',
    url: '/dtkt/provider'
  },
  {
    label: 'settlement_supplier',
    active: true
  }
];

const SettelementWithSuppliers = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PageBreadcrumb items={suppliersBreadcrumbItems} />
      <h2 className="mb-5">{t('settlement_supplier')}</h2>

      <SettelementTable />
    </div>
  );
};

export default SettelementWithSuppliers;
