import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import SettelementCutomerTable from './SettelementCutomerTable';

export const customersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'dt_kt',
    url: '/dtkt/provider'
  },
  {
    label: 'settlement_customer',
    active: true
  }
];

const SettelementWithCustomers = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PageBreadcrumb items={customersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('settlement_customer')}</h2>

        <SettelementCutomerTable />
      </div>
    </div>
  );
};

export default SettelementWithCustomers;
