/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import returnItemService from 'service/returnItemService';
import Button from 'components/base/Button';
import legalEntitySettingsService from 'service/legalEntitySettings';
import AddWithQuantityToReturn from './AddWithQuantityToReturn';
import { showAlert } from 'store/alert/alert.thunk';
import { pageToOffset } from 'utils/pageToOffset';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';

export type SearchDataType = {
  id: string;
  product_name: string;
  variation: string;
  barcode: string;
  units: string;
  total_price: string;
  remainder: string;
  date_order: string;
};

interface OpenSearchReturningTableProps {
  returnItemData?: any;
  setReturnItemData?: any;
  senderId?: any;
  focusRef?: any;
  isAutoTab?: any;
  savedBarcodes?: any;
  setSavedBarcodes?: any;
}
const OpenSearchReturningTable = ({
  returnItemData,
  setReturnItemData,
  senderId,
  isAutoTab,
  focusRef,
  savedBarcodes,
  setSavedBarcodes
}: OpenSearchReturningTableProps) => {
  const { t, i18n } = useTranslation();

  const dispatch: Dispatch<any> = useDispatch();

  const countryId = useSelector((state: any) => state?.auth?.user?.country_id);

  const [searchValue, setSearchValue] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [isForceQuantity, setIsForceQuantity] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [addedProduct, setAddedProduct] = useState<any>();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchDataLegalSettings = useMemo(() => {
    return () => {
      legalEntitySettingsService
        .getList({ offset: 0, limit: 100 })
        .then((res: any) => {
          setIsForceQuantity(
            res?.legal_entity_settings[0]?.is_force_quantity ?? false
          );
        });
    };
  }, [countryId]);

  const { data, isLoading, isFetching } = useQuery(
    ['GET_PRODUCT', searchValue, senderId, getBarcode, currentPage],
    () => {
      if (senderId !== '') {
        const res = returnItemService
          .getBySenderId(senderId, {
            limit: 10,
            offset: pageToOffset(currentPage, 10),
            name: searchValue,
            barcode: getBarcode
          })
          .then((res: any) => {
            return res;
          });

        return res;
      }
    },
    {
      enabled:
        (!!senderId &&
          (!searchValue || searchValue.length > 2 || !getBarcode)) ||
        !!currentPage
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const searchData: SearchDataType[] = useMemo(() => {
    console.log('savedBarcodes', savedBarcodes);
    return (
      data?.products
        ?.filter((items: any) => {
          const barcode =
            data?.is_product === true
              ? items?.barcode
              : items?.nomenclature?.barcode;

          const savedQuantity = savedBarcodes?.[barcode]?.quantity || 0;

          // Exclude items where the saved quantity equals the item's quantity
          return (
            savedQuantity !== items?.quantity &&
            items?.quantity - savedQuantity > 0
          );
        })
        ?.map((items: any) => {
          const barcode =
            data?.is_product === true
              ? items?.barcode
              : items?.nomenclature?.barcode;

          const savedQuantity = savedBarcodes?.[barcode]?.quantity || 0;

          return {
            product_name:
              data?.is_product === true
                ? items?.name
                : items?.nomenclature?.name,
            barcode: barcode,
            units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
            total_price: items?.price_in
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(items?.price_in)
                  .replace(/,/g, ' ')
              : 0,
            remainder: Math.max((items?.quantity ?? 0) - savedQuantity, 0),
            date_order: new Date(items?.date_created)
              .toLocaleDateString('en-GB')
              .replace(/\//g, '-'),

            // this dataes need for static rendering dataes
            unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
            measure_unit_id: items?.nomenclature?.measure_unit?.id,
            amount: Math.max((items?.quantity ?? 0) - savedQuantity, 0),
            current_balance: Math.max(
              (items?.quantity ?? 0) - savedQuantity,
              0
            ),
            currency_id: items?.currency_id,
            measure_unit_kind_id: items?.nomenclature?.measure_unit_kind_id,
            package_quantity: items?.package_quantity ?? 0,
            nomenclature_id: items?.nomenclature_id,
            parent_product_id: items?.parent_product_id,
            product_id: items?.id,
            price: items?.price_in,
            is_vat: items?.is_vat ? items?.is_vat : false
          };
        }) ?? []
    );
  }, [data, searchValue, getBarcode, t, i18n?.language, savedBarcodes]);

  const handleCheckItem = (data: any) => {
    if (isForceQuantity) {
      setOpenAddProduct(true);
    } else {
      if (data?.remainder === 0) {
        dispatch(
          showAlert({
            title: t('quantity_is_not_enough')
          })
        );
        return;
      }

      const sanitizedBarcode = data.barcode?.replace(/["'\s]/g, '');

      const updatedProductInvoiceData = [...returnItemData]; // Make a copy of the current state

      if (updatedProductInvoiceData.length === 0) {
        // Handle case where there are no items in movingItemData
        updatedProductInvoiceData.push({
          ...data,
          amount: data.amount || 1
        });
      } else {
        const foundProductIndex = updatedProductInvoiceData.findIndex(
          item => item?.barcode === sanitizedBarcode
        );

        if (foundProductIndex !== -1) {
          const existingItem = updatedProductInvoiceData[foundProductIndex];

          if (
            existingItem.measure_unit_kind_id ===
            '3c7cee55-ad41-4ee3-a409-a7bb5f428b36'
          ) {
            // Handle fractional units
            const currentAmount = existingItem.amount || '0';
            const newAmount = data.amount || 0;

            if (
              typeof currentAmount === 'string' &&
              currentAmount.includes('/')
            ) {
              const [currentNumerator, denominator] = currentAmount
                .split('/')
                .map(Number);

              // if (numerator + 1 > denominator) {
              //   dispatch(
              //     showAlert({
              //       title: t('quantity_exceeds_remainder', {
              //         remainder: `${denominator}/${denominator}`
              //       })
              //     })
              //   );
              //   return;
              // }
              const [newNumerator] = String(newAmount).split('/').map(Number); // Increment numerator
              /* prettier-ignore */
              existingItem.amount = `${(currentNumerator || 0) + newNumerator}/${
                denominator || 1
              }`;
            } else {
              existingItem.amount =
                (Number(existingItem.amount) || 0) + newAmount;
            }
          } else {
            // Handle integer units
            const newAmount = (+existingItem.amount || 0) + 1;
            if (newAmount > data.remainder) {
              dispatch(
                showAlert({
                  title: t('quantity_exceeds_remainder', {
                    remainder: data.remainder
                  })
                })
              );
              return;
            }
            existingItem.amount = newAmount;
          }

          // Move updated item to the top
          const [updatedItem] = updatedProductInvoiceData.splice(
            foundProductIndex,
            1
          );
          updatedProductInvoiceData.unshift(updatedItem);
        } else {
          // Add a new item with initial amount of 1 or 1/1
          updatedProductInvoiceData.push({ ...data, amount: data.amount || 1 });
        }
      }

      setReturnItemData(updatedProductInvoiceData);

      setSavedBarcodes((prevBarcodes: any) => {
        const updatedBarcodes = { ...prevBarcodes };

        const totalQuantity = updatedProductInvoiceData
          .filter(item => item.barcode === sanitizedBarcode)
          .reduce((total, item) => {
            if (
              item.measure_unit_kind_id ===
              '3c7cee55-ad41-4ee3-a409-a7bb5f428b36'
            ) {
              const amountStr = String(item?.amount || '0');
              const [numerator] = amountStr.split('/').map(Number);
              return total + (numerator || 0);
            }
            return total + (item.amount || 0);
          }, 0);

        updatedBarcodes[sanitizedBarcode] = { quantity: totalQuantity };
        return updatedBarcodes;
      });
      if (isAutoTab) {
        // when auto tab true focus to quantity
        setTimeout(() => {
          const firstProductIndex = 0;
          focusRef(`amount_${firstProductIndex}`);
        }, 500);
      }
    }
  };

  const searchTableColumns: ColumnDef<SearchDataType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => {
              handleCheckItem(rowData.row.original);
              setAddedProduct(rowData.row.original);
            }}
          >
            <FeatherIcon icon="plus" className="cursor-pointer" size={18} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: t('variation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'total_price',
      header: t('purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_order',
      header: t('purchase_date'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: searchData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    fetchDataLegalSettings();
  }, []);

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <div className="mb-4 d-flex gap-2">
            <div className="form-icon-container">
              <Form.Floating>
                <Form.Control
                  type="text"
                  placeholder={t('scan_barcode')}
                  onFocus={e => e.target.select()}
                  onChange={e => setGetBarcode(e.target.value)}
                  className="form-icon-input"
                  autoComplete="off"
                />
                <label
                  htmlFor="floatingInputCustom"
                  className="form-icon-label text-700"
                >
                  {t('scan_barcode')}
                </label>
              </Form.Floating>
              <FontAwesomeIcon
                icon={faBarcode}
                className="text-900 fs-9 form-icon"
              />
            </div>
            <SearchBox
              placeholder={t('search')}
              onChange={e => setSearchValue(e?.target?.value)}
            />
          </div>
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>

        <Modal
          show={openAddProduct}
          onHide={() => setOpenAddProduct(false)}
          size="sm"
          centered
          contentClassName="border border-300"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t('add')} {t('quantity')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddWithQuantityToReturn
              setOpenAddProduct={setOpenAddProduct}
              addedProduct={addedProduct}
              setReturnItemData={setReturnItemData}
              returnItemData={returnItemData}
              setSavedBarcodes={setSavedBarcodes}
            />
          </Modal.Body>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export default OpenSearchReturningTable;
