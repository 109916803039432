/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import inventoryService from 'service/inventory';
import legalEntitySettingsService from 'service/legalEntitySettings';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';

export type InfoType = {
  inventory_name: string;
  inventory_number: string;
  date_inventory: string;
  inventory_user: string;
  inventory_warehouse: string;
  date_create_inventory: string;
  guid: string;
};

const WriteOffInventoryTable = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const navigateTo = (row: any) => {
    navigate(
      `/write-off/write-off-inventory/write-off-inventory-result/${row?.original?.guid}`,
      {
        state: {
          name: row?.original?.inventory_name
        }
      }
    );
  };

  const updateSearchParams = (key: string, value: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(key, value); // Set new value
    } else {
      newParams.delete(key); // Remove if empty
    }
    setSearchParams(newParams);
  };

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, isFetching, isLoading } = useQuery(
    ['GET_WRITE_OFF_INVENTORY', currentPage],
    async () => {
      const res = await inventoryService
        .getList({
          offset: pageToOffset(currentPage, 10),
          limit: 10
        })
        .then((res: any) => res);
      return res;
    },
    { enabled: !!currentPage }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const updatedInfoData: InfoType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resData =
      data?.inventories?.map((items: any) => {
        return {
          inventory_name: items?.user?.first_name
            ? `${items?.user?.first_name} ${items?.user?.last_name}`
            : '',
          /* prettier-ignore */
          date_inventory: formatDateToTimeZone(items?.inventory_date, timeZone),
          inventory_warehouse: items?.ware_house?.name,
          inventory_number: items?.inventory_number,
          /* prettier-ignore */
          date_create_inventory: formatDateToTimeZone(items?.date_create, timeZone),
          guid: items?.id
        };
      }) ?? [];

    return resData;
  }, [data, dataSettings]);

  const infoDataTableColumns: ColumnDef<InfoType>[] = [
    {
      accessorKey: 'inventory_name',
      header: t('financially_responsible_person'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_inventory',
      header: t('date_invoice'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'inventory_number',
      header: t('invoice_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'inventory_warehouse',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_create_inventory',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: updatedInfoData,
    columns: infoDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    const pageParam = searchParams.get('pageParam');

    if (pageParam) {
      setCurrentPage(Number(pageParam));
    }
  }, [searchParams]);

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            navigateTo={navigateTo}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            onClick={(event, page) => {
              if (page) {
                updateSearchParams('pageParam', String(page));
              }
            }}
          />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default WriteOffInventoryTable;
