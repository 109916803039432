import { RouteObject, createBrowserRouter } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import MainLayoutProvider from 'providers/MainLayoutProvider';

import App from 'App';

//RIZO COMPONENTS
import LegalEntity from 'pages/legal';

import Clients from 'pages/clients';

import Stock from 'pages/directories/stock';

import Currency from 'pages/directories/currency';

import Rate from 'pages/directories/rate';

import PaymentType from 'pages/directories/payment';
import Nds from 'pages/directories/nds';

import Wallet from 'pages/directories/wallet';

import AddAmount from 'pages/directories/wallet/add/AddAmount';

import PurchaseInvoce from 'pages/purchase/invoce';

import Outgoing from 'pages/applications/outgoing';

import NewApplicatons from 'pages/applications/outgoing/filter/newapplications/NewApplicatons';

import ComingOut from 'pages/applications/coming';

import ComingOutReturning from 'pages/returns/coming';
import OutgoingReturnig from 'pages/returns/outgoing';
import NewReturning from 'pages/returns/outgoing/filter/newreturning/NewReturning';

import ComingOutMovements from 'pages/movements/coming';
import OutgoingMovements from 'pages/movements/outgoing';
import NewMovements from 'pages/movements/outgoing/filter/newmovements/NewMovements';

import Bonuses from 'pages/bonuses';

import ProviderPage from 'pages/dtkt/provider';

import CustomerPage from 'pages/dtkt/customer';
import SettelementWithSuppliers from 'pages/dtkt/suppliers';
import SettelementWithCustomers from 'pages/dtkt/buyyers';

import CreateInventory from 'pages/inventory/create';
import InfoInventory from 'pages/inventory/info';

import Expenses from 'pages/expenses';

import PurchaseByInvoice from 'pages/reports/purchase/PurchaseByInvoice';
import PurchaseByGoods from 'pages/reports/purchase/PurchaseByGoods';

import ByPayment from 'pages/reports/sale/ByPayments';
import ByProduct from 'pages/reports/sale/ByProduct';

import Remainder from 'pages/reports/remainder';

import GrossIncome from 'pages/reports/income';

import AccordingToInvoice from 'pages/analytics/revaluation/AccordingToInvoice';
import AccordingToProduct from 'pages/analytics/revaluation/AccordingToProduct';
import AutoOrder from 'pages/analytics/autoorder';
import FrozenRemedy from 'pages/analytics/means/FrozenRemedy';
import LostProfit from 'pages/analytics/profit';
import ABCAnalysis from 'pages/analytics/analysis';
import SignIn from 'pages/auth/SignIn';
import SettelementResultTable from 'pages/dtkt/suppliers/result/SettelementResultTable';
import SettelementCustomerResultTable from 'pages/dtkt/buyyers/result/SettelementCustomerResultTable';
import SuppliersMaturityDate from 'pages/dtkt/suppliersMaturityDate';
import CustomersMaturityDate from 'pages/dtkt/customersMaturityDate';
import Account from 'pages/directories/account';
import Articles from 'pages/directories/article';
import InfoResultTable from 'pages/inventory/info/result/InfoResultTable';
import OutgoingReturningResultTable from 'pages/returns/outgoing/OutgoingReturningResult';
import ComingResultReturning from 'pages/returns/coming/ComingResultReturning';
import InventoryMainCreate from 'pages/inventory/create/InventoryMainCreate';
import OutgoingMovementsResultTable from 'pages/movements/outgoing/OutgoingMovementsResultTable';
import ComingResultMovements from 'pages/movements/coming/ComingResultMovements';
import ComingResultTable from 'pages/applications/coming/ComingResultTable';
import OutgoingResultTable from 'pages/applications/outgoing/OutgoingResultTable';
import CreateInvoice from 'pages/purchase/invoce/CreateInvoice';
import AdminSettings from 'pages/settings';
import PurchaseByInvoiceTableResult from 'pages/reports/purchase/PurchaseByInvoiceTableResult';
import AccordingToInvoiceResultTable from 'pages/analytics/revaluation/table/AccordingToInvoiceResultTable';
import HomePage from 'pages/home';
import Cashflow from 'pages/finance/cashflow';
import ProfitAndLost from 'pages/finance/p&l';
import CashRegister from 'pages/directories/cash';
import BonusResultTable from 'pages/bonuses/BonusResultTable';
import SignUp from 'pages/auth/SignUp';
import VerifyOtp from 'pages/auth/VerifyOtp';
import Register from 'pages/auth/Register';
import RegisterNextStep from 'pages/auth/RegisterNextStep';
import Products from 'pages/products';
import ChildTable from 'pages/products/childTable';
import CashInfo from 'pages/cashInfo';
import Checks from 'pages/cashInfo/checks';
import MyProducts from 'pages/myProducts';
import Scales from 'pages/scales';
import Roles from 'pages/roles';
import RolePermisions from 'pages/roles/RolePermisions';
import WriteOffInventory from 'pages/write-off/write-off-inventory';
import WriteOffInventoryResultTable from 'pages/write-off/write-off-inventory/WriteOffInventoryResultTable';
import MarriageGoods from 'pages/MarriageGoods';
import Purchaser from 'pages/dtkt/purchaser';
import PurchaserRepaymentTerm from 'pages/dtkt/purchaserRepaymentTerm';
import PurchaserWithCustomer from 'pages/dtkt/purchaserWithCustomer';
import PurchaserWithCustomerResult from 'pages/dtkt/purchaserWithCustomer/PurchaserWithCustomerResult';
import HistoryPurchaseWithCustomer from 'pages/dtkt/historyPurchaseWithCustomer';
import CurrencyExchangeRate from 'pages/currency-conversion/exchange-rate';
import Conversion from 'pages/currency-conversion/conversion';
import CashTransaction from 'pages/cash-transaction';
import AllChecks from 'pages/allChecks';
import ClientsRoles from 'pages/clients/ClientsRoles';
import Discount from 'pages/discount';
import DiscountItems from 'pages/discount/DiscountItems';
import CustomerLimits from 'pages/dtkt/customer/CustomerLimits';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <HomePage />
          },

          {
            path: '/legal',
            element: <LegalEntity />
          },

          {
            path: '/clients',
            element: <Clients />
          },

          {
            path: '/clients',
            children: [
              {
                path: 'client-roles/:roleId',
                element: <ClientsRoles />
              }
            ]
          },

          {
            path: '/roles',
            element: <Roles />
          },
          {
            path: '/roles',
            children: [
              {
                path: 'roles-create/:roleId',
                element: <RolePermisions />
              }
            ]
          },
          {
            path: '/directories',
            children: [
              {
                path: 'stock',
                element: <Stock />
              },

              {
                path: 'currency',
                element: <Currency />
              },

              {
                path: 'rate',
                element: <Rate />
              },

              {
                path: 'payment',
                element: <PaymentType />
              },
              {
                path: 'nds',
                element: <Nds />
              },

              {
                path: 'wallet',
                element: <Wallet />
              },

              {
                path: '/directories/wallet',
                children: [
                  {
                    path: 'add/:addId',
                    element: <AddAmount />
                  }
                ]
              },

              {
                path: 'accounts',
                element: <Account />
              },
              {
                path: 'articles',
                element: <Articles />
              },

              {
                path: 'cash',
                element: <CashRegister />
              }
            ]
          },

          {
            path: '/currency-conversion',
            children: [
              {
                path: 'exchange-rate',
                element: <CurrencyExchangeRate />
              },
              {
                path: 'conversion',
                element: <Conversion />
              }
            ]
          },

          {
            path: '/cash-transaction',
            element: <CashTransaction />
          },

          {
            path: '/products',
            element: <Products />
          },
          {
            path: '/products',
            children: [
              {
                path: 'products-add/:productId',
                element: <ChildTable />
              }
            ]
          },
          {
            path: '/my-products',
            element: <MyProducts />
          },

          {
            path: '/scales',
            element: <Scales />
          },
          {
            path: '/marrige-goods',
            element: <MarriageGoods />
          },

          {
            path: '/purchase',
            children: [
              {
                path: 'create',
                element: <CreateInvoice />
              },

              {
                path: '/purchase/create',
                children: [
                  {
                    path: 'create-add/:invoceId',
                    element: <PurchaseInvoce />
                  }
                ]
              }
            ]
          },
          {
            path: '/bonuses',
            element: <Bonuses />
          },
          {
            path: '/bonuses',
            children: [
              {
                path: 'bonus-add/:bonusId',
                element: <BonusResultTable />
              }
            ]
          },

          {
            path: '/discount',
            element: <Discount />
          },

          {
            path: '/discount',
            children: [
              {
                path: 'discount-items/:discountId',
                element: <DiscountItems />
              }
            ]
          },

          {
            path: '/returning',
            children: [
              {
                path: 'outgoing',
                element: <OutgoingReturnig />
              },
              {
                path: '/returning/outgoing',
                children: [
                  {
                    path: 'returning-outgoing-result/:outgoingId',
                    element: <OutgoingReturningResultTable />
                  }
                ]
              },

              {
                path: 'outgoing/filter/newreturning/:newId',
                element: <NewReturning />
              },

              {
                path: 'coming',
                element: <ComingOutReturning />
              },
              {
                path: '/returning/coming',
                children: [
                  {
                    path: 'returning-coming-result/:comingId',
                    element: <ComingResultReturning />
                  }
                ]
              }
            ]
          },
          {
            path: '/applications',
            children: [
              {
                path: 'outgoing',
                element: <Outgoing />
              },
              {
                path: 'outgoing/filter/newapplications/:outId',
                element: <NewApplicatons />
              },
              {
                path: '/applications/outgoing',
                children: [
                  {
                    path: 'applications-result/:ordId',
                    element: <OutgoingResultTable />
                  }
                ]
              },

              {
                path: 'coming',
                element: <ComingOut />
              },

              {
                path: '/applications/coming',
                children: [
                  {
                    path: 'applications-coming-result/:resultId',
                    element: <ComingResultTable />
                  }
                ]
              }
            ]
          },
          {
            path: '/movements',
            children: [
              {
                path: 'outgoing',
                element: <OutgoingMovements />
              },

              {
                path: '/movements/outgoing',
                children: [
                  {
                    path: 'movements-outgoing-result/:mvId',
                    element: <OutgoingMovementsResultTable />
                  }
                ]
              },

              {
                path: 'outgoing/filter/newmovements/:newId',
                element: <NewMovements />
              },

              {
                path: 'coming',
                element: <ComingOutMovements />
              },
              {
                path: '/movements/coming',
                children: [
                  {
                    path: 'movements-coming-result/:comingId',
                    element: <ComingResultMovements />
                  }
                ]
              }
            ]
          },
          {
            path: '/dtkt',
            children: [
              {
                path: 'provider',
                element: <ProviderPage />
              },
              {
                path: 'suppliers',
                element: <SettelementWithSuppliers />
              },

              {
                path: '/dtkt/suppliers',
                children: [
                  {
                    path: 'suppliers-result/:suppliersId',
                    element: <SettelementResultTable />
                  }
                ]
              },

              {
                path: 'suppliers-maturity-date',
                element: <SuppliersMaturityDate />
              },

              {
                path: 'customer',
                element: <CustomerPage />
              },

              {
                path: '/dtkt/customer',
                children: [
                  {
                    path: 'customer-limits/:customerId',
                    element: <CustomerLimits />
                  }
                ]
              },

              {
                path: 'customer-maturity-date',
                element: <CustomersMaturityDate />
              },
              {
                path: 'buyyers',
                element: <SettelementWithCustomers />
              },

              {
                path: '/dtkt/buyyers',
                children: [
                  {
                    path: 'buyyers-result/:buyyersId',
                    element: <SettelementCustomerResultTable />
                  }
                ]
              },

              {
                path: 'purchaser',
                element: <Purchaser />
              },
              {
                path: 'purchaser-with-customer',
                element: <PurchaserWithCustomer />
              },
              {
                path: '/dtkt/purchaser-with-customer',
                children: [
                  {
                    path: 'purchaser-with-customer-result/:resultId',
                    element: <PurchaserWithCustomerResult />
                  }
                ]
              },

              {
                path: 'purchaser-repayment-term',
                element: <PurchaserRepaymentTerm />
              },
              {
                path: 'purchaser-with-customer-history',
                element: <HistoryPurchaseWithCustomer />
              }
            ]
          },
          {
            path: '/inventory',
            children: [
              {
                path: 'create-inventory',
                element: <InventoryMainCreate />
              },
              {
                path: 'main/:mainId',
                element: <CreateInventory />
              },
              {
                path: 'info',
                element: <InfoInventory />
              },
              {
                path: '/inventory/info',
                children: [
                  {
                    path: 'info-result/:infoId',
                    element: <InfoResultTable />
                  }
                ]
              }
            ]
          },
          {
            path: '/write-off',
            children: [
              { path: 'write-off-inventory', element: <WriteOffInventory /> },
              {
                path: '/write-off/write-off-inventory',
                children: [
                  {
                    path: 'write-off-inventory-result/:infoId',
                    element: <WriteOffInventoryResultTable />
                  }
                ]
              }
            ]
          },
          {
            path: '/expenses',
            children: [
              {
                index: true,
                element: <Expenses />
              }
            ]
          },
          {
            path: '/reports',
            children: [
              {
                path: 'purchase',
                children: [
                  {
                    path: 'invoice',
                    element: <PurchaseByInvoice />
                  },
                  {
                    path: '/reports/purchase/invoice',
                    children: [
                      {
                        path: 'purchase-invoice-result/:invoiceId',
                        element: <PurchaseByInvoiceTableResult />
                      }
                    ]
                  },

                  {
                    path: 'goods',
                    element: <PurchaseByGoods />
                  }
                ]
              },
              {
                path: 'sale',
                children: [
                  {
                    path: 'payments',
                    element: <ByPayment />
                  },
                  {
                    path: 'product',
                    element: <ByProduct />
                  }
                ]
              },
              {
                path: 'remainder',
                element: <Remainder />
              },
              {
                path: 'income',
                element: <GrossIncome />
              },
              {
                path: 'cash-info',
                element: <CashInfo />
              },
              {
                path: '/reports/all-checks',
                element: <AllChecks />
              },
              {
                path: '/reports/cash-info',
                children: [
                  {
                    path: 'cash-info-check/:cashId',
                    element: <Checks />
                  }
                ]
              }
            ]
          },
          {
            path: '/analytics',
            children: [
              {
                path: 'revaluation',
                children: [
                  {
                    path: 'revaluation-invoice',
                    element: <AccordingToInvoice />
                  },
                  {
                    path: '/analytics/revaluation/revaluation-invoice',
                    children: [
                      {
                        path: 'invoice-result/:inId',
                        element: <AccordingToInvoiceResultTable />
                      }
                    ]
                  },

                  {
                    path: 'revaluation-product',
                    element: <AccordingToProduct />
                  }
                ]
              },
              {
                path: 'abc',
                element: <ABCAnalysis />
              },
              {
                path: 'autoorder',
                element: <AutoOrder />
              },
              {
                path: 'frozen-funds',
                element: <FrozenRemedy />
              },
              {
                path: 'profit',
                element: <LostProfit />
              }
            ]
          },
          {
            path: '/finance',
            children: [
              {
                path: 'cashflow',
                element: <Cashflow />
              },
              {
                path: 'profit-and-lost',
                element: <ProfitAndLost />
              }
            ]
          },
          {
            path: '/settings',
            children: [
              {
                index: true,
                element: <AdminSettings />
              }
            ]
          }
        ]
      },
      {
        path: '/sign-in',
        element: <SignIn />
      },
      {
        path: '/sign-up',
        element: <SignUp />
      },
      {
        path: '/verify-otp',
        element: <VerifyOtp />
      },
      {
        path: '/register',
        element: <Register />
      },
      {
        path: '/register-next-step',
        element: <RegisterNextStep />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
