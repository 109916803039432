/* eslint-disable @typescript-eslint/no-explicit-any */
import CountUp from 'react-countup';
import { LegacyRef } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import AnalyticsCallCampaignChart from 'components/charts/e-charts/AnalyticsCallCampaignChart';
import dashboardService from 'service/dashboard';
import styles from './styles.module.scss';

export const branchOption = [
  {
    label: 'Epa',
    value: 'Epa'
  }
];

interface SaleGraphProps {
  selectedCurrencyId?: any;
  dateFrom?: any;
  dateTo?: any;
  selectedBranchId?: string;
}

const SaleGraph = ({
  selectedCurrencyId,
  dateFrom,
  dateTo,
  selectedBranchId
}: SaleGraphProps) => {
  const { t } = useTranslation();
  const [randomColors, setRandomColors] = useState<{ [key: string]: string }>(
    {}
  );

  const legalEntityId = useSelector(
    (state: any) => state?.auth?.legal_entity?.id
  );
  //Get Sale
  const { data } = useQuery(
    ['GET_SALE', dateFrom, dateTo, selectedCurrencyId, selectedBranchId],
    () => {
      if (selectedCurrencyId && dateFrom) {
        const res = dashboardService
          .getSale({
            offset: 0,
            limit: 10,
            date_from: dateFrom,
            date_to: dateTo,
            currency_id: selectedCurrencyId,
            branch_id: selectedBranchId
          })
          .then((res: any) => res);
        return res;
      }
    },
    { enabled: Boolean(dateFrom && selectedCurrencyId) || !!selectedBranchId }
  );

  console.log('test');
  const { data: dataSaleBranch } = useQuery(
    ['GET_SALE_BRANCH', dateFrom, dateTo, selectedCurrencyId],
    () => {
      if (selectedCurrencyId && dateFrom) {
        const res = dashboardService
          .getSaleBranch({
            offset: 0,
            limit: 100,
            date_from: dateFrom,
            date_to: dateTo,
            currency_id: selectedCurrencyId
          })
          .then((res: any) => res?.items);
        return res;
      }
    },
    { enabled: Boolean(dateFrom && selectedCurrencyId) }
  );

  useEffect(() => {
    if (dataSaleBranch) {
      const colors: { [key: string]: string } = {};

      // Apply filter logic
      const filteredData = dataSaleBranch.filter((items: any) => {
        if (selectedBranchId === 'all') {
          return true; // Show all items if selectedBranchId is "all"
        }
        // Filter based on selectedBranchId or legalEntityId
        if (selectedBranchId) {
          return items?.legal_entity?.id === selectedBranchId;
        }
        return items?.legal_entity?.id === legalEntityId;
      });
      /* prettier-ignore */
      filteredData.forEach((item: any) => {
        if (selectedBranchId === 'all') {
          // Generate colors for all items if selectedBranchId is "all"
          /* prettier-ignore */
            const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
          colors[item?.legal_entity?.id] = randomColor;
        } else if (item?.legal_entity?.id === selectedBranchId) {
          // Generate color for selectedBranchId
          colors[item?.legal_entity?.id] =
            `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        } else if (item?.legal_entity?.id === legalEntityId) {
          // Generate color for legalEntityId
          colors[item?.legal_entity?.id] =
            `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        }
      });

      setRandomColors(colors);
    }
  }, [dataSaleBranch, selectedBranchId, legalEntityId]);

  const sumOfTotalAmount = data?.items?.reduce((sum: number, item: any) => {
    return sum + (item.info?.total_amount || 0);
  }, 0);

  return (
    <div className={styles.graphContainer}>
      <div className={styles.saleInfo}>
        <p className={styles.title}>{t('sales')}</p>
        <CountUp
          end={sumOfTotalAmount}
          duration={2.75}
          suffix={
            selectedCurrencyId === 'd69b53bd-da93-4db6-9108-c31210ca5c8f'
              ? ` ${t('sum')}`
              : ' $'
          }
          separator=" "
          delay={0}
        >
          {({ countUpRef }) => (
            <div>
              <span
                className={styles.count}
                ref={countUpRef as LegacyRef<HTMLHeadingElement>}
              ></span>
            </div>
          )}
        </CountUp>

        {/* <Form.Floating style={{ marginTop: '12px', width: '320px' }}>
          <Form.Select>
            <option className="d-none" value=""></option>
            {branchOption?.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>

          <label htmlFor="floatingInputCustom">По дням</label>
        </Form.Floating> */}

        <AnalyticsCallCampaignChart
          data={data?.items}
          randomColors={randomColors}
          style={{
            height: '300px',
            width: '100%',
            marginTop: '24px'
          }}
        />

        {/* <div className={styles.graphInfo}>
          <span className={styles.dot}></span>
          <div className={styles.selectedGraphInfo}>
            <p className={styles.sgTitle}>Чорсу</p>
            <CountUp
              end={278980500}
              duration={2.75}
              suffix="сум"
              separator=" "
              delay={0}
            >
              {({ countUpRef }) => (
                <div>
                  <span
                    className={styles.sgCount}
                    ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                  ></span>
                </div>
              )}
            </CountUp>
          </div>
        </div> */}
      </div>

      <div className={styles.fullGraph}>
        {dataSaleBranch
          ?.filter((items: any) => {
            // Show all items if selectedBranchId is "all"
            if (selectedBranchId === 'all') {
              return true;
            }
            // Filter based on selectedBranchId or legalEntityId
            if (selectedBranchId) {
              return items?.legal_entity?.id === selectedBranchId;
            }
            return items?.legal_entity?.id === legalEntityId;
          })
          ?.map((items: any) => {
            const color =
              randomColors[items?.legal_entity?.id] ||
              `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            return (
              <div
                key={items?.legal_entity?.id}
                className={styles.switchToGraph}
              >
                <div
                  className={styles.elips}
                  style={{ background: color }}
                ></div>
                <div className={styles.branchContent}>
                  <p className={styles.branchTitle}>
                    {items?.legal_entity?.name}
                  </p>
                  <CountUp
                    end={items?.total_amount ?? 0}
                    duration={2.75}
                    suffix={
                      selectedCurrencyId ===
                      'd69b53bd-da93-4db6-9108-c31210ca5c8f'
                        ? ` ${t('sum')}`
                        : ' $'
                    }
                    separator=" "
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <>
                        <span
                          className={styles.count}
                          ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                        ></span>
                      </>
                    )}
                  </CountUp>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SaleGraph;
