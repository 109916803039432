/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import reportInvoiceService from 'service/reportInvoice';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type ProductData = {
  casher: string;
  product_name: string;
  barcode: string;
  supplier: string;
  warehouse: string;
  currency: string;
  category: string;
  unit: string;
  quantity: string;
  buyyer: string;
  purchase_price: string;
  sales_price: string;
  paid: string;
  discrepancy: string;
  total_payment_price: string;
  date_synchronization: string;
  date: string;
};

interface ProductTableProps {
  generateTableData?: any;
  setGeneratedTableData?: any;
  getName?: any;
  getBarcode?: any;
  getFilterData?: any;
}

const ProductTable = ({
  generateTableData,
  setGeneratedTableData,
  getName,
  getBarcode,
  getFilterData
}: ProductTableProps) => {
  const { t, i18n } = useTranslation();
  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  const branchId = useSelector((state: any) => state?.auth?.legal_entity?.id);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  const { data, refetch } = useQuery(
    ['GET_REPORTS_SALE', getName, getBarcode],
    async () => {
      const res = await reportInvoiceService
        .getReportSaleList({
          offset: 0,
          limit: 100,
          name: getName,
          currency_id: Object.keys(getFilterData).length === 0 && currencyId,
          branch_id: Object.keys(getFilterData).length === 0 && branchId,
          date_from: Object.keys(getFilterData).length === 0 && todayStart,
          date_to:
            Object.keys(getFilterData).length === 0 && todayEnd + 86400000,
          barcode: getBarcode,
          ...getFilterData
        })
        .then((res: any) => res?.list);
      return res;
    },
    {
      enabled:
        getName.length === 0 ||
        getName.length > 2 ||
        getBarcode.length === 0 ||
        getBarcode.length > 2 ||
        !!currencyId ||
        !!branchId ||
        !!getFilterData
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const reportData: ProductData[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resData =
      data?.map((items: any) => {
        const packageQuantity = items?.product?.package_quantity || 1;
        const quantity = items?.quantity || 0;
        const fullPackages = Math.floor(quantity / packageQuantity);
        const remainingQuantity = quantity % packageQuantity;
        const quantityDisplay = `${fullPackages}/${remainingQuantity}`;

        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          warehouse: items?.product?.ware_house?.name,
          unit: items?.product?.nomenclature?.measure_unit?.name?.[
            i18n?.language
          ],
          currency: items?.currency?.name?.[i18n?.language],
          buyyer: items?.user?.first_name,
          category:
            items?.product?.nomenclature?.category?.name?.[i18n?.language],
          purchase_price: items?.price_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.price_in)
                .replace(/,/g, ' ')
            : 0,
          paid: items?.payment_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.payment_amount)
                .replace(/,/g, ' ')
            : 0,
          discrepancy: items?.payment_difference
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.payment_difference)
                .replace(/,/g, ' ')
            : 0,
          sales_price: items?.price
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.price)
                .replace(/,/g, ' ')
            : 0,
          total_payment_price: items?.total_payment_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.total_payment_amount)
                .replace(/,/g, ' ')
            : 0,
          payment_procedure: items?.payment_order?.name?.[i18n?.language],
          supplier: items?.legal_entity?.name,
          quantity:
            items?.product?.nomenclature?.measure_unit_id ===
            '443bfff1-61e0-4057-8583-d040dc5a0454'
              ? quantityDisplay
              : quantity,
          /* prettier-ignore */
          date: formatDateToTimeZone(items?.date_create, timeZone),
          /* prettier-ignore */
          date_synchronization: formatDateToTimeZone(items?.date_sync, timeZone)
        };
      }) ?? [];

    if (resData) {
      setGeneratedTableData(resData);
    }

    return resData;
  }, [data, dataSettings, t, i18n?.language]);

  const productDataTableColumns: ColumnDef<ProductData>[] = [
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1; // Calculate the order number based on the row index
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'warehouse',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'branch',
      header: t('branch'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'supplier',
      header: t('supplier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'buyyer',
      header: t('buyer'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'category',
      header: t('category'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'purchase_price',
      header: t('purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'quantity',
      header: t('quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sales_price',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'paid',
      header: t('paid'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'discrepancy',
      header: t('discrepancy'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_payment_price',
      header: t('total_payment_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_synchronization',
      header: t('date_synchronization'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  console.log(reportData);
  const table = useAdvanceTable({
    data: generateTableData,
    columns: productDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [getName, getBarcode]);
  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default ProductTable;
