/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';

interface AddWithQuantityToMovingProps {
  setOpenAddProduct: (show: boolean) => void;
  addedProduct?: any;
  setMovingItemData?: any;
  movingItemData?: any;
  setSavedBarcodes?: any;
}

const AddWithQuantityToMoving = ({
  setOpenAddProduct,
  addedProduct,
  setMovingItemData,
  movingItemData,
  setSavedBarcodes
}: AddWithQuantityToMovingProps) => {
  const { t } = useTranslation();

  const dispatch: Dispatch<any> = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const inputRefQuantity = useRef<HTMLInputElement | any>();

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    const quantity = +data?.quantity || 0;

    const sanitizedBarcode = addedProduct.barcode?.replace(/["'\s]/g, '');

    if (!addedProduct || quantity <= 0 || addedProduct?.remainder === 0) {
      dispatch(
        showAlert({
          title: t('quantity_is_not_enough')
        })
      );
      setLoading(false);
      return;
    }

    if (quantity > addedProduct?.remainder) {
      dispatch(
        showAlert({
          title: t('quantity_exceeds_remainder', {
            remainder: addedProduct?.remainder
          })
        })
      );
      setLoading(false);
      return;
    }

    const createdData = {
      ...addedProduct,
      amount: quantity
    };

    const updatedProductInvoiceData = [...movingItemData];

    const foundProductIndex = updatedProductInvoiceData.findIndex(
      item => item?.barcode === sanitizedBarcode
    );

    if (foundProductIndex !== -1) {
      const quantityCheck =
        updatedProductInvoiceData[foundProductIndex].amount || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1;

      if (typeof quantityCheck === 'string' && quantityCheck?.includes('/')) {
        // eslint-disable-next-line
        [numerator, denominator] = quantityCheck?.split('/').map(Number);

        integerPart = numerator += 1; // Whole number part
        denominator = +denominator; // Remainder of the division
      } else {
        integerPart = +quantityCheck;
      }

      const quantityDisplay =
        typeof quantityCheck === 'string' && quantityCheck?.includes('/')
          ? `${integerPart}/${+denominator}`
          : (integerPart += 1);

      if (
        updatedProductInvoiceData[foundProductIndex].measure_unit_kind_id ===
        '3c7cee55-ad41-4ee3-a409-a7bb5f428b36'
      ) {
        updatedProductInvoiceData[foundProductIndex].amount = quantityDisplay;
      } else {
        updatedProductInvoiceData[foundProductIndex].amount =
          (+updatedProductInvoiceData[foundProductIndex].amount || 0) + 1;
      }

      const [updatedItem] = updatedProductInvoiceData.splice(
        foundProductIndex,
        1
      );

      updatedProductInvoiceData.unshift(updatedItem);
    } else {
      updatedProductInvoiceData.push(createdData);
    }
    setMovingItemData(updatedProductInvoiceData);

    setSavedBarcodes((prevBarcodes: any) => {
      const updatedBarcodes = { ...prevBarcodes };

      const totalQuantity = updatedProductInvoiceData
        .filter(item => item.barcode === sanitizedBarcode)
        .reduce((total, item) => {
          if (
            item.measure_unit_kind_id === '3c7cee55-ad41-4ee3-a409-a7bb5f428b36'
          ) {
            const amountStr = String(item?.amount || '0');
            const [numerator] = amountStr.split('/').map(Number);
            return total + (numerator || 0);
          }
          return total + (item.amount || 0);
        }, 0);

      updatedBarcodes[sanitizedBarcode] = { quantity: totalQuantity };
      return updatedBarcodes;
    });
    dispatch(
      showAlert({
        title: t('product_successfully_added'),
        type: 'success'
      })
    );
    setLoading(false);
    setOpenAddProduct(false);
  };

  useEffect(() => {
    if (inputRefQuantity.current) {
      inputRefQuantity.current.focus();
    }
  }, [inputRefQuantity.current]);
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col>
            <Controller
              name={`quantity`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRefQuantity}
                    type="text"
                    placeholder={t('quantity')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.quantity}
                  />
                  <label htmlFor="floatingInputCustom">{t('quantity')}</label>
                  {errors.quantity && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default AddWithQuantityToMoving;
