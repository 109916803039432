/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import discountService from 'service/discount';
import CreateDiscountItem from './CreateDiscountItem';
import SupperModal from 'components/modals/SupperModal';

export type discountItemsType = {
  barcode: string;
  name: string;
  guid: string;
};

const DiscountItems = () => {
  const { t, i18n } = useTranslation();
  const { pathname, state } = useLocation();
  const { name } = state;
  const discountId = pathname.split('/').pop();

  const [openResult, setOpenResult] = useState(false);
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  const discountItemsBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'discount',
      url: '/discount'
    },
    {
      label: name ?? '',
      active: true
    }
  ];

  const { data, refetch } = useQuery(
    ['GET_DISCOUNT_ITEM', discountId],
    () => {
      if (discountId !== '') {
        const res = discountService
          .getListDiscountItem(discountId)
          .then((res: any) => {
            return res?.discount_items;
          });
        return res;
      }
    },
    { enabled: !!discountId }
  );

  const discountItemData: discountItemsType[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          product_name: items?.product?.name,
          barcode: items?.barcode
        };
      }) ?? [];

    return resultData;
  }, [data, discountId, t, i18n?.language]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const discountItemDataTableColumns: ColumnDef<discountItemsType>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div>
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                handleDelete(rowData?.row?.original?.guid);
              }}
            >
              <FeatherIcon
                icon="trash-2"
                className="cursor-pointer text-danger"
                size={20}
              />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('bonus_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: discountItemData,
    columns: discountItemDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      discountService.deleteDiscountItem(selectedInvoiceId).finally(() => {
        refetch();
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={discountItemsBreadcrumbItems} />

      <div className="d-flex justify-content-between align-items-center">
        <h2>{name || ''}</h2>

        <Button
          style={{ height: '48px' }}
          variant="primary"
          onClick={() => setOpenResult(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {t('new')}
        </Button>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>

        <SupperModal
          show={openSupperModal}
          setShow={setOpenSupperModal}
          setConfirm={setConfirmToDelete}
          title={name || ''}
          bodyText={t('you_want_delete')}
        />

        <Offcanvas
          show={openResult}
          onHide={() => {
            setOpenResult(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('add')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CreateDiscountItem
              setOpenAddBonus={setOpenResult}
              refetch={refetch}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </AdvanceTableProvider>
    </div>
  );
};

export default DiscountItems;
